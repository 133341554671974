/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

export interface SettingState {
    value: any;
    pending: boolean;
}

export class DeviceSettings {
    public displayName: SettingState;
    public ledEnabled: SettingState;
    public ledColor: SettingState;

    constructor() {
        this.displayName = { value: null, pending: false };
        this.ledEnabled = { value: null, pending: false };
        this.ledColor = { value: null, pending: false };
    }
}

export default DeviceSettings;
