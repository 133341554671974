/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import React, { ChangeEvent, Component } from "react";
import { ISession } from "../../data/clientSpecific/ISession";
import Utils from "../../data/utils/utils";

interface Props {
    sessions: ISession[];
    disabled: boolean;
    reset: boolean;
    onSelectSession: (session: ISession) => void;
}

interface State {
    selectedSession: ISession;
}

export default class SessionPicker extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedSession: null,
        };
    }

    public componentDidUpdate(): void {
        if (this.props.reset && this.state.selectedSession) {
            this.setState({ selectedSession: null });
        }
    }

    private listSessions = (): JSX.Element[] => {
        if (this.props.sessions != null) {
            let sessions = this.props.sessions.map((session: ISession) => {
                const startTimestamp: string = new Date(Number(session.sessionId)).toLocaleString();
                return (
                    <MenuItem key={session.sessionId} value={session.sessionId}>
                        {startTimestamp + " - " + Utils.millisecondsToHoursAndMinutes(session.durationMs)}
                    </MenuItem>
                );
            });
            sessions = sessions.reverse();
            return sessions;
        }
    }

    private selectSession = (event: ChangeEvent<{ name?: string; value: unknown; }>): void => {
        const value = event.target.value;
        const match = this.props.sessions.find((session: ISession) => session.sessionId === value) || null;
        if (this.state.selectedSession !== match) {
            this.setState({ selectedSession: match });
            this.props.onSelectSession(match);
        }
    }

    private getSessionId = (): string => {
        if (this.state.selectedSession && this.state.selectedSession.sessionId) {
            return this.state.selectedSession.sessionId;
        }
        return "";
    }

    public render(): JSX.Element {
        if (this.props.sessions != null) {
            return (
                <div className="session-picker-container">
                    <FormControl className="session-picker">
                        <InputLabel shrink={true}>
                            Session
                        </InputLabel>
                        <Select
                            value={this.getSessionId()}
                            onChange={this.selectSession}
                            disabled={this.props.disabled}
                            displayEmpty={true}
                        >
                            <MenuItem key="Custom" value="" hidden={true}>
                                Custom
                            </MenuItem>
                            {this.listSessions()}
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }
}
