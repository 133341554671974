/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ApolloQueryResult } from "apollo-client";
import {Auth} from "aws-amplify";
import gql from "graphql-tag";
import {
    addDeviceToGroup,
    addDeviceToOrganization,
    createDeviceGroup,
    deleteDeviceGroup,
    removeDeviceFromGroup } from "../graphql/mutations";
import { getDeviceTree, getGroups, getQsEmbedUrl } from "../graphql/queries";
import AWSThingGroup from "../group/AWSThingGroup";
import IGroup from "../group/IGroup";
import { OrganizationUtils } from "../organization/OrganizationUtils";
import AppSyncClientFactory from "./AppSyncClientFactory";
import {clientType, Service} from "./AppSyncClientProvider";
import IBackend from "./IBackend";

export interface IoTThingGroupAttribute {
    key: string;
    value: string;
}

export interface IoTThingGroup {
    name: string;
    attr: IoTThingGroupAttribute[];
}

export interface IoTGroupsResponse {
    getGroups: {
        thingGroups: IoTThingGroup[];
        nextToken: string;
    };
}

export interface IoTDeviceTreeResponse {
    getDeviceTree: string;
}

export interface GetQsEmbedUrlResponse {
    getQsEmbedUrl: {
        embedUrl: string;
    };
}

interface QsEmbedUrlRequest {
    dashboardId: string;
    openIdToken: string;
    sessionName: string;
    emailAddress: string;
    resetDisabled: boolean;
    undoRedoDisabled: boolean;
    sessionLifetimeInMinutes: number;
}

export default class AWSBackend implements IBackend {

    public async getQsEmbedUrl(openIdToken: string, dashboardId: string): Promise<string> {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const request: QsEmbedUrlRequest = {
                dashboardId,
                emailAddress: user.username,
                openIdToken,
                sessionName: user.username,
                undoRedoDisabled: true,
                resetDisabled: true,
                sessionLifetimeInMinutes: 600,
            };
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.STATS, clientType.TYPE_COGNITO);
            const embedUrlResponse: ApolloQueryResult<GetQsEmbedUrlResponse> = await appSyncClient.query({
                query: gql(getQsEmbedUrl),
                variables: {
                    request,
                },
            });
            const embedUrl = embedUrlResponse.data.getQsEmbedUrl.embedUrl;
            console.log("Embed URL: " + embedUrl);
            return embedUrl;
        } catch (error) {
            console.error("Error: ", error);
            return "";
        }
    }

    private static instance: IBackend = null;
    private static groups: IGroup[] = null;

    public static getInstance(): IBackend {
        if (AWSBackend.instance == null) {
            AWSBackend.instance = new AWSBackend();
        }
        return this.instance;
    }

    public async getDeviceTree(): Promise<string> {
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
            const deviceTreeResponse: ApolloQueryResult<IoTDeviceTreeResponse> = await appSyncClient.query({
                query: gql(getDeviceTree),
                variables: { },
            });
            return deviceTreeResponse.data.getDeviceTree;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return "";
        }
    }

    public async getGroups(recursive: boolean): Promise<IGroup[]> {
        try {
            if (AWSBackend.groups) {
                return AWSBackend.groups;
            }
            let nextToken: string = null;
            let groupList: IoTThingGroup[] = [];
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
                const groupListResponse: ApolloQueryResult<IoTGroupsResponse> = await appSyncClient.query({
                    query: gql(getGroups),
                    variables: {
                        recursive,
                        includeAttributes: true,
                        nextToken,
                    },
                });
                nextToken = groupListResponse.data.getGroups.nextToken || null;
                groupList = groupList.concat(groupListResponse.data.getGroups.thingGroups);
            } while (nextToken);
            const groups = groupList.map((thingGroup: IoTThingGroup) => {
                return new AWSThingGroup(thingGroup.name, thingGroup.attr);
            });
            AWSBackend.groups = groups;
            return groups;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return [];
        }
    }

    public async addDeviceGroup(displayName: string, parentGroup: IGroup): Promise<void> {
        const org: string = await this.getOrganization(parentGroup);
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
        const groupResponse: ApolloQueryResult<any> = await appSyncClient.mutate({
            mutation: gql(createDeviceGroup),
            variables: {
                groupName: displayName,
                parentGroup: parentGroup ? parentGroup.groupId : null,
                organizationName: org,
            },
        });
        if (!groupResponse.data.createThingGroup) {
            console.error("Failed to create group, backend response empty: " + JSON.stringify(groupResponse.errors));
            throw groupResponse.errors[0];
        }
        const newGroup: AWSThingGroup = new AWSThingGroup(
            groupResponse.data.createThingGroup.name,
            groupResponse.data.createThingGroup.attr);
        parentGroup.addSubGroup(newGroup);
    }

    public async removeGroup(removeGroup: IGroup): Promise<void> {
        try {
            const org: string = await this.getOrganization(removeGroup);
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
            await appSyncClient.mutate({
                mutation: gql(deleteDeviceGroup),
                variables: {
                    groupName: removeGroup.groupId,
                    organizationName: org,
                },
            });
        } catch (error) {
            console.error("Failed to remove group: " + JSON.stringify(error));
            throw error;
        }
    }

    public async addDeviceToOrganization(devName: string, group: IGroup): Promise<void> {
        const org: string = await this.getOrganization(group);
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
        await appSyncClient.mutate({
            mutation: gql(addDeviceToOrganization),
            variables: {
                thingName: devName,
                groupName: group.groupId,
                organizationName: org,
            },
        });
    }

    public async addDeviceToGroup(devName: string, group: IGroup): Promise<void> {
        const org: string = await this.getOrganization(group);
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
        await appSyncClient.mutate({
            mutation: gql(addDeviceToGroup),
            variables: {
                thingName: devName,
                groupName: group.groupId,
                overrideDynamics: false,
                organizationName: org,
            },
        });
    }

    public async removeDeviceFromGroup(devName: string, group: IGroup): Promise<void> {
        const org: string = await this.getOrganization(group);
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
        await appSyncClient.mutate({
            mutation: gql(removeDeviceFromGroup),
            variables: {
                thingName: devName,
                groupName: group.groupId,
                organizationName: org,
            },
        });
    }

    private async getOrganization(group: IGroup): Promise<string> {
        let org: string = group ? group.getOrganization() : null;
        if (!org || org.length === 0) {
            org = await OrganizationUtils.getOrganizationNameForCurrentUser();
        }
        return org;
    }
}
