/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import AuthListener from "../data/auth/authListener";
import AuthWrapper from "../data/auth/authWrapper";
import LoginContent from "./login-content";
import LoginHeader from "./login-header";

interface State {
    redirect: boolean;
}

interface Props {
}

export default class Unauth extends Component<Props, State> {

    private authListener: AuthListener;
    private mounted: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            redirect: false,
        };
        this.authListener = new AuthListener((event: string): void => this.listener(event));
    }

    public async componentDidMount(): Promise<void> {
        this.mounted = true;
        if (await AuthWrapper.isCurrentUserAuthenticated()) {
            this.setState({
                redirect: true,
            });
        }
    }

    public componentWillUnmount(): void {
        this.mounted = false;
        this.authListener.removeListener((event: string) => this.listener(event));
    }

    private listener(event: string): void {
        switch (event) {
            case "SignedIn":
                this.redirect();
                break;
            default:
                break;
        }
    }

    private redirect(): void {
        if (this.mounted) { this.setState({ redirect: true }); }
    }

    public render(): JSX.Element {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
        return (
            <Fragment>
                <section className="login-page-container col-md-12">
                    <LoginHeader />
                    <LoginContent />
                </section >
            </Fragment>
        );
    }
}
