/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";

import ClientProperties, { CanGateway, CanType } from "../../data/clientSpecific/ClientProperties";

interface Props {
  label: string;
  currentSensor: CanType;
  sensorTypes: CanType[];
  onSensorSelected: (sensorType: CanType) => void;
}

export default class SensorSelect extends Component<Props> {
  private sensorSelected = (event: React.ChangeEvent<{ name?: string; value: unknown; }>): void => {
    const value = event.target.value;
    const sensorType: CanType = value as CanType;
    this.props.onSensorSelected(sensorType);
  }

  public render(): JSX.Element {
    return (
      <div className="sensor-select-container">
        <FormControl className="sensor-select">
          <InputLabel shrink={true} id="select-sensor-label">
            {this.props.label}
          </InputLabel>
          <Select
            labelId="select-sensor"
            id="select-sensor"
            value={this.props.currentSensor}
            onChange={this.sensorSelected}
            displayEmpty={true}
          >
            {this.props.sensorTypes.map((sensor: CanType) =>
              <MenuItem value={sensor} key={sensor}>
                {ClientProperties.CAN_GATEWAYS.find((i: CanGateway) => i.id === sensor).name}
              </MenuItem>,
            )}
          </Select>
        </FormControl>
      </div>
    );
  }
}
