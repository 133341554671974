import { Button, Dialog, Typography } from "@material-ui/core";
import React, { Component } from "react";

interface Props {
    title: string;
    message: string;
    handleConfirm: () => void;
    handleCancel: () => void;
}

export default class ConfirmationDialog extends Component<Props> {

    public render(): JSX.Element {
        return (
            <Dialog
                onClose={this.props.handleCancel}
                open={true}
            >
                <div className="popup-inner">
                    <Typography variant="h6">{this.props.title}</Typography>
                    <div className="popup-body">
                        <Typography variant="subtitle1">{this.props.message}?</Typography>
                        <div className="buttons-container">
                            <Button
                                variant="contained"
                                color="primary"
                                className="button left"
                                onClick={this.props.handleConfirm}
                            >
                                Confirm
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className="button"
                                onClick={this.props.handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}
