/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import { IoTGroupsResponse, IoTThingGroup, IoTThingGroupAttribute } from "../backend/AWSBackend";
import AWSThing from "../device/AWSThing";
import { Device } from "../device/Device";
import { getDevices, getGroups } from "../graphql/queries";
import IGroup from "./IGroup";

interface IoTDevicesResponse {
    getDevices: {
        things: string[];
        nextToken?: string;
    };
}

const ORGANIZATION_KEY: string = "organization";

export default class AWSThingGroup implements IGroup {
    public readonly groupId: string;
    private devices: Device[];
    private groups: IGroup[];
    private groupAttributes: IoTThingGroupAttribute[];

    constructor(groupId: string, attributes: IoTThingGroupAttribute[]) {
        this.groupId = groupId;
        this.groupAttributes = attributes;
    }

    public getLabel(): string {
        return AWSThingGroup.getLocalisedName(this.groupAttributes, this.groupId);
    }

    public setGroups(groups: IGroup[]): void {
        this.groups = groups;
    }

    public setDevices(devices: Device[]): void {
        this.devices = devices;
    }

    public getOrganization(): string {
        let org: string = "";
        this.groupAttributes.forEach((attribute: IoTThingGroupAttribute) => {
            if (attribute.key === ORGANIZATION_KEY) {
                org = attribute.value;
            }
        });
        return org;
    }

    public async getGroups(): Promise<IGroup[]> {
        try {
            if (this.groups) {
                return this.groups;
            }
            let nextToken: string = null;
            let groupList: IoTThingGroup[] = [];
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
                const groupListResponse: ApolloQueryResult<IoTGroupsResponse> = await appSyncClient.query({
                    query: gql(getGroups),
                    variables: {
                        parentGroup: this.groupId,
                        recursive: false,
                        includeAttributes: true,
                        nextToken,
                    },
                });
                nextToken = groupListResponse.data.getGroups.nextToken;
                groupList = groupList.concat(groupListResponse.data.getGroups.thingGroups);
            } while (nextToken);
            const groups = groupList.map((thingGroup: IoTThingGroup) => {
                return new AWSThingGroup(thingGroup.name, thingGroup.attr);
            });
            this.groups = groups;
            return groups;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return [];
        }
    }

    public async getDevices(): Promise<Device[]> {
        try {
            if (this.devices) {
                return this.devices;
            }
            const startTime = Date.now();
            let nextToken: string = null;
            let deviceIdList: string[] = [];
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
                const deviceIdListResponse: ApolloQueryResult<IoTDevicesResponse> = await appSyncClient.query({
                    query: gql(getDevices),
                    variables: {
                        groupId: this.groupId,
                        nextToken,
                    },
                });
                nextToken = deviceIdListResponse.data.getDevices.nextToken || null;
                deviceIdList = deviceIdList.concat(deviceIdListResponse.data.getDevices.things);
            } while (nextToken);
            const devices: Device[] = await Promise.all(
                deviceIdList.map(async (deviceId: string): Promise<Device> => {
                    const device = new AWSThing(deviceId);
                    await device.init();
                    return device;
                }));
            this.devices = devices;
            console.log(`getDevices for group '${this.groupId}' (${Date.now() - startTime} ms)`);
            return devices;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return [];
        }
    }

    public addSubGroup(newGroup: IGroup): void {
        let alreadyExists: boolean = false;
        this.groups.forEach((group: IGroup) => {
            if (group.groupId === newGroup.groupId) {
                alreadyExists = true;
            }
        });
        if (!alreadyExists) {
            this.groups.push(newGroup);
        }
    }

    public removeSubGroup(removeGroup: IGroup): void {
        this.groups.forEach((group: IGroup, index: number) => {
            if (group.groupId === removeGroup.groupId) {
                this.groups.splice(index, 1);
            }
        });
    }

    private static getLocalisedName(groupAttributes: IoTThingGroupAttribute[], defaultValue: string, language: string = "label_default"): string {
        const match: IoTThingGroupAttribute[] = groupAttributes.filter((attr: IoTThingGroupAttribute) => {
            return attr.key === language;
        });
        if (match.length > 0) {
            return match[0].value;
        } else {
            return defaultValue;
        }
    }
}
