
/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { clientType, Service } from "../backend/AppSyncClientProvider";
import { cancelDeviceOtaUpdate, cancelGroupOtaUpdate, triggerDeviceOtaUpdate, triggerGroupOtaUpdate } from "../graphql/mutations";
import { getOtaUpdates, getOtaUpdateStates } from "../graphql/queries";
import { otaUpdateState } from "../graphql/subscriptions";
import { OrganizationUtils } from "../organization/OrganizationUtils";
import { OtaUpdate, OtaUpdateState } from "./otaTypes";
import { OtaUpdateListener } from "./otaUpdateListener";

interface AppSyncDataResponse {
    getOtaUpdates?: {
        items: any[];
        nextToken?: string;
    };

    getOtaUpdateStates?: {
        items: any[];
        nextToken?: string;
    };
}

export default class OtaManager {

    private static instance: OtaManager = null;

    private otaUpdates: OtaUpdate[] = null;
    private otaUpdateStates: OtaUpdateState[] = null;
    private subscriptions: ZenObservable.Subscription[] = [];
    private listeners: OtaUpdateListener[] = [];

    public static getInstance(): OtaManager {
        if (OtaManager.instance == null) {
            OtaManager.instance = new OtaManager();
        }
        return this.instance;
    }

    public async init(): Promise<void> {
        if (this.subscriptions.length === 0) {
            console.log("Initializing OTA manager");
            await this.subscribe();
            console.log("OTA manager initialized");
        }
    }

    public uninit(): void {
        this.subscriptions.forEach((s: ZenObservable.Subscription) => {
            s.unsubscribe();
        });
        console.log("OTA manager uninitialized");
    }

    public async getOtaUpdates(forceRefresh?: boolean): Promise<OtaUpdate[]> {
        if (this.otaUpdates == null || forceRefresh) {
            return await this.fetchOtaUpdates();
        } else {
            return this.otaUpdates;
        }
    }

    public async getOtaUpdateStates(forceRefresh?: boolean): Promise<OtaUpdateState[]> {
        if (this.otaUpdateStates == null || forceRefresh) {
            return await this.fetchOtaUpdateStates();
        } else {
            return this.otaUpdateStates;
        }
    }

    public async triggerDeviceOtaUpdate(deviceId: string, otaId: string): Promise<void> {
        console.log(`triggerDeviceOtaUpdate ${deviceId}, ${otaId}`);
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
            await appSyncClient.mutate({
                mutation: gql(triggerDeviceOtaUpdate),
                variables: {
                    deviceId,
                    otaId,
                },
            });
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    public async triggerGroupOtaUpdate(groupId: string, otaId: string): Promise<void> {
        console.log(`triggerGroupOtaUpdate ${groupId}, ${otaId}`);
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
            await appSyncClient.mutate({
                mutation: gql(triggerGroupOtaUpdate),
                variables: {
                    groupId,
                    otaId,
                },
            });
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    public async cancelDeviceOtaUpdate(deviceId: string): Promise<void> {
        console.log(`cancelDeviceOtaUpdate ${deviceId}`);
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
            await appSyncClient.mutate({
                mutation: gql(cancelDeviceOtaUpdate),
                variables: {
                    deviceId,
                },
            });
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    public async cancelGroupOtaUpdate(groupId: string): Promise<void> {
        console.log(`cancelGroupOtaUpdate ${groupId}`);
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
            await appSyncClient.mutate({
                mutation: gql(cancelGroupOtaUpdate),
                variables: {
                    groupId,
                },
            });
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    public addListener(listener: OtaUpdateListener): void {
        const listenerIndex = this.listeners.indexOf(listener);
        if (listenerIndex !== -1) {
            this.removeListenerFromIndex(listenerIndex);
        }
        this.listeners.push(listener);
        console.log("Listener added");
    }

    public removeListener(listener: OtaUpdateListener): void {
        const listenerIndex = this.listeners.indexOf(listener);
        if (listenerIndex !== -1) {
            this.removeListenerFromIndex(listenerIndex);
            console.log("Listener removed");
        }
    }

    private async fetchOtaUpdates(): Promise<OtaUpdate[]> {
        let nextToken: string = null;
        let otaUpdates: OtaUpdate[] = [];
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
        try {
            do {
                const otaUpdateResponse: ApolloQueryResult<AppSyncDataResponse> = await appSyncClient.query({
                    query: gql(getOtaUpdates),
                    variables: {
                        nextToken,
                    },
                });
                nextToken = otaUpdateResponse.data.getOtaUpdates.nextToken || null;
                otaUpdates = otaUpdates.concat(otaUpdateResponse.data.getOtaUpdates.items);
            } while (nextToken);
            this.otaUpdates = otaUpdates;
            return otaUpdates;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return [];
        }
    }

    private async fetchOtaUpdateStates(): Promise<OtaUpdateState[]> {
        let nextToken: string = null;
        let otaUpdateStates: OtaUpdateState[] = [];
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
        try {
            do {
                const otaUpdateStatesResponse: ApolloQueryResult<AppSyncDataResponse> = await appSyncClient.query({
                    query: gql(getOtaUpdateStates),
                    variables: {
                        nextToken,
                    },
                });
                nextToken = otaUpdateStatesResponse.data.getOtaUpdateStates.nextToken || null;
                otaUpdateStates = otaUpdateStates.concat(otaUpdateStatesResponse.data.getOtaUpdateStates.items);
            } while (nextToken);
            this.otaUpdateStates = otaUpdateStates;
            return otaUpdateStates;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return [];
        }
    }

    private removeListenerFromIndex(index: number): void {
        this.listeners.splice(index, 1);
    }

    private async subscribe(): Promise<void> {
        const canSees: string[] = await OrganizationUtils.getCanSeeValuesForCurrentUser();
        canSees.forEach((canSee: string) => {
            this.subscriptions.push(this.subscribeWithIdentity(canSee));
        });
    }

    private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
        return appSyncClient.subscribe({
            query: gql(otaUpdateState),
            variables: {
                receiver: identity,
            },
        }).subscribe({
            error: (error: any): void => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscribe();
                }
                console.error(error);
            },
            next: (newData: any): void => {
                const state: OtaUpdateState = newData.data.onOtaUpdateState;
                console.log("OTA state: " + JSON.stringify(state));
                this.listeners.forEach((listener: OtaUpdateListener) => {
                    listener.onOtaUpdateState(state);
                });
            },
        });
    }
}
