/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { ChangeEvent, Component } from "react";
import Utils from "../../data/utils/utils";
import ErrorNote from "../global/error-note";

interface Props {
    createUser: (username: string, isAdmin: boolean) => void;
}

interface State {
    username: string;
    isAdmin: boolean;
    errorMsg: string;
}

export default class NewUserForm extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            username: "",
            isAdmin: false,
            errorMsg: null,
        };
    }

    private saveNewUser = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();

        if (!Utils.isValidEmail(this.state.username)) {
            this.setState({ errorMsg: "Not a valid email address"});
            return;
        }
        this.props.createUser(this.state.username, this.state.isAdmin);
        this.setState({ username: "" });
    }

    private handleCloseErrorNote = (): void => {
        this.setState({ errorMsg: null });
    }

    private renderErrorNote(): JSX.Element {
        if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleCloseErrorNote}
                />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <div className="new-user-form">
                <Table className="new-user-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Add new user:</TableCell>
                            <TableCell align="center">Add admin rights: </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {
                                    <TextField
                                        fullWidth={true}
                                        label="Email"
                                        variant="outlined"
                                        className="new-user-input"
                                        type="email"
                                        value={this.state.username}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void =>
                                            this.setState({ username: event.currentTarget.value })}
                                    />
                                }
                            </TableCell>
                            <TableCell align="center" style={{ paddingLeft: "1.5rem" }}>
                                {
                                    <Checkbox
                                        color="primary"
                                        checked={this.state.isAdmin}
                                        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                                            this.setState({ isAdmin: event.currentTarget.checked })}
                                    />
                                }
                            </TableCell>
                            <TableCell align="right">
                                {
                                    <Button
                                        onClick={this.saveNewUser}
                                        variant="contained"
                                        color="primary"
                                        href=""
                                    >
                                        Save
                                    </Button>
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {this.renderErrorNote()}
            </div>
        );
    }
}
