/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Device } from "../device/Device";
import BaseObservable from "../observer/BaseObservable";
import DeviceSelectorObserver from "./DeviceSelectorObserver";

export default class DeviceSelector extends BaseObservable {
    private static instance: DeviceSelector = null;
    private _devices: Device[] = [];
    private _currentDevice: Device = null;
    private _selectedGroupId: string = null;

    public static getInstance(): DeviceSelector {
        if (DeviceSelector.instance == null) {
            DeviceSelector.instance = new DeviceSelector();
        }
        return this.instance;
    }

    set devices(devices: Device[]) {
        if (this._devices !== devices) {
            const setChanged = DeviceSelector.deviceSetsAreDifferent(this._devices, devices);
            this._devices = devices;
            if (setChanged) {
                console.log("Devices " + this._devices.length);
                const observers = this.observers as DeviceSelectorObserver[];
                observers.forEach((observer: DeviceSelectorObserver) => {
                    observer.onDeviceSetChanged(this._devices);
                });
            }
        }
    }

    public changeGroup(groupId: string): void {
        this._selectedGroupId = groupId;
    }

    public addDevice(device: Device): void {
        const deviceIndex = this._devices.indexOf(device);
        if (deviceIndex < 0) {
            this._devices.push(device);
        } else {
            this._devices[deviceIndex] = device;
        }
    }

    public getCurrentGroupId(): string {
        return this._selectedGroupId;
    }

    public getCurrentDevice(): Device {
        return this._currentDevice;
    }

    public setCurrentDevice(currentDeviceIdUrl: string): void {
        const match = this._devices.find((device: Device) => device.getId() === currentDeviceIdUrl) || null;
        if (this._currentDevice !== match) {
            this._currentDevice = match;
            this.notify();
        }
    }

    public addObserver(observer: DeviceSelectorObserver): void {
        super.addObserver(observer);
        this.notify();
    }

    public removeObserver(observer: DeviceSelectorObserver): void {
        super.removeObserver(observer);
    }

    public notify(): void {
        const observers = this.observers as DeviceSelectorObserver[];
        observers.forEach((observer: DeviceSelectorObserver) => {
            observer.onSelectedDeviceChanged(this._currentDevice);
        });
    }

    private static deviceSetsAreDifferent(oldSet: Device[], newSet: Device[]): boolean {
        if (oldSet.length !== newSet.length) {
            return true;
        }
        const diff = oldSet.filter((o: Device, index: number) => {
            return o.getId() !== newSet[index].getId();
        });
        return diff.length > 0;
    }
}
