/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Tab, Tabs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import React, { ChangeEvent, Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { RouteComponentPropsParams } from "../../@types/routerprops";
import { Device } from "../../data/device/Device";
import { IGroup } from "../../data/group/IGroup";
import { Tree } from "../../data/tree/tree";
import SearchBar from "../controls/search-bar";
import ArrowBack from "./../../assets/arrow-back-24px.svg";
import ArrowForward from "./../../assets/arrow-forward-24px.svg";
import SearchIcon from "./../../assets/baseline-search-24px.svg";
import ClientProperties from "./../../data/clientSpecific/ClientProperties";
import DeviceSelector from "./../../data/deviceSelector/DeviceSelector";
import DeviceSelectorObserver from "./../../data/deviceSelector/DeviceSelectorObserver";
import Utils, { DeviceBrowserMode } from "./../../data/utils/utils";
import Loader from "./../global/loader";
import IoTListGroup from "./list-group";
import IoTListItem from "./list-item";
import ListTab from "./list-tab";
import IoTDeviceTree from "./tree";

interface Props extends RouteComponentProps<RouteComponentPropsParams> {
    devices: Device[];
    groups: IGroup[];
    tree: Tree;
}

interface State {
    searchFilter: string;
    drawerOpen: boolean;
    selectedDevice: Device;
    tabValue: number;
}

class IoTList extends Component<Props, State> implements DeviceSelectorObserver {

    private deviceSelector: DeviceSelector = DeviceSelector.getInstance();

    constructor(props: Props) {
        super(props);
        this.state = {
            searchFilter: "",
            drawerOpen: false,
            selectedDevice: null,
            tabValue: 0,
        };
    }

    public onSelectedDeviceChanged(device: Device): void {
        this.setState({ selectedDevice: device });
    }

    public onDeviceSetChanged(devices: Device[]): void {
        console.log("onDeviceSetChanged");
    }

    public componentDidMount(): void {
        this.deviceSelector.addObserver(this);
        if (!this.props.match.params.id) {
            this.toggleDrawer();
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.match.params.id !== this.props.match.params.id
            || Utils.isEmpty(this.props.match.params)) {
            this.deviceSelector.setCurrentDevice(this.props.match.params.id);
        }

        if (this.props.match.params.id && !this.state.selectedDevice) {
            this.deviceSelector.setCurrentDevice(this.props.match.params.id);
        }
    }

    public componentWillUnmount(): void {
        this.deviceSelector.removeObserver(this);
    }

    private getDisplayName = (): string => {
        if (this.state.selectedDevice) {
            return Utils.getDisplayName(this.state.selectedDevice);
        }
    }

    private toggleDrawer = (): void => {
        this.setState((prevState: State) => ({
            drawerOpen: !prevState.drawerOpen,
        }));
    }

    private renderGroupList = (): JSX.Element | JSX.Element[] => {
        if (!this.props.groups) {
            return <Loader />;
        }
        return this.getGroupsList();
    }

    private renderDeviceList = (): JSX.Element | JSX.Element[] => {
        if (!this.props.devices) {
            return <Loader />;
        }
        return this.getIoTListItems();
    }

    private getIoTListItems(): JSX.Element | JSX.Element[] {
        if (this.props.devices && this.props.devices.length >= 0) {
            let filteredDevices: Device[] = this.props.devices.slice();
            if (this.state.searchFilter !== "") {
                filteredDevices = this.props.devices.filter((device: Device) => {
                    return this.isFilterMatch(Utils.getDisplayName(device));
                });
            }

            filteredDevices = filteredDevices.sort((a: Device, b: Device) => {
                return Utils.compareDevicesForSorting(a, b);
            });

            const iotItemsList = filteredDevices.map((device: Device) => {
                if (Device.isValid(device)) {
                    return (
                        <IoTListItem
                            key={device.getId()}
                            device={device}
                            deviceSelected={(deviceId: string): void => console.log("deviceSelected " + deviceId)}
                            adminMode={false}
                        />
                    );
                }
                return undefined;
            });
            if (iotItemsList.length === 0) {
                return <li className="iot-item error">No things found!</li>;
            }
            return iotItemsList;
        }
    }

    private getGroupsList(): JSX.Element | JSX.Element[] {
        if (this.props.groups && this.props.groups.length >= 0) {
            let groups: IGroup[] = this.props.groups.slice();
            if (this.state.searchFilter !== "") {
                groups = this.props.groups.filter((group: IGroup) => {
                    return this.isFilterMatch(group.groupId);
                });
            }

            groups = groups.sort((a: IGroup, b: IGroup) => {
                return Utils.compareGroupsForSorting(a, b);
            });

            const iotGroupsList = groups.map((group: IGroup) => {
                return (
                    <IoTListGroup
                        key={group.groupId}
                        group={group}
                    />
                );
            });
            if (iotGroupsList.length === 0) {
                return <li className="iot-item error">No groups found!</li>;
            }
            return iotGroupsList;
        }
    }

    private isFilterMatch(value: string): boolean {
        return (value.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1);
    }

    private changeFilter = (event: React.FormEvent<HTMLInputElement>): void => {
        if (event !== null) {
            this.setState({ searchFilter: event.currentTarget.value });
        }
    }

    private handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
        this.setState({ tabValue: newValue });
    }

    private addChildGroup(parentGroup: IGroup): void {
        console.log("Ignore add group when not in admin mode");
    }

    private removeGroup(groupName: IGroup): void {
        console.log("Ignore remove group when not in admin mode");
    }

    private renderLegacyBrowserMode(): JSX.Element {
        return (
            <React.Fragment>
                <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleChange}
                    variant="fullWidth"
                    className="list-tabs"
                >
                    <Tab label={ClientProperties.DEVICES_TAB_TITLE} />
                    <Tab label={ClientProperties.GROUPS_TAB_TITLE} />
                </Tabs>
                <List>
                    <ListTab value={this.state.tabValue} index={0}>
                        {this.renderDeviceList()}
                    </ListTab>
                    <ListTab value={this.state.tabValue} index={1}>
                        {this.renderGroupList()}
                    </ListTab>
                </List>
                <div className="iot-drawer-list-footer col-sm-12 col-xsm-12">
                    <div className="search-bar col-sm-10 col-xsm-10">
                        <div className="search-icon-container">
                            <img className="search-icon" alt="" src={SearchIcon} />
                        </div>
                        <input
                            className="search-input"
                            type="text"
                            placeholder="Search"
                            value={this.state.searchFilter}
                            onChange={this.changeFilter}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private onSearchTriggered(searchString: string): void {
        console.log("onSearchTriggered: " + searchString);
        this.setState({ searchFilter: searchString });
    }

    private renderTreeBrowserMode(): JSX.Element {
        return (
            <React.Fragment>
                <IoTDeviceTree
                    tree={this.props.tree}
                    searchFilter={this.state.searchFilter}
                    adminMode={false}
                    addGroup={this.addChildGroup}
                    removeGroup={this.removeGroup}
                />
                <SearchBar
                    searchString={this.state.searchFilter}
                    onSearchTriggered={(searchString: string): void => this.onSearchTriggered(searchString)}
                    className="tree-search-bar-container"
                />
            </React.Fragment>
        );
    }

    private renderDeviceBrowserMode(deviceBrowserMode: DeviceBrowserMode): JSX.Element {
        if (deviceBrowserMode !== DeviceBrowserMode.Legacy) {
            return this.renderTreeBrowserMode();
        } else {
            return this.renderLegacyBrowserMode();
        }
    }

    public render(): JSX.Element {
        return (
            <div className="iot-device-list-container col-sm-3 col-xsm-5">
                <Button onClick={this.toggleDrawer}>
                    <img src={ArrowForward} alt="forward arrow" />
                </Button>
                <span>{this.getDisplayName()}</span>
                <Drawer
                    open={this.state.drawerOpen}
                    classes={{ paper: "col-xlg-3 col-sm-4 col-xsm-9 iot-drawer" }}
                    onClose={this.toggleDrawer}
                >
                    <div className="iot-drawer-list">
                        <div className="iot-drawer-list-header">
                            <Button onClick={this.toggleDrawer}>
                                <img src={ArrowBack} alt="back arrow" />
                            </Button>
                            <span className="iot-drawer-list-header-title">{ClientProperties.DEVICE_DRAWER_TITLE}</span>
                        </div>
                        {this.renderDeviceBrowserMode(Utils.getDeviceBrowserMode())}
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default withRouter(IoTList);
