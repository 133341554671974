/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Button from "@material-ui/core/Button";
import MUIDataTable, { Display, MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions, SelectableRows } from "mui-datatables";
import React, { Component, Fragment } from "react";
import { ClientProperties } from "../../data/clientSpecific/ClientProperties";
import IEvent, { EventState } from "../../data/clientSpecific/IEvent";
import { Device } from "../../data/device/Device";
import DeviceSelector from "../../data/deviceSelector/DeviceSelector";
import DeviceSelectorObserver from "../../data/deviceSelector/DeviceSelectorObserver";
import EventSet from "../../data/events/EventSet";
import EventsRepository from "../../data/events/EventsRepository";
import { EventObserver } from "../../data/observer/EventObserver";
import Utils, { DateTimeFormatTarget } from "../../data/utils/utils";
import Loader from "../global/loader";

interface Props {
    devices: Device[];
}

interface State {
    singleDeviceEvents: IEvent[];
    singleDeviceEventSet: EventSet;
    selectedDevice: Device;
    isLoading: boolean;
    settingEventStateId: string;
}

type EventTableCell = string | number | JSX.Element;
type EventTableRow = EventTableCell[];

class EventsWrapper extends Component<Props, State> implements EventObserver, DeviceSelectorObserver {

    private deviceSelector: DeviceSelector = DeviceSelector.getInstance();

    private readonly tableColumns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            options: {
                filter: false,
                sort: false,
                display: "false" as Display,
                viewColumns: false,
            },
        },
        {
            name: "timestamp",
            label: ClientProperties.EVENT_TABLE_LABELS.timestamp,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "description",
            label: ClientProperties.EVENT_TABLE_LABELS.description,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "type",
            label: ClientProperties.EVENT_TABLE_LABELS.type,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "severity",
            label: "Severity",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sensorName",
            label: ClientProperties.EVENT_TABLE_LABELS.sensorName,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sensorValue",
            label: ClientProperties.EVENT_TABLE_LABELS.sensorValue,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "eventState",
            label: ClientProperties.EVENT_TABLE_LABELS.eventState,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta, updateValue: any): JSX.Element => {
                    return this.renderAcknowledgementCell(tableMeta);
                },
            },
        },
    ];

    private readonly tableOptions: MUIDataTableOptions = {
        filterType: "checkbox",
        customToolbarSelect: (selectedRows: any, displayData: Array<{ data: any[]; dataIndex: number }>, setSelectedRows: any): React.ReactNode => {
            // TODO: This is not supported yet.
            return null;
        },
        onRowsDelete: (rowsDeleted: any[]): void => {
            // TODO: This is not supported yet.
        },
        selectableRows: "none" as SelectableRows,
        selectableRowsOnClick: true,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            settingEventStateId: null,
            singleDeviceEvents: null,
            singleDeviceEventSet: null,
            selectedDevice: null,
        };
    }

    public async componentDidMount(): Promise<void> {
        this.deviceSelector.addObserver(this);
    }

    public async componentDidUpdate(_prevProps: Props, prevState: State): Promise<void> {
        if (Device.isValid(this.state.selectedDevice)) {
            if (prevState.selectedDevice == null ||
                prevState.selectedDevice.getId() !== this.state.selectedDevice.getId()) {
                await this.fetchDeviceSpecificEvents(this.state.selectedDevice);
            }
        }
    }

    public componentWillUnmount(): void {
        if (this.state.singleDeviceEventSet) {
            this.state.singleDeviceEventSet.removeObserver(this);
        }
        this.deviceSelector.removeObserver(this);
    }

    public async onSelectedDeviceChanged(device: Device): Promise<void> {
        if (this.state.singleDeviceEventSet) {
            this.state.singleDeviceEventSet.removeObserver(this);
        }
        this.setState({ selectedDevice: device });
        await this.fetchDeviceSpecificEvents(device);
    }

    public onDeviceSetChanged(devices: Device[]): void {
        console.log("onDeviceSetChanged");
    }

    public onEventSet(eventSet: EventSet): void {
        console.log("onEventSet " + eventSet.getEvents().length);
        if (this.state.selectedDevice.getId() === eventSet.getId()) {
            this.setState({ singleDeviceEventSet: eventSet, settingEventStateId: null });
            this.forceUpdate();
        }
    }

    private async fetchDeviceSpecificEvents(device: Device): Promise<void> {
        if (device) {
            this.setState({isLoading: true});
            const events: EventSet = await device.getEvents();
            if (events != null) {
                events.addObserver(this);
                this.setState({ isLoading: false, singleDeviceEvents: events.getEvents(), singleDeviceEventSet: events });
            } else {
                this.setState({ isLoading: false, singleDeviceEvents: null, singleDeviceEventSet: null });
            }
        }
    }

    private getTableData(): EventTableRow[] {
        const rows: EventTableRow[] = [];
        if (this.state.singleDeviceEvents) {
            this.state.singleDeviceEvents.forEach((event: IEvent) => {
                if (event) {
                    if (event.eventState === EventState.Active) {
                        const row: EventTableRow = [];
                        row.push(event.timestamp);
                        row.push(Utils.convertTimestampToString(event.updatedTimestamp, DateTimeFormatTarget.EventsTable));
                        row.push(EventsRepository.getInstance().getEventDescription(event.eventId));
                        row.push(event.type);
                        row.push(event.severity);
                        row.push(event.sensorName);
                        row.push(event.sensorValue);
                        rows.push(row);
                    }
                }

            });
        }
        return rows;
    }

    private getEventsTable(): JSX.Element {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
              <MUIDataTable
                title={ClientProperties.EVENT_TABLE_TITLE}
                data={this.getTableData()}
                columns={this.tableColumns}
                options={this.tableOptions}
              />
        );
    }

    private renderAcknowledgementCell(tableMeta: MUIDataTableMeta): JSX.Element {
        if (this.state.singleDeviceEvents == null || tableMeta == null) {
            return null;
        }
        const timestamp: string = tableMeta.rowData[0];
        const events: IEvent[] = this.state.singleDeviceEvents.filter((event: IEvent) => {
            if (event) {
                return event.timestamp === timestamp;
            }
            return false;
        });
        if (events.length === 1) {
            const event: IEvent = events[0];
            if (event.eventState === EventState.Active) {
                return (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={ (): void => {
                                this.setState({ settingEventStateId: timestamp });
                                if (this.state.singleDeviceEventSet) {
                                    this.state.singleDeviceEventSet.deactivateEvent(event);
                                }
                            }
                        }
                    >
                        {ClientProperties.EVENT_ACKNOWLEDGE_BUTTON_TITLE}
                    </Button>
                );
            } else {
                if (this.state.settingEventStateId && this.state.settingEventStateId === timestamp) {
                    return <Loader/>;
                } else {
                    return null;
                }
            }

        } else {
            return null;
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                {this.getEventsTable()}
            </Fragment>
        );
    }
}

export default EventsWrapper;
