/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import {ApolloQueryResult} from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import {shadowUpdates} from "../graphql/subscriptions";
import {OrganizationUtils} from "../organization/OrganizationUtils";
import Device from "./Device";
import {ShadowUpdatedResponse} from "./IShadowResponse";

export default class ShadowSubscriptionManager {

    private devices: Device[] = [];
    private subscriptions: ZenObservable.Subscription[] = [];
    private static instance: ShadowSubscriptionManager = null;

    public static getInstance(): ShadowSubscriptionManager {
        if (ShadowSubscriptionManager.instance == null) {
            ShadowSubscriptionManager.instance = new ShadowSubscriptionManager();
        }
        return this.instance;
    }

    public addDevice(device: Device): void {
        if (this.devices && this.devices.indexOf(device) === -1) {
            this.devices.push(device);
            if (this.devices.length === 1) {
                this.subscribe().catch((reason: any) => console.error("Failed to subscribe: " + JSON.stringify(reason)));
            }
        }
    }
    public removeDevice(device: Device): void {
        const observerIndex = this.devices.indexOf(device);
        if (observerIndex !== -1) {
            if (this.devices.length === 1) {
                do {
                    this.subscriptions.pop().unsubscribe();
                } while (this.subscriptions.length > 0);
            }
            this.devices.splice(observerIndex, 1);
        }
    }

    private findDevice(deviceId: string): Device {
        let match: Device = null;
        this.devices.forEach((device: Device) => {
            if (deviceId === device.getId()) {
                match = device;
            }
        });
        return match;
    }

    private async subscribe(): Promise<void> {
        const canSees: string[] = await OrganizationUtils.getCanSeeValuesForCurrentUser();
        canSees.forEach((canSee: string) => {
            this.subscriptions.push(this.subscribeWithIdentity(canSee));
        });
    }

    private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
        return appSyncClient.subscribe({
            query: gql(shadowUpdates),
            variables: {
                receiver: identity,
            },
        }).subscribe({
            error: (error: any): void => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscriptions.push(this.subscribeWithIdentity(identity));
                }
                console.error(error);
            },
            next: (response: ApolloQueryResult<ShadowUpdatedResponse>): void => {
                const device = this.findDevice(response.data.onShadowUpdated.reported.deviceId);
                const { desired, reported, timestamp } = response.data.onShadowUpdated;
                if (device) {
                    device.setState(reported, desired, timestamp);
                }
            },
        });
    }
}
