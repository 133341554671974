/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ClientProperties from "../../data/clientSpecific/ClientProperties";
import { Device } from "../../data/device/Device";
import DeviceSelector from "../../data/deviceSelector/DeviceSelector";
import Utils from "../../data/utils/utils";
import SettingsWrapper from "../settings/settings-wrapper";
import LocationIcon from "../status/location-icon";
import { BrowserItemBase, TOOLTIP_DELAY_MS } from "./browser-item-base";

class TreeNode extends BrowserItemBase {

    private renderStatusContainer(): JSX.Element {
        if (!this.props.adminMode) {
            return (
                <div className="iot-status-container col-sm-4 col-xsm-4">
                    <SettingsWrapper
                        device={this.props.device}
                        isIcon={true}
                    />
                    {this.getSignalStatusElement()}
                    <LocationIcon
                        device={this.props.device}
                    />
                    {this.getPowerSupplyStatusElement()}
                </div>
            );
        }
    }

    public render(): JSX.Element {
        const nodeIsSelected = TreeNode.isDeviceCurrentlySelected(this.props.device.getId());
        const div = nodeIsSelected ? "tree-node-selected" : "tree-node-not-selected";
        return (
            <div
                className={div}
                draggable={this.props.adminMode}
                onDragStart={(event: React.DragEvent): void => this.handleDragStart(event)}
            >
                <ReactTooltip delayShow={TOOLTIP_DELAY_MS}/>
                <NavLink
                    to={this.getLinkRoute()}
                    className="iot-item-link col-sm-8 col-xsm-8"
                    isActive={(): boolean => nodeIsSelected}
                    onClick={(): void => this.props.deviceSelected(this.props.device.getId())}
                >
                    <div
                        className="iot-icon col-sm-1 col-xsm-1"
                        data-tip={ClientProperties.getAlarmTooltipFromEntities(this.state.alarmTypes)}
                    >
                        {this.getDeviceIcon()}
                    </div>
                    <div className="iot-name col-sm-10 col-xsm-10" data-tip={this.getTooltip()}>
                        {Utils.getDisplayName(this.props.device)}
                    </div>
                </NavLink >
                {this.renderStatusContainer()}
            </div >
        );
    }

    private static isDeviceCurrentlySelected(deviceId: string): boolean {
        return (Device.isValid(DeviceSelector.getInstance().getCurrentDevice()) &&
        DeviceSelector.getInstance().getCurrentDevice().getId() === deviceId);
    }
}

export default withRouter(TreeNode);
