/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import IData from "../clientSpecific/IData";
import BaseObservable from "../observer/BaseObservable";

export abstract class LatestData extends BaseObservable {
    public abstract getId(): string;
    public abstract getLatestData(): IData;
    public abstract fetch(): Promise<void>;
    public abstract setData(data: IData): void;
}

export default LatestData;
