/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import CompletePasswordInputs from "../components/inputs/complete-password";
import LoginInputs from "../components/inputs/login";
import AuthWrapper from "../data/auth/authWrapper";

interface State {
    password: string;
    username: string;
    newPassword: string;
    confirmPassword: string;
    phase: string;
    verificationCode: string;
    user: any;
    loader: boolean;
    [key: string]: any;
    passwordError: boolean;
    usernameError: boolean;
}

interface Props {
}

export default class LoginContent extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            confirmPassword: "",
            loader: false,
            newPassword: "",
            password: "",
            phase: "",
            user: {},
            username: "",
            verificationCode: "",
            passwordError: false,
            usernameError: false,
        };
    }

    private userInput(key: string, value: string): void {
        this.setState({
            [key]: value,
            usernameError: false,
            passwordError: false,
        });
    }

    private renderInputFields(): JSX.Element {
        switch (this.state.phase) {
            case "confirmPassword":
                return (
                    <CompletePasswordInputs
                        loader={this.state.loader}
                        completePassword={(): Promise<void> => this.completePassword()}
                        userInput={(key: string, value: string): void => this.userInput(key, value)}
                        confirmPassword={this.state.confirmPassword}
                        newPassword={this.state.newPassword}
                    />
                );
            default:
                return (
                    <LoginInputs
                        loader={this.state.loader}
                        logIn={(): Promise<void> => this.logIn()}
                        userInput={(key: string, value: string): void => this.userInput(key, value)}
                        username={this.state.username}
                        password={this.state.password}
                        passwordError={this.state.passwordError}
                        usernameError={this.state.usernameError}
                    />
                );
        }
    }

    public async completePassword(): Promise<void> {
        try {
            this.setState({ loader: true });
            await AuthWrapper.completeNewPassword(this.state.user, this.state.newPassword);
        } catch (error) {
            this.setState({ loader: false });
            console.error(error);
        }
    }

    public async logIn(): Promise<void> {
        if (this.state.username === "" && this.state.password === "") {
            // Do nothing
        } else {
            try {
                this.setState({ loader: true });
                const user = await AuthWrapper.logIn(this.state.username, this.state.password);
                switch (user.challengeName) {
                    case "NEW_PASSWORD_REQUIRED":
                        this.setState({
                            loader: false,
                            phase: "confirmPassword",
                            user,
                        });
                        break;
                }
            } catch (error) {
                this.setState({ loader: false });
                switch (error.toString()) {
                    case "Error: Empty password":
                        this.setState({
                            passwordError: true,
                        });
                        break;
                    case "Error: Incorrect username or password.":
                        this.setState({
                            passwordError: true,
                            usernameError: true,
                        });
                        break;
                    case "Error: Username cannot be empty":
                        this.setState({
                            usernameError: true,
                        });
                        break;
                    case "Error: User does not exist.":
                        this.setState({
                            usernameError: true,
                        });
                        break;
                    default:
                        this.setState({
                            usernameError: false,
                            passwordError: false,
                        });
                        break;
                }
            }
        }
    }

    public render(): JSX.Element {
        return (
            <section className="login-container col-md-12">
                <div className="login-content col-lg-4 col-md-6 col-sm-6 col-xsm-8">
                    {this.renderInputFields()}
                </div>
            </section >
        );
    }

}
