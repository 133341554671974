/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import DeviceSettings from "../clientSpecific/DeviceSettings";
import { DeviceStateProperties } from "../clientSpecific/DeviceStateProperties";
import AWSDataSet from "../data/AWSDataSet";
import AWSLatestData from "../data/AWSLatestData";
import AWSSessionSet from "../data/AWSSessionSet";
import DataSet from "../data/DataSet";
import LatestData from "../data/LatestData";
import SessionSet from "../data/SessionSet";
import AWSEventSet from "../events/AWSEventSet";
import EventSet from "../events/EventSet";
import { clientChangeShadow } from "../graphql/mutations";
import { getDeviceShadow } from "../graphql/queries";
import { DeviceStateObserver } from "../observer/DeviceStateObserver";
import Device from "./Device";
import DeviceState, { StateOptions } from "./DeviceState";
import { ShadowResponse } from "./IShadowResponse";
import ShadowSubscriptionManager from "./ShadowSubscriptionManager";

export interface SettingsPayload {
    [key: string]: any;
}

export default class AWSThing extends Device {

    private readonly deviceId: string;
    private state: DeviceState;

    private dataSet: DataSet = null;
    private latestData: LatestData = null;
    private eventSet: EventSet = null;

    constructor(deviceId: string) {
        super();
        this.deviceId = deviceId;
    }

    public async store(deviceSettings: DeviceSettings): Promise<void> {
        console.log("Store settings");
        const settingsKeys = Object.keys(deviceSettings) as Array<keyof DeviceSettings>;
        const payload: SettingsPayload = {};
        settingsKeys.forEach((key: keyof DeviceSettings) => {
            if (deviceSettings[key] != null) {
                payload[key] = deviceSettings[key].value;
            }
        });
        try {
            this.setState(null, payload as DeviceStateProperties, null);
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
            await appSyncClient.mutate({
                mutation: gql(clientChangeShadow),
                variables: {
                    deviceId: this.deviceId,
                    payload,
                },
            });
        } catch (error) {
            console.log("Error: " + JSON.stringify(error));
        }
    }

    public getId(): string {
        return this.deviceId;
    }

    public getState(): DeviceState {
        return this.state;
    }

    public addObserver(observer: DeviceStateObserver): void {
        super.addObserver(observer);
        if (this.observers.length === 1) {
            ShadowSubscriptionManager.getInstance().addDevice(this);
        }
    }

    public removeObserver(observer: DeviceStateObserver): void {
        super.removeObserver(observer);
        if (this.observers.length === 0) {
            ShadowSubscriptionManager.getInstance().removeDevice(this);
        }
    }

    public notify(): void {
        const observers = this.observers as DeviceStateObserver[];
        if (observers && observers.length !== 0) {
            observers.forEach((observer: DeviceStateObserver) => {
                observer.onDeviceStateUpdate(this);
            });
        }
    }

    public async init(): Promise<void> {
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DEVICE, clientType.TYPE_COGNITO);
            const deviceStateResponse: ApolloQueryResult<ShadowResponse> = await appSyncClient.query({
                query: gql(getDeviceShadow),
                variables: {
                    deviceId: this.deviceId,
                },
            });
            const { desired, reported, timestamp } = deviceStateResponse.data.getDeviceShadow;
            this.state = new DeviceState(reported, desired, timestamp);
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
        }
    }

    public setState(current: DeviceStateProperties, next: DeviceStateProperties,
                    stateUpdated: number): void {
        if (this.state) {
            if (!current) {
                current = this.state.getStateProperties(StateOptions.Current);
            }
            if (!next) {
                next = this.state.getStateProperties(StateOptions.Next);
            }
            if (!stateUpdated) {
                stateUpdated = Date.now();
            }
        }
        this.state = new DeviceState(current, next, stateUpdated);
        this.notify();
    }

    // REFACTOR: Built in dependency, hard to unit test.
    public async getSessions(startTimestamp: number, endTimestamp: number): Promise<SessionSet> {
        try {
            const sessionSet = new AWSSessionSet(this.deviceId, startTimestamp, endTimestamp);
            await sessionSet.fetch();
            return sessionSet;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return null;
        }
    }

    // REFACTOR: Built in dependency, hard to unit test.
    public async getData(startTimestamp: number, endTimestamp: number): Promise<DataSet> {
        try {
            if (this.dataSet && this.dataSet.getStartTimestamp() === startTimestamp
                && this.dataSet.getEndTimestamp() === endTimestamp) {
                return this.dataSet;
            }
            const dataSet = new AWSDataSet(this.deviceId, startTimestamp, endTimestamp);
            await dataSet.fetch();
            this.dataSet = dataSet;
            return dataSet;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return null;
        }
    }

    // REFACTOR: Built in dependency, hard to unit test.
    public async getLatestData(): Promise<LatestData> {
        try {
            if (!this.latestData) {
                this.latestData = new AWSLatestData(this.deviceId);
                await this.latestData.fetch();
            }
            return this.latestData;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return null;
        }
    }

    public async getEvents(): Promise<EventSet> {
        try {
            if (this.eventSet) {
                return this.eventSet;
            }
            const eventSet = new AWSEventSet(this.deviceId);
            await eventSet.fetch();
            this.eventSet = eventSet;
            return eventSet;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return null;
        }
    }
}
