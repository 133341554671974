/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import { dataUpdates } from "../graphql/subscriptions";
import { OrganizationUtils } from "../organization/OrganizationUtils";
import { LatestData } from "./LatestData";

// REFACTOR: Could LatestDataSubscriptionManager and DataSubscriptionManager be
// combined through Generics?
export default class LatestDataSubscriptionManager {

    private subscribedData: LatestData[] = [];
    private subscriptions: ZenObservable.Subscription[] = [];
    private static instance: LatestDataSubscriptionManager = null;

    public static getInstance(): LatestDataSubscriptionManager {
        if (LatestDataSubscriptionManager.instance == null) {
            LatestDataSubscriptionManager.instance = new LatestDataSubscriptionManager();
        }
        return this.instance;
    }

    public subscribeData(dataSet: LatestData): void {
        if (this.subscribedData && this.subscribedData.indexOf(dataSet) === -1) {
            this.subscribedData.push(dataSet);
            if (this.subscriptions.length === 0) {
                this.subscribe().catch((reason: any) => console.error("Failed to subscribe: " + JSON.stringify(reason)));
            }
        }
    }

    public removeSubscription(dataSet: LatestData): void {
        const observerIndex = this.subscribedData.indexOf(dataSet);
        if (observerIndex !== -1) {
            if (this.subscribedData.length === 1) {
                do {
                    this.subscriptions.pop().unsubscribe();
                } while (this.subscriptions.length > 0);
            }
            this.subscribedData.splice(observerIndex, 1);
        }
    }

    private findDataSet(deviceId: string): LatestData {
        let match: LatestData = null;
        this.subscribedData.forEach((latestData: LatestData) => {
            if (deviceId === latestData.getId()) {
                match = latestData;
            }
        });
        return match;
    }

    private async subscribe(): Promise<void> {
        const canSees: string[] = await OrganizationUtils.getCanSeeValuesForCurrentUser();
        canSees.forEach((canSee: string) => {
            this.subscriptions.push(this.subscribeWithIdentity(canSee));
        });
    }

    private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
        console.log("LatestData subs for " + identity);
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DATA, clientType.TYPE_COGNITO);
        return appSyncClient.subscribe({
            query: gql(dataUpdates),
            variables: {
                receiver: identity,
            },
        }).subscribe({
            error: (error: any): void => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscriptions.push(this.subscribeWithIdentity(identity));
                }
                console.error(error);
            },
            next: (newData: any): void => {
                const dataSet = this.findDataSet(newData.data.onDataUpdates.deviceId);
                if (dataSet) {
                    dataSet.setData(newData.data.onDataUpdates);
                }
            },
        });
    }
}
