/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Dialog, List, ListItem, ListItemText, Switch, TextField } from "@material-ui/core";
import React, { Component } from "react";
import { DeviceSettings } from "../../data/clientSpecific/DeviceSettings";
import { Device } from "../../data/device/Device";
import Utils from "../../data/utils/utils";
import Loader from "../global/loader";
import DeviceIcon from "./../../assets/memory_24px.svg";

interface Props {
    selectedDevice: Device;
    closeSettings: () => void;
}

interface State {
    ledEnabled: boolean;
    displayName: string;
}

export default class IoTSettingsPopup extends Component<Props, State> {

    private deviceSettings: DeviceSettings = this.getSettings();
    private deviceSettingsChanged: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            ledEnabled: this.deviceSettings.ledEnabled.value || false,
            displayName: this.deviceSettings.displayName.value || "",
        };
    }

    private getSettings(): DeviceSettings {
        if (this.props.selectedDevice && this.props.selectedDevice.getState()) {
            return this.props.selectedDevice.getState().exportSettings();
        }
        return null;
    }

    private handleCancel = (): void => {
        this.deviceSettingsChanged = false;
        this.props.closeSettings();
    }

    private saveDeviceSettings = (): void => {
        if (this.deviceSettingsChanged) {
            this.props.selectedDevice.store(this.deviceSettings);
        }
        this.props.closeSettings();
    }

    private ledStateChanged = (): void => {
        if (this.deviceSettings && this.deviceSettings.ledEnabled !== undefined) {
            this.deviceSettingsChanged = true;
            this.deviceSettings.ledEnabled.value = !this.deviceSettings.ledEnabled.value;
            this.setState({ ledEnabled: this.deviceSettings.ledEnabled.value });
        }
    }

    private displayNameChanged = (event: React.FormEvent<HTMLInputElement>): void => {
        const newName = event.currentTarget.value;
        if (this.deviceSettings && this.deviceSettings.displayName !== undefined) {
            this.deviceSettingsChanged = true;
            this.deviceSettings.displayName.value = newName;
            this.setState({ displayName: newName });
        }
    }

    private getLoader(key: keyof DeviceSettings): JSX.Element {
        if (this.deviceSettings[key].pending) {
            return (
                <Loader
                    size="1.5rem"
                    circleHeight="0.2rem"
                    topBottomPadding="0"
                    leftRightPadding="0.2rem"
                />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Dialog
                onClose={this.props.closeSettings}
                open={true}
            >
                <div className="popup-inner">
                    <div className="popup-header">
                        <img className="device-icon" alt="" src={DeviceIcon} />
                        {Utils.getDisplayName(this.props.selectedDevice)}
                    </div>
                    <div className="popup-body">
                        <List>
                            <div className="setting-item">
                                <ListItem>
                                    <ListItemText id="switch-list-label-display-name" primary="Display name" />
                                    <TextField
                                        value={this.state.displayName}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                            this.displayNameChanged(event)}
                                    />
                                    {this.getLoader("displayName")}
                                </ListItem>
                            </div>
                            <div className="setting-item">
                                <ListItem>
                                    <ListItemText id="switch-list-label-led" primary="LED on/off" />
                                    <Switch
                                        edge="end"
                                        onChange={this.ledStateChanged}
                                        checked={this.state.ledEnabled}
                                        color="primary"
                                    />
                                    {this.getLoader("ledEnabled")}
                                </ListItem>
                            </div>
                            <div className="buttons-container">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="button left"
                                    onClick={this.saveDeviceSettings}
                                >
                                    Apply
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="button"
                                    onClick={this.handleCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </List>
                    </div>
                </div>
            </Dialog>
        );
    }
}
