/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import {ApolloQueryResult} from "apollo-client";
import gql from "graphql-tag";
import awsconfig from "../../aws-config";
import AuthWrapper from "../auth/authWrapper";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import {
    createOrganizationUser,
    deleteUser,
    setUserAdminStatus,
    updateOrganizationDetails,
} from "../graphql/mutations";
import { getOrganizationDetails, getOrganizationUsers } from "../graphql/queries";
import AWSOrganization from "./AWSOrganization";
import AWSUser from "./AWSUser";

interface CreateUserPayload {
    [key: string]: any;
}

// TODO: Abstract this and move AWS implementation to AWSOrganizationUtils
export class OrganizationUtils {

    public static async getOrganization(orgName: string): Promise<AWSOrganization> {
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.USERS, clientType.TYPE_COGNITO);
        const organizationResponse: ApolloQueryResult<any> = await appSyncClient.query({
            query: gql(getOrganizationDetails),
            variables: {
                organizationName: orgName,
                userPoolId: awsconfig.Auth.userPoolId,
            },
        });
        if (!organizationResponse.data.getOrganizationDetails.name ||
            organizationResponse.data.getOrganizationDetails.name.length === 0) {
            throw new Error("User has no organization!");
        }
        return new AWSOrganization(organizationResponse.data.getOrganizationDetails.name,
            organizationResponse.data.getOrganizationDetails.displayName,
            null);
    }

    public static async getOrganizationUsers(orgName: string, token: string): Promise<AWSUser[]> {
        const users: AWSUser[] = [];
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.USERS, clientType.TYPE_COGNITO);
        const usersResponse: ApolloQueryResult<any> = await appSyncClient.query({
            query: gql(getOrganizationUsers),
            variables: {
                organizationName: orgName,
                userPoolId: awsconfig.Auth.userPoolId,
                nextToken: token,
            },
            fetchPolicy: "network-only",
        });
        usersResponse.data.getOrganizationUsers.users.forEach((user: any) => {
            users.push(new AWSUser(user.username, user.admin));
        });
        return users;
    }

    public static async isCurrentUserAdmin(): Promise<boolean> {
        let isAdmin: boolean = false;
        const groups: string[] = await this.getCurrentUsersGroups();
        if (groups && groups.length > 0) {
            groups.forEach((group: string) => {
                if (this.isAdminGroup(group)) {
                    isAdmin = true;
                }
            });
        }
        return isAdmin;
    }

    public static async getOrganizationNameForCurrentUser(): Promise<string> {
        let name: string = "";
        const groups: string[] = await this.getCurrentUsersGroups();
        if (groups && groups.length > 0) {
            groups.forEach((group: string) => {
                if (!this.isAdminGroup(group)) {
                    name = group;
                }
            });
        }
        return name;
    }

    public static async getCanSeeValuesForCurrentUser(): Promise<string[]> {
        if (process.env.REACT_APP_DATA_ACCESS_AUTH_ENABLED !== "true") {
            return ["*"];
        }

        const canSees: string[] = await this.getCurrentUsersGroups();
        canSees.push((await AuthWrapper.getCurrentAuthenticatedUser(false)).getUsername());
        return canSees;
    }

    public static async setUserAdminStatus(user: string, value: boolean): Promise<void> {
        const orgName: string = await this.getOrganizationNameForCurrentUser();
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.USERS, clientType.TYPE_COGNITO);
        const resp: any = await appSyncClient.mutate({
            mutation: gql(setUserAdminStatus),
            variables: {
                organizationName: orgName,
                username: user,
                userPoolId: awsconfig.Auth.userPoolId,
                isAdmin: value,
            },
        });
        console.log("Change admin: " + JSON.stringify(resp));
    }

    public static async updateOrganizationDetails(newDisplayName: string): Promise<void> {
        const orgName: string = await this.getOrganizationNameForCurrentUser();
        if (!newDisplayName || newDisplayName.length === 0 || newDisplayName === orgName) {
            return;
        }
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.USERS, clientType.TYPE_COGNITO);
        await appSyncClient.mutate({
            mutation: gql(updateOrganizationDetails),
            variables: {
                organizationName: orgName,
                userPoolId: awsconfig.Auth.userPoolId,
                displayName: newDisplayName,
            },
        });
    }

    public static async createOrganizationUser(name: string, isAdmin: boolean, resendInvitation: boolean)
        : Promise<AWSUser> {
        const orgName = await this.getOrganizationNameForCurrentUser();
        const userPayload: CreateUserPayload = {
            username: name,
            organizationName: orgName,
            userPoolId: this.getCurrentUserPoolId(),
            admin: isAdmin,
            resend: resendInvitation,
        };
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.USERS, clientType.TYPE_COGNITO);
        const response: any = await appSyncClient.mutate({
            mutation: gql(createOrganizationUser),
            variables: {
                payload: userPayload,
            },
        });
        return new AWSUser(
            response.data.createOrganizationUser.username,
            response.data.createOrganizationUser.admin);
    }

    public static async deleteUser(name: string): Promise<boolean> {
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.USERS, clientType.TYPE_COGNITO);
            await appSyncClient.mutate({
                mutation: gql(deleteUser),
                variables: {
                    username: name,
                    userPoolId: this.getCurrentUserPoolId(),
                },
            });
        } catch (error) {
            console.error("Failed to delete user: " + error + ", " + JSON.stringify(error));
            return false;
        }
        return true;
    }

    private static async getCurrentUsersGroups(): Promise<string[]> {
        const groups: string[] = (await AuthWrapper.getCurrentAuthenticatedUser(false))
            .getSignInUserSession()
            .getIdToken()
            .decodePayload()["cognito:groups"];
        return groups ? groups : [];
    }

    private static isAdminGroup(groupName: string): boolean {
        return groupName.endsWith(process.env.REACT_APP_ADMIN_GROUP_SUFFIX);
    }

    private static getCurrentUserPoolId(): string {
        return awsconfig.Auth.userPoolId;
    }
}
