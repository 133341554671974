/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

const graphql = require("./../../clients/" + process.env.REACT_APP_CLIENT_FOLDER + "/graphql.yml");

export const dataUpdates = graphql.subscriptions.dataUpdates;
export const shadowUpdates = graphql.subscriptions.shadowUpdates;
export const eventUpdates = graphql.subscriptions.eventUpdates;

// OTA
export const otaUpdateState = graphql.subscriptions.otaUpdateState;
