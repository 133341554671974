/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Dialog, List, ListItemText, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";
import IGroup from "../../data/group/IGroup";
import ErrorNote from "../global/error-note";
import Loader from "../global/loader";

interface Props {
    title: string;
    label: string;
    parentGroup: IGroup;
    closePopup: () => void;
    triggerGroupsUpdate: () => void;
}

interface State {
    name: string;
    loading: boolean;
    errorMsg: string;
}

export default class AddNewPopupBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            name: "",
            loading: false,
            errorMsg: null,
        };
    }

    protected handleSubmit = (): void => {
        console.log("Popup base - empty handleSubmit implementation");
    }

    private nameChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ name: event.currentTarget.value });
    }

    private handleCancel = (): void => {
        this.props.closePopup();
    }

    private handleCloseErrorNote = (): void => {
        this.setState({ errorMsg: null });
    }

    private renderPopupBody(): JSX.Element {
        if (this.state.loading) {
            return (
                <Loader />
            );
        } else if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleCloseErrorNote}
                />
            );
        } else {
            return (
                <div className="popup-body">
                    <List style={{ margin: "2rem" }}>
                        <ListItemText id="list-label-new-popup-name" primary={this.props.label} />
                        <TextField
                            value={this.state.name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                this.nameChanged(event)}
                        />
                        <div className="buttons-container">
                            <Button
                                variant="contained"
                                color="primary"
                                className="button left"
                                onClick={this.handleSubmit}
                            >
                                Apply
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className="button"
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </List>
                </div>
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Dialog
                onClose={this.props.closePopup}
                open={true}
            >
                <div className="popup-inner">
                    <Typography variant="h6">{this.props.title}</Typography>
                    {this.renderPopupBody()}
                </div>
            </Dialog>
        );
    }
}
