/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, TextField } from "@material-ui/core";
import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../global/loader";

export interface Props {
    loader: boolean;
    newPassword: string;
    confirmPassword: string;
    userInput: (inputId: string, value: string) => void;
    completePassword: any;
}

export default class CompletePasswordInputs extends Component<Props> {

    private renderLoader = (): JSX.Element => {
        if (this.props.loader) {
            return <Loader />;
        }
    }

    private renderInputs(): JSX.Element {
        const isEnabled = this.props.newPassword.length > 0 && this.props.confirmPassword.length > 0;
        return (
            <Fragment>
                <div className="login-fields">
                    <TextField
                        className="text-field"
                        label="New password"
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                        defaultValue={this.props.newPassword}
                        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
                            void => this.props.userInput("newPassword", event.target.value)}
                        fullWidth={true}
                    />
                    <TextField
                        className="text-field"
                        label="Confirm new password"
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                        defaultValue={this.props.confirmPassword}
                        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
                            void => this.props.userInput("confirmPassword", event.target.value)}
                        fullWidth={true}
                    />
                </div>
                <div className="login-buttons">
                    <Button
                        disabled={!isEnabled}
                        variant="contained"
                        color="primary"
                        onClick={(): void => this.props.completePassword()}
                        className="button"
                    >
                        Log In
                    </Button>
                </div>
            </Fragment>
        );
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                <div className="login-header">
                    New password required
                </div>
                {this.renderLoader()}
                {this.renderInputs()}
            </Fragment >
        );
    }
}
