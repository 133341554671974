/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { RouteComponentPropsParams } from "../@types/routerprops";
import AuthWrapper from "../data/auth/authWrapper";
import { OrganizationUtils } from "../data/organization/OrganizationUtils";
import CLOSE_ICON from "./../assets/baseline-close-black-24px.svg";
import MENU_ICON from "./../assets/baseline-menu-black-24px.svg";
const HEADER_LOGO = require("./../assets/" + process.env.REACT_APP_HEADER_LOGO_ASSET);

interface State {
    menuOpen?: boolean;
    adminUser: boolean;
}

interface Props extends RouteComponentProps<RouteComponentPropsParams> {
}

class Header extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            menuOpen: false,
            adminUser: false,
        };
        this.checkAdminStatus().catch((error: Error) => console.log(error));
    }

    private logOut = async (): Promise<void> => {
        await AuthWrapper.logOut();
    }

    private toggleMenu = (): void => {
        this.setState((prevState: State) => ({ menuOpen: !prevState.menuOpen }));
    }

    private getTrailingRoute = (): string => {
        if (this.props.match.params.id) {
            return "/" + this.props.match.params.id;
        }
        return "";
    }

    private checkAdminStatus = async (): Promise<void> => {
        if (await OrganizationUtils.isCurrentUserAdmin()) {
            this.setState({ adminUser: true });
        }
    }

    private renderAdminMenuItem = (onClickEnabled?: boolean): JSX.Element => {
        // Show admin menu item only if user is organization admin
        if (this.state.adminUser) {
            return (
                <li className="menu-item">
                    <NavLink
                        to={"/admin"}
                        onClick={onClickEnabled ? this.toggleMenu : null}
                        activeClassName="active"
                        className="menu-item-link"
                    >
                        Admin
                    </NavLink>
                </li>
            );
        }
    }

    private listMenuItems(onClickEnabled?: boolean): JSX.Element {
        return (
            <ul className="menu-list">
                <li className="menu-item">
                    <NavLink
                        to={"/status" + this.getTrailingRoute()}
                        onClick={onClickEnabled ? this.toggleMenu : null}
                        activeClassName="active"
                        className="menu-item-link"
                    >
                        Status
                    </NavLink>
                </li>
                <li className="menu-item">
                    <NavLink
                        to={"/history" + this.getTrailingRoute()}
                        onClick={onClickEnabled ? this.toggleMenu : null}
                        activeClassName="active"
                        className="menu-item-link"
                    >
                        History
                    </NavLink>
                </li>
                <li className="menu-item">
                    <NavLink
                        to={"/statistics" + this.getTrailingRoute()}
                        onClick={onClickEnabled ? this.toggleMenu : null}
                        activeClassName="active"
                        className="menu-item-link"
                    >
                        Analytics
                    </NavLink>
                </li>
                <li className="menu-item">
                    <NavLink
                        to={"/events" + this.getTrailingRoute()}
                        onClick={onClickEnabled ? this.toggleMenu : null}
                        activeClassName="active"
                        className="menu-item-link"
                    >
                        Events
                    </NavLink>
                </li>
                {this.renderAdminMenuItem(onClickEnabled)}
                <li className="menu-item" onClick={this.logOut}>
                    <span className="menu-item-link">Log out</span>
                </li>
            </ul >
        );
    }

    public render(): JSX.Element {
        return (
            <header className="header-container col-sm-11">
                <div className="mobile-menu-container col-sm-1 col-xsm-2">
                    <img
                        className="menu-button"
                        alt=""
                        src={this.state.menuOpen ? CLOSE_ICON : MENU_ICON}
                        onClick={this.toggleMenu}
                    />
                    <div
                        className={this.state.menuOpen ? "menu-content" : "menu-content hidden"}
                    >
                        {this.state.menuOpen &&
                            (
                                this.listMenuItems(true)
                            )
                        }
                    </div>
                </div>
                <div className="logo-container col-md-4 col-sm-5 col-xsm-10">
                    <a href="https://sadeinnovations.com/home" target="_blank" rel="noopener noreferrer">
                        <img className="header-logo" src={HEADER_LOGO} alt="header-logo" />
                    </a>
                </div>
                <div className="menu-container col-md-8 col-sm-7">
                    <div className="menu">
                        {this.listMenuItems()}
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
