/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ClientProperties from "../../data/clientSpecific/ClientProperties";
import Utils from "../../data/utils/utils";
import SettingsWrapper from "../settings/settings-wrapper";
import LocationIcon from "../status/location-icon";
import { BrowserItemBase, TOOLTIP_DELAY_MS } from "./browser-item-base";

export interface Props {
    adminMode: boolean;
}

class IoTListItem extends BrowserItemBase {

    public render(): JSX.Element {
        return (
            <li className="iot-item">
                <ReactTooltip delayShow={TOOLTIP_DELAY_MS} />
                <NavLink
                    to={this.getLinkRoute()}
                    className="iot-item-link col-sm-8 col-xsm-8"
                    activeClassName="active"
                >
                    <div
                        className="iot-icon col-sm-2 col-xsm-2"
                        data-tip={ClientProperties.getAlarmTooltipFromEntities(this.state.alarmTypes)}
                    >
                        {this.getDeviceIcon()}
                    </div>
                    <div className="iot-name col-sm-10 col-xsm-10" data-tip={this.getTooltip()}>
                        {Utils.getDisplayName(this.props.device)}
                    </div>
                </NavLink >
                <div className="iot-status-container col-sm-4 col-xsm-4">
                    <SettingsWrapper
                        device={this.props.device}
                        isIcon={true}
                    />
                    {this.getSignalStatusElement()}
                    <LocationIcon
                        device={this.props.device}
                    />
                    {this.getPowerSupplyStatusElement()}
                </div>
            </li >
        );
    }
}

export default withRouter(IoTListItem);
