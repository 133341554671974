/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import {OrganizationObserver} from "../observer/OrganizationObserver";
import AWSUser from "./AWSUser";
import { Organization } from "./Organization";
import { OrganizationUtils } from "./OrganizationUtils";

export default class AWSOrganization extends Organization {

    private readonly orgName: string;
    private displayName: string;
    private users: AWSUser[];

    constructor(orgName: string, displayName: string, users: AWSUser[]) {
        super();
        this.orgName = orgName;
        this.displayName = displayName;
        this.users = users ? users : [];
    }

    public getDisplayName(): string {
        return this.displayName;
    }

    public getUsers(): AWSUser[] {
        if (this.users.length === 0) {
            this.fetchUsers();
        }
        return this.users;
    }

    public async submitNameChange(newName: string): Promise<void> {
        await OrganizationUtils.updateOrganizationDetails(newName);
        this.displayName = newName;
        this.notifyNameChange();
    }

    public async createUser(username: string, isAdmin: boolean): Promise<void> {
        try {
            const newUser: AWSUser = await OrganizationUtils.createOrganizationUser(username, isAdmin, false);
            const currentUsersMap: Map<string, AWSUser> = new Map<string, AWSUser>();
            this.users.forEach((user: AWSUser) => {
                currentUsersMap.set(user.getUserName(), user);
            });
            currentUsersMap.set(newUser.getUserName(), newUser);
            this.users = Array.from(currentUsersMap.values());
            this.sortUsers();
            this.notifyUsersChange();
        } catch (error) {
            console.error("Failed to create new user: " + error);
            return Promise.reject(error);
        }
        return Promise.resolve();
    }

    public async deleteUser(user: AWSUser): Promise<void> {
        await OrganizationUtils.deleteUser(user.getUserName());
        this.users.forEach((listUser: AWSUser, index: number) => {
            if (user.getUserName() === listUser.getUserName()) {
                console.log("Remove from local list: " + user.getUserName());
                this.users.splice(index, 1);
            }
        });
        this.notifyUsersChange();
    }

    private async fetchUsers(): Promise<void> {
        console.log("Fetch users");
        if (!this.users) {
            this.users = [];
        }
        const currentUsersMap: Map<string, AWSUser> = new Map<string, AWSUser>();
        this.users.forEach((user: AWSUser) => {
            currentUsersMap.set(user.getUserName(), user);
        });

        const newUsers: AWSUser[] = await OrganizationUtils.getOrganizationUsers(this.orgName, null);
        newUsers.forEach((user: AWSUser) => {
            currentUsersMap.set(user.getUserName(), user);
        });
        this.users = Array.from(currentUsersMap.values());
        this.sortUsers();
        this.notifyUsersChange();
    }

    private sortUsers(): void {
        this.users.sort((a: AWSUser, b: AWSUser) => a.getUserName().localeCompare(b.getUserName()));
    }

    private notifyNameChange(): void {
        const observers = this.observers as OrganizationObserver[];
        if (observers) {
            observers.forEach((observer: OrganizationObserver) => {
                observer.onOrganizationNameChange(this.getDisplayName());
            });
        }
    }

    private notifyUsersChange(): void {
        const observers = this.observers as OrganizationObserver[];
        if (observers) {
            observers.forEach((observer: OrganizationObserver) => {
                observer.onUsersChange(this.getUsers());
            });
        }
    }
}
