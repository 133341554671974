/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Device from "../device/Device";
import IGroup from "../group/IGroup";
import Utils from "../utils/utils";
import { Tree } from "./tree";
import { IoTTreeItem } from "./tree-item";

export class TreeBuilder {

    public static async createTree(rootLevelGroups: IGroup[]): Promise<Tree> {
        console.log("Creating tree");
        const startTime = Date.now();
        const tree = await this.buildRootLevelTree(rootLevelGroups);
        console.log(`Tree done in ${Date.now() - startTime} ms`);
        return tree;
    }

    private static async buildRootLevelTree(rootLevelGroups: IGroup[]): Promise<Tree> {
        const iotTreeItems: IoTTreeItem[] = [];
        const leafItems: IoTTreeItem[] = [];
        const devices: Device[] = [];
        if (rootLevelGroups && rootLevelGroups.length > 0) {
            let drawNodeLevel: boolean = true;
            if (rootLevelGroups.length === 1) {
                drawNodeLevel = false;
            }

            for (const group of rootLevelGroups) {
                const groupFolder: IoTTreeItem = {
                    id: group.groupId,
                    isGroup: true,
                    item: group,
                    parent: null,
                    childNodesAndFolders: [],
                    drawLevel: drawNodeLevel,
                    isFilterMatch: false,
                    hasAlarms: false,
                };

                await this.buildTreeFolder(groupFolder, leafItems);
                iotTreeItems.push(groupFolder);
            }
        }

        leafItems.forEach((leaf: IoTTreeItem) => {
            devices.push(leaf.item as Device);
        });

        return {
            treeItems: iotTreeItems,
            leafs: leafItems,
            deviceList: devices,
        };
    }

    private static async buildTreeFolder(groupFolder: IoTTreeItem, leafItems: IoTTreeItem[]): Promise<void> {
        const group: IGroup = groupFolder.item as IGroup;
        let childGroups: IGroup[] = await group.getGroups();
        childGroups = childGroups.sort((a: IGroup, b: IGroup) => {
            return Utils.compareGroupsForSorting(a, b);
        });

        for (const childGroup of childGroups) {
            const childNode: IoTTreeItem = {
                id: childGroup.groupId,
                isGroup: true,
                item: childGroup,
                parent: groupFolder,
                childNodesAndFolders: [],
                drawLevel: true,
                isFilterMatch: false,
                hasAlarms: false,
            };

            groupFolder.childNodesAndFolders.push(childNode);
            await this.buildTreeFolder(childNode, leafItems);
        }

        let devices: Device[] = await group.getDevices();
        devices = devices.sort((a: Device, b: Device) => {
            return Utils.compareDevicesForSorting(a, b);
        });

        devices.forEach((iotDevice: Device) => {
            const leafItem: IoTTreeItem = {
                id: iotDevice.getId(),
                isGroup: false,
                item: iotDevice,
                parent: groupFolder,
                childNodesAndFolders: null,
                drawLevel: true,
                isFilterMatch: false,
                hasAlarms: false,
            };
            groupFolder.childNodesAndFolders.push(leafItem);
            leafItems.push(leafItem);
        });
    }
}
