/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

// import gql from "graphql-tag";
// import AppSyncClientFactory from "../backend/AppSyncClientFactory";
// import {clientType} from "../backend/AppSyncClientProvider";
import SessionSet from "./SessionSet";

export default class SessionSubscriptionManager {

    private subscribedData: SessionSet[] = [];
    private subscription: ZenObservable.Subscription = null;
    private static instance: SessionSubscriptionManager = null;

    public static getInstance(): SessionSubscriptionManager {
        if (SessionSubscriptionManager.instance == null) {
            SessionSubscriptionManager.instance = new SessionSubscriptionManager();
        }
        return this.instance;
    }

    public subscribeData(dataSet: SessionSet): void {
        if (this.subscribedData && this.subscribedData.indexOf(dataSet) === -1) {
            this.subscribedData.push(dataSet);
            if (!this.subscription) {
                this.subscribe();
            }
        }
    }
    public removeSubscription(dataSet: SessionSet): void {
        const observerIndex = this.subscribedData.indexOf(dataSet);
        if (observerIndex !== -1) {
            if (this.subscribedData.length === 1) {
                this.subscription.unsubscribe();
                this.subscription = null;
            }
            this.subscribedData.splice(observerIndex, 1);
        }
    }

    private findDataSet(deviceId: string): SessionSet {
        let match: SessionSet = null;
        this.subscribedData.forEach((sessionSet: SessionSet) => {
            if (deviceId === sessionSet.getId()) {
                match = sessionSet;
            }
        });
        return match;
    }

    private subscribe(): void {
        throw new Error("Subscription not implemented");
        // const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(clientType.TYPE_COGNITO);
        // this.subscription = appSyncClient.subscribe({
        //     query: gql(sessionUpdates),
        // }).subscribe({
        //     error: (error: any): void => {
        //         if (error.errorMessage === "AMQJS0008I Socket closed.") {
        //             console.log("Reconnecting socket");
        //             this.subscribe();
        //         }
        //         console.error(error);
        //     },
        //     next: (newData: any): void => {
        //         const dataSet = this.findDataSet(newData.data.onSessionUpdates.deviceId);
        //         dataSet.setData(newData.data.onSessionUpdates);
        //     },
        // });
    }
}
