/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Drawer } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouteComponentPropsParams } from "../../@types/routerprops";
import ClientProperties, { CanType } from "../../data/clientSpecific/ClientProperties";
import { IData } from "../../data/clientSpecific/IData";
import { ISession } from "../../data/clientSpecific/ISession";
import DataSet from "../../data/data/DataSet";
import { SessionSet } from "../../data/data/SessionSet";
import { Device } from "../../data/device/Device";
import DeviceSelectorObserver from "../../data/deviceSelector/DeviceSelectorObserver";
import { DataObserver } from "../../data/observer/DataObserver";
import { SessionObserver } from "../../data/observer/SessionObserver";
import Utils from "../../data/utils/utils";
import IoTHistoryTools from "../controls/history-tools";
import LiveControl from "../controls/live-control";
import SensorSelect from "../controls/sensor-select";
import SessionPicker from "../controls/session-picker";
import TimeRangePicker from "../controls/time-range-picker";
import Loader from "../global/loader";
import ArrowDouble from "./../../assets/arrow-double-24px.svg";
import DeviceSelector from "./../../data/deviceSelector/DeviceSelector";
import IoTChart from "./history-chart";
import IoTMap from "./history-map";

interface Props extends RouteComponentProps<RouteComponentPropsParams> { }

interface State {
    isLoading: boolean;
    startTimestamp: number;
    endTimestamp: number;
    sessionSet: SessionSet;
    selectedSensor: string[];
    selectedSensor2: string[];
    dataSet: DataSet;
    selectedLocation: number[];
    liveData: boolean;
    selectedDevice: Device;
    drawerOpen: boolean;
    timestampChanged: boolean;
    sensorType: CanType;
}

const DELTA_TIME_MS: number = 2 * 60 * 60 * 1000;
const DEFAULT_START_TIMESTAMP: number = new Date().getTime() - DELTA_TIME_MS;
const DEFAULT_END_TIMESTAMP: number = new Date().getTime();

class IoTHistoryWrapper extends Component<Props, State> implements DataObserver,
    SessionObserver, DeviceSelectorObserver {

    private deviceSelector: DeviceSelector = DeviceSelector.getInstance();

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            startTimestamp: DEFAULT_START_TIMESTAMP,
            endTimestamp: DEFAULT_END_TIMESTAMP,
            sessionSet: null,
            dataSet: null,
            selectedSensor: null,
            selectedSensor2: null,
            selectedLocation: [],
            liveData: false,
            selectedDevice: null,
            drawerOpen: false,
            timestampChanged: false,
            sensorType: CanType.CAN_1,
        };
    }

    public componentDidMount(): void {
        this.deviceSelector.addObserver(this);

        if (this.props.match.params.id) {
            this.setState({ isLoading: true });
        }
    }

    public componentWillUnmount(): void {
        if (this.state.dataSet) {
            this.state.dataSet.removeObserver(this);
        }
        if (this.state.sessionSet) {
            this.state.sessionSet.removeObserver(this);
        }
        this.deviceSelector.removeObserver(this);
    }

    public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
        const deviceChanged = this.state.selectedDevice != null &&
            (prevState.selectedDevice == null || prevState.selectedDevice.getId() !==
                this.state.selectedDevice.getId());
        if (deviceChanged) {
            console.log("componentDidUpdate: Device changed to " + this.state.selectedDevice.getId());
            this.setState({
                dataSet: null,
                sessionSet: null,
                drawerOpen: false,
                selectedLocation: null,
                startTimestamp: new Date().getTime() - DELTA_TIME_MS,
                endTimestamp: new Date().getTime(),
            });
        }

        if (this.state.startTimestamp !== prevState.startTimestamp
            || this.state.endTimestamp !== prevState.endTimestamp) {
            console.log("componentDidUpdate: Fetching data");
            await this.fetchData();
            await this.fetchSessions();
        }

        if (this.state.startTimestamp === prevState.startTimestamp
            && this.state.endTimestamp === prevState.endTimestamp && this.state.timestampChanged) {
            this.setState({ timestampChanged: false });
        }

        if (this.state.dataSet && this.state.dataSet !== prevState.dataSet) {
            console.log("componentDidUpdate: Data changed");
            this.setLatestLocation(this.getLatestLocation());
        }

        if (!this.state.liveData && prevState.liveData) {
            console.log("componentDidUpdate: LiveData disabled");
            this.state.dataSet.removeObserver(this);
            this.state.sessionSet.removeObserver(this);
        }

        if (!prevState.liveData && this.state.liveData) {
            console.log("componentDidUpdate: LiveData enabled");
            this.setState({
                endTimestamp: new Date().getTime(),
            });
        }
    }

    public onSelectedDeviceChanged(device: Device): void {
        this.setState({ selectedDevice: device });
    }

    public onDeviceSetChanged(devices: Device[]): void {
        console.log("onDeviceSetChanged");
    }

    public onDataUpdate(dataSet: DataSet): void {
        if (dataSet.getId() === this.state.selectedDevice.getId()) {
            const newDataSet = Object.create(dataSet);
            newDataSet.data = dataSet.getData().slice();
            this.setState({
                dataSet: newDataSet,
            });
        }
    }

    public onSessionUpdate(sessionSet: SessionSet): void {
        if (sessionSet.getId() === this.state.selectedDevice.getId()) {
            // const newSessionSet = Object.create(sessionSet);
            // newSessionSet.data = sessionSet.getSessions().slice();
            this.setState({ sessionSet });
        }
    }

    private async fetchData(): Promise<void> {
        if (this.state.selectedDevice && this.state.startTimestamp != null && this.state.endTimestamp != null) {
            this.setState({ isLoading: true });
            const dataSet: DataSet = await this.state.selectedDevice.getData(this.state.startTimestamp,
                this.state.endTimestamp);
            if (this.state.liveData) {
                dataSet.addObserver(this);
            } else {
                console.log("Live off, remove observer");
                dataSet.removeObserver(this);
            }
            this.setState({
                isLoading: false,
                dataSet,
                timestampChanged: false,
            });
        }
    }

    private async fetchSessions(): Promise<void> {
        if (this.state.selectedDevice) {
            const sessionSet: SessionSet = await this.state.selectedDevice.getSessions(0, Date.now());
            this.setState({ sessionSet });
        }
    }

    private selectPoint = (timestamp: number): void => {
        this.state.dataSet.getData().forEach((item: IData) => {
            if (Number(item.timestamp) === timestamp) {
                if (item.latitude && item.longitude) {
                    const location = [item.longitude, item.latitude];
                    this.setState({ selectedLocation: location });
                    if (this.state.liveData) {
                        this.toggleLiveData();
                    }
                    if (!this.state.drawerOpen) {
                        this.toggleDrawer();
                    }
                } else {
                    if (this.state.selectedLocation) {
                        this.setState({ selectedLocation: null });
                    }
                }
            }
        });
    }

    private isLocationInData(): boolean {
        if (this.state.dataSet) {
            for (const entry of this.state.dataSet.getData()) {
                if (entry.latitude && entry.longitude) {
                    return true;
                }
            }
        }
    }

    private getLatestLocation(): number[] {
        let latestTimestamp: number = 0;
        let latestLocation: number[] = [];
        this.state.dataSet.getData().slice().forEach((data: IData) => {
            const timestamp = Number(data.timestamp);
            if (timestamp > latestTimestamp && data.longitude && data.latitude) {
                latestTimestamp = timestamp;
                latestLocation = [data.longitude, data.latitude];
            }
        });
        return latestLocation;
    }

    private setLatestLocation(latestLocation: number[]): void {
        const [longitude, latitude] = latestLocation;
        if (longitude && latitude) {
            const selectedLocation = [longitude, latitude];
            this.setState({ selectedLocation });
        }
    }

    private setTimeRange = (startTimestamp: number | null, endTimestamp: number | null): void => {
        this.setState({
            startTimestamp,
            endTimestamp,
            timestampChanged: true,
        });
    }

    private toggleLiveData = (): void => {
        this.setState((prevState: State) => ({
            liveData: !prevState.liveData,
            timestampChanged: true,
        }));
    }

    private selectSensor = (sensors: string[], sensors2: string[]): void => {
        this.setState({
            selectedSensor: sensors,
            selectedSensor2: sensors2,
        });
    }

    private selectSession = (session: ISession): void => {
        if (session !== null) {
            this.setState({
                startTimestamp: Number(session.sessionId),
                endTimestamp: Number(session.sessionId) + Number(session.durationMs),
            });
        }
    }

    private toggleDrawer = (): void => {
        this.setState((prevState: State) => ({
            drawerOpen: !prevState.drawerOpen,
        }));
    }

    private renderErrorMessage(): JSX.Element {
        if (!this.state.isLoading) {
            return (
                <div className="error-container">
                    {this.state.selectedDevice ?
                        <Fragment>
                            <p><span>Device {Utils.getDisplayName(this.state.selectedDevice)} selected.</span></p>
                            <p><span>No statistics data found!</span></p>
                        </Fragment> :
                        <p><span>Please select device first and then select start and end time.</span></p>}
                </div >
            );
        }
    }

    private setSensorType = (sensorType: CanType): void => {
        this.setState({
            sensorType,
        });
    }

    private getData(): IData[] {
        return ClientProperties.filterSensorData(this.state.sensorType, this.state.dataSet.getData());
    }

    private renderToolbar = (): JSX.Element => {
        if (this.state.selectedDevice) {
            return (
                <Fragment>
                    <SensorSelect
                        label="CAN GW"
                        currentSensor={this.state.sensorType}
                        sensorTypes={[CanType.CAN_1, CanType.CAN_2]}
                        onSensorSelected={this.setSensorType}
                    />
                    {
                        this.state.sessionSet && this.state.sessionSet.getSessions()
                        && this.state.sessionSet.getSessions().length > 0 &&
                        <SessionPicker
                            sessions={this.state.sessionSet.getSessions()}
                            onSelectSession={this.selectSession}
                            disabled={this.state.liveData}
                            reset={this.state.timestampChanged}
                        />
                    }
                    <TimeRangePicker
                        startTimestamp={this.state.startTimestamp}
                        endTimestamp={this.state.endTimestamp}
                        onTimeRangeSelect={this.setTimeRange}
                        disabled={this.state.liveData}
                    />
                    <LiveControl
                        liveData={this.state.liveData}
                        onLiveDataToggled={this.toggleLiveData}
                    />
                </Fragment>
            );
        }
    }

    private renderLoader = (): JSX.Element => {
        if (this.state.isLoading) {
            return <Loader />;
        }
    }

    private renderSidebar = (): JSX.Element => {
        if (this.state.selectedDevice && this.isLocationInData()) {
            return (
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={this.state.drawerOpen}
                    transitionDuration={500}
                    classes={{ paper: "col-sm-4 col-xsm-9 iot-content-drawer" }}
                >
                    <div className="history-map-container">
                        <IoTMap
                            selectedLocation={this.state.selectedLocation}
                            mapsData={this.state.dataSet.getData()}
                        />
                    </div>
                    <div className="drawer-button-container">
                        <Button className="drawer-button close" onClick={this.toggleDrawer}>
                            <img src={ArrowDouble} alt="forward arrow" />
                        </Button>
                    </div>
                </Drawer>
            );
        }
    }

    private renderSidebarButton = (): JSX.Element => {
        if (this.state.dataSet && this.state.dataSet.getData() && this.state.dataSet.getData().length > 0
            && !this.state.drawerOpen && this.isLocationInData()) {
            return (
                <div className="drawer-button-container">
                    <Button onClick={this.toggleDrawer} className="drawer-open-button">
                        <img src={ArrowDouble} alt="forward arrow" />
                    </Button>
                </div>
            );
        }
    }

    private renderChart = (): JSX.Element => {
        if (this.state.dataSet && this.state.dataSet.getData() && this.state.dataSet.getData().length > 1) {
            return (
                <div className="chart-container">
                    <IoTChart
                        onPointSelect={this.selectPoint}
                        selectedSensor={this.state.selectedSensor}
                        selectedSensor2={this.state.selectedSensor2}
                        data={this.getData()}
                    />
                </div>
            );
        }
    }

    private renderHistoryTools = (): JSX.Element => {
        if (this.state.dataSet && this.state.dataSet.getData() && this.state.dataSet.getData().length > 1) {
            return (
                <IoTHistoryTools
                    onSensorSelect={this.selectSensor}
                    data={this.getData()}
                    selectedDevice={this.state.selectedDevice}
                />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                <div className="iot-tool-container col-sm-9 col-xsm-12">
                    {this.renderToolbar()}
                </div>
                {this.renderSidebar()}
                <div
                    className="iot-content-container col-sm-12 col-xsm-12"
                    style={this.state.drawerOpen ? { marginLeft: "33%", width: "66%" } : null}
                >
                    {this.renderSidebarButton()}
                    {this.renderErrorMessage()}
                    {this.renderLoader()}
                    {this.renderChart()}
                    {this.renderHistoryTools()}
                </div>
            </Fragment >
        );
    }
}

export default withRouter(IoTHistoryWrapper);
