/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Auth from "./containers/auth";
import Unauth from "./containers/unauth";

interface State {
}

interface Props { }

class App extends Component<Props, State> {

    public render(): JSX.Element {
        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        path="/login"
                        component={Unauth}
                    />
                    <Route
                        path="/"
                        component={Auth}
                    />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
