/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Drawer } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TableContent } from "../../@types/tableprops";
import { ClientProperties, StatusDataRow, StatusEntry } from "../../data/clientSpecific/ClientProperties";
import { IData } from "../../data/clientSpecific/IData";
import { LatestData } from "../../data/data/LatestData";
import { Device } from "../../data/device/Device";
import DeviceSelector from "../../data/deviceSelector/DeviceSelector";
import DeviceSelectorObserver from "../../data/deviceSelector/DeviceSelectorObserver";
import { LatestDataObserver } from "../../data/observer/LatestDataObserver";
import Utils, { DrawerState } from "../../data/utils/utils";
import TimeFilter from "../controls/time-filter";
import SettingsWrapper from "../settings/settings-wrapper";
import ArrowDouble from "./../../assets/arrow-double-24px.svg";
import TableWrapper from "./../global/table-wrapper";
import LocationIcon from "./location-icon";
import StatusMap from "./status-map";

const DEVICE_ID_INDEX = 0;

interface Props extends RouteComponentProps {
    devices: Device[];
}

interface State {
    latestDataList: LatestData[];
    filteredDataList: IData[];
    filterTimestamp: number;
    selectedDevice: Device;
    drawerState: DrawerState;
}

class StatusWrapper extends Component<Props, State> implements LatestDataObserver, DeviceSelectorObserver {

    private deviceSelector: DeviceSelector = DeviceSelector.getInstance();

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedDevice: null,
            filteredDataList: null,
            latestDataList: null,
            filterTimestamp: null,
            drawerState: DrawerState.Open,
        };
    }

    public async componentDidMount(): Promise<void> {
        this.deviceSelector.addObserver(this);
        await this.fetchStatusData();
    }

    public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
        if (this.props.devices && prevProps.devices == null) {
            await this.fetchStatusData();
        }

        if (this.state.latestDataList !== null && (prevState.latestDataList !== this.state.latestDataList ||
            prevState.filterTimestamp !== this.state.filterTimestamp)) {
            this.filterData();
        }
    }

    public componentWillUnmount(): void {
        if (this.state.latestDataList) {
            this.state.latestDataList.forEach((latestData: LatestData) => {
                latestData.removeObserver(this);
            });
        }
        this.deviceSelector.removeObserver(this);
    }

    public onSelectedDeviceChanged(device: Device): void {
        this.setState({ selectedDevice: device });
    }

    public onDeviceSetChanged(devices: Device[]): void {
        console.log("onDeviceSetChanged");
    }

    public onDataUpdate(latestData: LatestData): void {
        const latestDataList = this.state.latestDataList.slice();
        latestDataList.forEach((data: LatestData, index: number) => {
            if (data && data.getId() === latestData.getId()) {
                latestDataList[index] = latestData;
            }
        });
        this.setState({ latestDataList });
    }

    private async fetchStatusData(): Promise<void> {
        if (this.props.devices) {
            const latestDataList: LatestData[] = await Promise.all(this.props.devices.map(async (device: Device) => {
                return await device.getLatestData();
            }));
            this.setState({ latestDataList });
        }
    }

    private onSet = (timestamp: number): void => {
        this.setState({
            filterTimestamp: timestamp,
        });
    }

    private filterData(): void {
        if (this.state && this.state.latestDataList !== null) {
            const latestDataList: LatestData[] = this.state.latestDataList.slice();
            const filteredDataList: IData[] = latestDataList.map((latestData: LatestData) => {
                if (latestData.getLatestData() != null
                    && Number(latestData.getLatestData().timestamp) >= this.state.filterTimestamp) {
                    return latestData.getLatestData();
                }
                return null;
            }).filter((latestData: IData) => latestData != null);
            this.setState({ filteredDataList });
        }
    }

    private getTableData(): TableContent {
        let tableHeader: string[] = [];
        const tableData: StatusDataRow[] = [];
        if (this.state.filteredDataList !== null) {
            const filteredDataList = this.state.filteredDataList.slice();
            filteredDataList.forEach((data: IData) => {
                if (data) {
                    const rowDevice: Device = this.props.devices.find((device: Device) =>
                        device.getId() === data.deviceId) || null;

                    if (rowDevice) {
                        let statusRow: StatusEntry[] = [{ title: "Device id", value: rowDevice.getId() },
                        { title: "Display name", value: Utils.getDisplayName(rowDevice) }];
                        const statusRowEntries: StatusEntry[] = ClientProperties.getStatusRowEntries(data);
                        statusRow = statusRow.concat(statusRowEntries);
                        statusRow.push({
                            title: "", value:
                                (
                                    <Fragment>
                                        <SettingsWrapper
                                            device={rowDevice}
                                            isIcon={true}
                                        />
                                        <LocationIcon
                                            device={rowDevice}
                                        />
                                    </Fragment>
                                ),
                        });
                        tableHeader = statusRow.map((entry: StatusEntry) => {
                            return entry.title;
                        });
                        const statusTableRowData = statusRow.map((entry: StatusEntry) => {
                            return entry.value;
                        });
                        tableData.push(statusTableRowData);
                    }
                }
            });
        }
        return {
            tableHeader,
            tableData,
        };
    }

    private isLocationInData(): boolean {
        let isLocation: boolean = false;
        if (this.state.filteredDataList) {
            const filteredDataList: IData[] = this.state.filteredDataList.slice();
            filteredDataList.forEach((data: IData) => {
                if (data) {
                    const dataKeys = Object.keys(data);
                    dataKeys.forEach((key: string) => {
                        if ((key === "latitude" && data[key] !== null) ||
                            (key === "longitude" && data[key] !== null)) {
                            isLocation = true;
                        }
                    });
                }
            });
        }
        return isLocation;
    }

    private handleTableRowSelect = (index: number, key?: string): void => {
        if (this.getTableData().tableData !== null) {
            const tableData = this.getTableData().tableData;
            let selectedRow: StatusDataRow = tableData[index];
            if (key) {
                const row = tableData.filter((td: StatusDataRow) => {
                    return (td[DEVICE_ID_INDEX] === key);
                });
                if (row && row.length > 0) {
                    selectedRow = row[0];
                }
            }
            const rowDeviceId: string = selectedRow[DEVICE_ID_INDEX].toString();
            this.props.history.push("/status/" + rowDeviceId);
            if (this.isLocationInData()) {
                this.setState({
                    drawerState: DrawerState.Open,
                });
            }
        }
    }

    private handleVisibleItemsChange = (newVisibleItems: string[]): void => {
        this.state.latestDataList.forEach((latestData: LatestData) => {
            if (newVisibleItems.find((value: string) => latestData.getId() === value)) {
                latestData.addObserver(this);
            } else {
                latestData.removeObserver(this);
            }
        });
    }

    private openDrawer = (): void => {
        this.setState({
            drawerState: DrawerState.Open,
        });
    }

    private openFullDrawer = (): void => {
        this.setState({
            drawerState: DrawerState.Full,
        });
    }

    private closeDrawer = (): void => {
        this.setState({
            drawerState: DrawerState.Closed,
        });
    }

    private renderButtons = (): JSX.Element => {
        if (this.state.drawerState === DrawerState.Open) {
            return (
                <div className="drawer-button-container">
                    <Button className="drawer-button close" onClick={this.closeDrawer}>
                        <img src={ArrowDouble} alt="forward arrow" />
                    </Button>
                    <Button onClick={this.openFullDrawer} className="drawer-button visible">
                        <img src={ArrowDouble} alt="forward arrow" />
                    </Button>
                </div>
            );
        }
        if (this.state.drawerState === DrawerState.Full) {
            return (
                <div className="drawer-button-container">
                    <Button className="drawer-button close" onClick={this.openDrawer}>
                        <img src={ArrowDouble} alt="forward arrow" />
                    </Button>
                </div >
            );
        }
    }

    private renderSidebar = (): JSX.Element => {
        if (this.isLocationInData()) {
            return (
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={this.state.drawerState !== DrawerState.Closed}
                    transitionDuration={500}
                    classes={{
                        paper: (this.state.drawerState === DrawerState.Full ? "col-xsm-12 col-sm-12 col-md-12 col-lg-12" :
                            "col-xsm-12 col-sm-12 col-md-12 col-lg-4") + " iot-content-drawer",
                    }}
                >
                    <div className="status-map-container">
                        <StatusMap
                            selectedDevice={this.state.selectedDevice}
                            mapData={this.state.filteredDataList}
                            drawerState={this.state.drawerState}
                        />
                    </div>
                    {this.renderButtons()}
                </Drawer>
            );
        }
    }

    private renderTable = (): JSX.Element => {
        if (this.state.drawerState !== DrawerState.Full) {
            return (
                <div className="table-wrapper">
                    <TableWrapper
                        title="Active devices"
                        rowsPerPageDefault={20}
                        header={this.getTableData().tableHeader}
                        data={this.getTableData().tableData}
                        stickyHeader={false}
                        onTableRowSelect={this.handleTableRowSelect}
                        onVisibleItemsChanged={this.handleVisibleItemsChange}
                    />
                </div>
            );
        }
    }

    private renderSidebarButton = (): JSX.Element => {
        if (this.state.latestDataList && this.state.drawerState !== DrawerState.Open && this.isLocationInData()) {
            return (
                <div className="drawer-button-container">
                    <Button onClick={this.openDrawer} className="drawer-open-button">
                        <img src={ArrowDouble} alt="forward arrow" />
                    </Button>
                </div>
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                <div className="iot-tool-container col-sm-8 col-xsm-7">
                    <TimeFilter
                        onSet={this.onSet}
                        filterList={[300000, 900000, 3600000, 21600000, 86400000, 604800000, 2592000000]}
                        labelList={["5 min", "15 min", "1 hour", "6 hours", "1 day", "1 week", "1 month"]}
                    />
                </div>
                {this.renderSidebar()}
                <div
                    className="iot-content-container col-xsm-12 col-sm-12 col-md-12 col-lg-12"
                    style={
                        (this.state.drawerState === DrawerState.Open && this.isLocationInData()) ?
                            { marginLeft: "33%", width: "66%" } : null ||
                                this.state.drawerState === DrawerState.Full ? { width: "0%" } : null
                    }
                >
                    {this.renderSidebarButton()}
                    {this.renderTable()}
                </div >
            </Fragment >
        );
    }
}

export default withRouter(StatusWrapper);
