/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

const graphql = require("./../../clients/" + process.env.REACT_APP_CLIENT_FOLDER + "/graphql.yml");

export const getDeviceData = graphql.queries.getDeviceData;
export const getDevices = graphql.queries.getDevices;
export const getDeviceTree = graphql.queries.getDeviceTree;
export const getDeviceShadow = graphql.queries.getDeviceShadow;
export const getSessions = graphql.queries.getSessions;
export const getLatestData = graphql.queries.getLatestData;
export const getGroups = graphql.queries.getGroups;
export const getQsEmbedUrl = graphql.queries.getQsEmbedUrl;
export const getOrganizationDetails = graphql.queries.getOrganizationDetails;
export const getOrganizationUsers = graphql.queries.getOrganizationUsers;
export const getEvents = graphql.queries.getEvents;
export const getDeviceEvents = graphql.queries.getDeviceEvents;
export const getEventMetadata = graphql.queries.getEventMetadata;

// OTA
export const getOtaUpdates = graphql.queries.getOtaUpdates;
export const getOtaUpdateStates = graphql.queries.getOtaUpdateStates;
