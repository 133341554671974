/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { Device } from "../../data/device/Device";
import SettingsIcon from "./../../assets/baseline-settings-20px.svg";
import SettingsPopup from "./../settings/settings-popup";

interface Props {
    device: Device;
    isIcon: boolean;
    onSettingsClosed?: () => void;
}

interface State {
    showSettings: boolean;
}

export default class SettingsWrapper extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showSettings: false,
        };
    }

    private toggleDeviceSettings = (): void => {
        this.setState((prevState: State) => ({ showSettings: !prevState.showSettings }));
        if (this.props.onSettingsClosed) {
            this.props.onSettingsClosed();
        }
    }

    private renderSettingsPopupIcon(): JSX.Element {
        if (this.props.isIcon) {
            return (
                <img
                    className="status-icon action"
                    alt="Settings"
                    src={SettingsIcon}
                    onClick={this.toggleDeviceSettings}
                />
            );
        } else {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    className="button left"
                    onClick={this.toggleDeviceSettings}
                >
                    Settings
                </Button>
            );
        }
    }

    private renderSettingsPopup(): JSX.Element {
        if (this.state.showSettings) {
            return (
                <SettingsPopup
                    selectedDevice={this.props.device}
                    closeSettings={this.toggleDeviceSettings}
                />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                {this.renderSettingsPopupIcon()}
                {this.renderSettingsPopup()}
            </Fragment>
        );
    }
}
