/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import moment from "moment";
import { Device } from "../device/Device";
import { StateOptions } from "../device/DeviceState";
import IGroup from "../group/IGroup";

const sensorMap = require("./../../clients/" + process.env.REACT_APP_CLIENT_FOLDER + "/sensor_map.yml");

export enum DateTimeFormatTarget {
    ChartTimeAxis,
    ChartTooltip,
    ShadowUpdate,
    StatusTable,
    EventsTable,
}

export enum DrawerState {
    Closed,
    Open,
    Full,
}

export enum DeviceBrowserMode {
    Legacy,
    Tree,
    TreeFromJson,
}

export default class Utils {
    private static HOUR_AS_MILLISECONDS: number = 1 * 60 * 60 * 1000;
    private static MINUTE_AS_MILLISECONDS: number = 1 * 60 * 1000;

    public static getDeviceBrowserMode(): DeviceBrowserMode {
        let browserMode: DeviceBrowserMode = DeviceBrowserMode.Legacy;
        const browserModeStr: string = process.env.REACT_APP_DEVICE_BROWSER_MODE;
        if (browserModeStr) {
            switch (browserModeStr) {
                case "tree":
                    browserMode = DeviceBrowserMode.Tree;
                    break;
                case "tree_json":
                    browserMode = DeviceBrowserMode.TreeFromJson;
                    break;
            }
        }
        return browserMode;
    }

    public static getSensorName(sensorId: string): string {
        if (sensorMap.sensors == null || sensorMap.sensors[sensorId] == null) {
            return sensorId;
        }
        return sensorMap.sensors[sensorId];
    }

    public static getDisplayName(device: Device): string {
        const state = device.getState();
        if (state) {
            const stateProperties = state.getStateProperties(StateOptions.Current);
            if (stateProperties && stateProperties.displayName && stateProperties.displayName !== "") {
                return stateProperties.displayName;
            }
        }
        return device.getId();
    }

    public static getDateTimeFormat(target: DateTimeFormatTarget): string {
        switch (target) {
            case DateTimeFormatTarget.ChartTimeAxis:
                return "d/M HH:mm";
            case DateTimeFormatTarget.ChartTooltip:
                return "d/M/yyyy HH:mm:ss";
            case DateTimeFormatTarget.StatusTable:
            case DateTimeFormatTarget.EventsTable:
                return "DD/MM/YYYY HH:mm:ss";
            case DateTimeFormatTarget.ShadowUpdate:
                return "D/M/Y HH:mm:ss";
        }
    }

    public static numberToFixed(value: number, decimalCount: number): string {
        if (isNaN(value) || value == null) {
            return "N/A";
        }
        return value.toFixed(decimalCount);
    }

    public static convertTimestampToString(timestamp: string, formatTarget: DateTimeFormatTarget): string {
        return moment(Number(timestamp)).format(Utils.getDateTimeFormat(formatTarget));
    }

    public static convertStringToTimestamp(dateTime: string, inputFormat: DateTimeFormatTarget): number {
        return moment(dateTime, Utils.getDateTimeFormat(inputFormat)).unix();
    }

    public static millisecondsToHoursAndMinutes(milliseconds: number): string {
        const hours: number = Math.floor(milliseconds / Utils.HOUR_AS_MILLISECONDS);
        const minutes: number = Math.floor((milliseconds % Utils.HOUR_AS_MILLISECONDS) / Utils.MINUTE_AS_MILLISECONDS);
        if (hours > 0) {
            return hours + " h " + minutes + " min";
        }
        return minutes + " min";
    }

    public static isEmpty(obj: object): boolean {
        if ((Object.keys(obj).length === 0 && obj.constructor === Object) || obj === null || obj === undefined) {
            return true;
        }
    }

    public static compareDevicesForSorting(first: Device, second: Device): number {
        const firstName = this.getDisplayName(first);
        const secondName = this.getDisplayName(second);
        const numberComparison = this.compareDeviceNamesAsNumbers(firstName, secondName);
        if (numberComparison === 0) {
            return firstName.localeCompare(secondName);
        } else {
            return numberComparison;
        }
    }

    public static compareGroupsForSorting(first: IGroup, second: IGroup): number {
        return first.groupId.localeCompare(second.groupId);
    }

    public static timestampToMilliseconds(timestamp: number): number {
        let millisecTimestamp = timestamp;
        if (`${timestamp}`.length < 13) {
            // timestamp seems to be expressed as seconds => convert to millisec
            millisecTimestamp = timestamp * 1000;
        }
        return millisecTimestamp;
    }

    public static isTimestampWithinTimeoutBounds(timestamp: number, timeoutMs: number): boolean {
        const deltaMs = Date.now() - Utils.timestampToMilliseconds(timestamp);
        return deltaMs < timeoutMs;
    }

    // For sorting device names ending with a number (so that e.g. "Device 2" shows up before "Device 10")
    private static compareDeviceNamesAsNumbers(deviceName1: string, deviceName2: string): number {

        try {
            // Split up the strings to compare into tokens
            const tokens1 = deviceName1.split(" ");
            const tokens2 = deviceName2.split(" ");

            // We only care about strings with equal number of tokens
            if (tokens1.length > 0 && tokens1.length === tokens2.length) {

                // Compare the all the other tokens except the last one...
                let tokenComparisons: number = 0;
                tokens1.forEach((token1: string, index: number) => {
                    if (index < tokens1.length - 1) {
                        tokenComparisons += Math.abs(token1.localeCompare(tokens2[index]));
                    }
                });

                // If all other tokens were equal, we compare the final tokens
                if (tokenComparisons === 0) {

                    // ...and compare them as numbers, if applicable
                    const number1 = Number(tokens1[tokens1.length - 1].replace("#", ""));
                    const number2 = Number(tokens2[tokens2.length - 1].replace("#", ""));

                    // One or both of the tokens are not a number => rely on "normal" comparison
                    if (isNaN(number1) || isNaN(number2)) {
                        return 0;
                    }

                    return number1 < number2 ? -1 : 1;
                }
            }
        } catch (error) {
            console.log("Error in compareDeviceNamesAsNumbers", error);
        }
        return 0;
    }

    public static isValidEmail(email: string): boolean {
        return email.length > 2 && email.search("@") > 0;
    }
}
