/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { ChangeEvent, Component } from "react";

interface Props {
    searchString: string;
    className: string;
    onSearchTriggered: (searchString: string) => void;
}

interface State {
    searchString: string;
}

export default class SearchBar extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            searchString: props.searchString,
        };
    }

    private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Enter") {
            this.triggerSearch();
        }
    }

    private triggerSearch = (): void => {
        this.props.onSearchTriggered(this.state.searchString);
    }

    public render(): JSX.Element {
        return (
            <div className={this.props.className}>
                <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel>Search</InputLabel>
                    <OutlinedInput
                        type="text"
                        value={this.state.searchString}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                            this.setState({ searchString: event.target.value })}
                        onKeyPress={this.handleKeyPress}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton type="submit" aria-label="search" onClick={this.triggerSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
            </div>
        );
    }
}
