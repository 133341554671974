/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import BackendFactory from "../../data/backend/BackendFactory";
import AddNewPopupBase from "./add-new-popup-base";

export default class AddDevicePopup extends AddNewPopupBase {

    protected handleSubmit = async (): Promise<void> => {
        this.setState({ loading: true });
        try {
            await BackendFactory.getBackend().addDeviceToOrganization(this.state.name, this.props.parentGroup);
            this.props.triggerGroupsUpdate();
            this.props.closePopup();
        } catch (error) {
            console.error("Failed to add new device: " + JSON.stringify(error));
            this.setState({
                loading: false,
                errorMsg: "Failed to add device",
            });
        }
    }
}
