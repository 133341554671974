/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import {OrganizationUtils} from "./OrganizationUtils";
import User from "./User";

export default class AWSUser extends User {
    private readonly userName: string;
    private admin: boolean;

    constructor(userName: string, admin: boolean) {
        super();
        this.userName = userName;
        this.admin = admin;
    }

    public getUserName(): string {
        return this.userName;
    }

    public isAdmin(): boolean {
        return this.admin;
    }

    public async setIsAdmin(value: boolean): Promise<void> {
        await OrganizationUtils.setUserAdminStatus(this.userName, value);
        this.admin = value;
    }
}
