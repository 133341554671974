/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { IData } from "../../data/clientSpecific/IData";
import { Device } from "../../data/device/Device";
import Utils from "./../../data/utils/utils";

interface Props {
    onSensorSelect: (sensors: string[], sensors2: string[]) => void;
    data: IData[];
    selectedDevice: Device;
}
interface State {
    // TODO: Why is csvData a state variable?
    csvData: IData[];
    availableSensors: string[];
    selectedSensor: string[];
    selectedSensor2: string[];
}

export default class IoTHistoryTools extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            csvData: null,
            availableSensors: null,
            selectedSensor: null,
            selectedSensor2: null,
        };
    }

    public componentDidMount(): void {
        this.generateCSV();
        this.setAvailableSensors();
    }

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.generateCSV();
            this.setAvailableSensors();
        }

        if (this.state.selectedSensor !== prevState.selectedSensor ||
            this.state.selectedSensor2 !== prevState.selectedSensor2) {
            this.props.onSensorSelect(this.state.selectedSensor, this.state.selectedSensor2);
        }
    }

    private setAvailableSensors(): void {
        if (this.props.data && this.props.data.length !== 0) {
            const sensors: string[] = [];
            const invalidKeys: string[] = ["__typename", "timestamp", "deviceId", "sessionId"];
            this.props.data.forEach((item: IData) => {
                const keys = Object.keys(item);
                keys.forEach((key: string) => {
                    if (!invalidKeys.includes(key) && !sensors.includes(key) && item[key] !== null) {
                        sensors.push(key);
                    }
                });
            });
            if (this.state.selectedSensor && sensors.includes(this.state.selectedSensor[0])) {
                this.setState({
                    availableSensors: sensors,
                });
            } else {
                this.setState({
                    availableSensors: sensors,
                    selectedSensor: [sensors[0]],
                    selectedSensor2: null,
                });
            }
        }
    }

    private generateCSV(): void {
        if (this.props.data != null && this.props.data.length !== 0) {
            console.log("Creating CSV export");
            const csvData = this.props.data.map((data: IData) => {
                const keys: string[] = Object.keys(data);
                const newData: IData = Object.create(data);
                keys.forEach((key: string) => {
                    const newKey = Utils.getSensorName(key);
                    if (data[key] != null) {
                        newData[newKey] = data[key];
                    }
                });
                delete newData.__typename;
                return newData;
            });
            this.setState({ csvData });
        }
    }

    private selectSensor1 = (event: React.ChangeEvent<{ value: unknown }>): void => {
        const value: string[] = event.target.value as string[];
        if (value[value.length - 1] === "") {
            this.setState({ selectedSensor: null });
        } else {
            this.setState({ selectedSensor: value });
        }
    }

    private selectSensor2 = (event: React.ChangeEvent<{ value: unknown }>): void => {
        const value: string[] = event.target.value as string[];
        if (value[value.length - 1] === "") {
            this.setState({ selectedSensor2: null });
        } else {
            this.setState({ selectedSensor2: value });
        }
    }

    private getCSVName = (): string => {
        return Utils.getDisplayName(this.props.selectedDevice) + ".csv";
    }

    private listSensors = (): JSX.Element[] => {
        if (this.state.availableSensors != null && this.state.availableSensors.length !== 0) {
            const sensors: JSX.Element[] = this.state.availableSensors.map((sensor: string) => {
                return <MenuItem key={sensor} value={sensor}>{Utils.getSensorName(sensor)}</MenuItem>;
            });
            return sensors;
        }
    }

    private renderValue = (selected: SelectProps["value"]): string => {
        const selectedStringList: string[] = selected as string[];
        const sensorNames: string[] = [];
        if (selectedStringList.length === 0) {
            return "None";
        } else if (selectedStringList.length > 0 && selectedStringList.length < 3) {
            selectedStringList.forEach((sensor: string) => {
                const newSensor = Utils.getSensorName(sensor);
                sensorNames.push(newSensor);
            });
            return sensorNames.join(", ");
        } else {
            return String(selectedStringList.length) + " sensors selected";
        }
    }

    private renderSensorSelection1 = (): JSX.Element => {
        if (this.state.availableSensors) {
            return (
                <div className="select-sensor-container left">
                    <FormControl className="select-sensor-picker">
                        <Select
                            multiple={true}
                            renderValue={this.renderValue}
                            value={this.state.selectedSensor || []}
                            onChange={this.selectSensor1}
                            name="sensor-selection1"
                            displayEmpty={true}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {this.listSensors()}
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }

    private renderSensorSelection2 = (): JSX.Element => {
        if (this.state.availableSensors) {
            return (
                <div className="select-sensor-container right">
                    <FormControl className="select-sensor-picker">
                        <Select
                            multiple={true}
                            renderValue={this.renderValue}
                            value={this.state.selectedSensor2 || []}
                            onChange={this.selectSensor2}
                            displayEmpty={true}
                            name="sensor-selection2"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {this.listSensors()}
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }

    private renderCSVExportButton = (): JSX.Element => {
        return (
            <div className="export-container">
                <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                        marginRight: "5rem",
                        display: "inline-block",
                    }}
                >
                    Drag to zoom, right click to reset
                </Typography>
                {this.state.csvData != null &&
                    <CSVLink
                        filename={this.getCSVName()}
                        data={this.state.csvData}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                        >
                            Export as CSV
                        </Button>
                    </CSVLink>
                }
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <div className="stats-tools-container">
                {this.renderSensorSelection1()}
                {this.renderSensorSelection2()}
                {this.renderCSVExportButton()}
            </div>
        );
    }
}
