/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { CognitoUser } from "@aws-amplify/auth";
import React, { Component, Fragment } from "react";
import AuthWrapper from "../../data/auth/authWrapper";
import { Device } from "../../data/device/Device";
import { IGroup } from "../../data/group/IGroup";
import { Organization } from "../../data/organization/Organization";
import { OrganizationUtils } from "../../data/organization/OrganizationUtils";
import { Tree } from "../../data/tree/tree";
import ErrorNote from "../global/error-note";
import InlineBox from "../global/inline-box";
import Loader from "../global/loader";
import DeviceList from "./device-list";
import OrganizationDetails from "./organization-details";
import UsersList from "./users-list";

interface State {
    organization: Organization;
    currentUser: string;
    searchFilter: string;
    errorMsg: string;
}

interface Props {
    groups: IGroup[];
    devices: Device[];
    legacyBrowserMode: boolean;
    tree: Tree;
    triggerGroupsUpdate: () => void;
}

export default class AdminWrapper extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            currentUser: null,
            organization: null,
            searchFilter: "",
            errorMsg: null,
        };
    }

    public async componentDidMount(): Promise<void> {
        if (!(await OrganizationUtils.isCurrentUserAdmin())) {
            this.setState({
                currentUser: "",
                errorMsg: "Not an admin user",
            });
            return;
        }
        await this.initOrganization();
        const user: CognitoUser = await AuthWrapper.getCurrentAuthenticatedUser(false);
        if (!user) {
            this.setState({
                currentUser: "",
                errorMsg: "Failed to validate user",
            });
            return;
        }
        this.setState({ currentUser: user.getUsername() });
    }

    private async initOrganization(): Promise<void> {
        const org: Organization =
            await OrganizationUtils.getOrganization(await OrganizationUtils.getOrganizationNameForCurrentUser());
        this.setState({ organization: org });
    }

    private handleCloseErrorNote = (): void => {
        this.setState({ errorMsg: null });
    }

    private renderOrganizationFields(): JSX.Element {
        if (this.state.organization) {
            return (
                <OrganizationDetails
                    organization={this.state.organization}
                />
            );
        }
    }

    private renderUsersList(): JSX.Element {
        if (this.state.organization) {
            return (
                <UsersList
                    organization={this.state.organization}
                    currentUser={this.state.currentUser}
                />
            );
        }
    }

    private renderErrorNote(): JSX.Element {
        if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleCloseErrorNote}
                />
            );
        }
    }

    public render(): JSX.Element {
        if (this.state.currentUser === null) {
            return <Loader />;
        } else if (this.state.currentUser === "") {
            return (
                <Fragment>
                    {this.renderErrorNote()}
                    <h3
                        style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
                    >
                        Nothing to see here ;)
                    </h3>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <div className="organization-title" style={{ width: "100%" }}>
                    {this.renderOrganizationFields()}
                </div>
                <div className="admin-list-container">
                    <InlineBox>
                        {this.renderUsersList()}
                    </InlineBox>
                </div>
                <div className="admin-list-container">
                    <InlineBox>
                        <DeviceList
                            devices={this.props.devices}
                            groups={this.props.groups}
                            tree={this.props.tree}
                            triggerGroupsUpdate={this.props.triggerGroupsUpdate}
                        />
                    </InlineBox>
                </div>
            </Fragment>
        );
    }
}
