/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Typography } from "@material-ui/core";
import React from "react";

interface TabProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default function ListTab(props: TabProps): JSX.Element {
    const { value, index, children } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
        >
            {children}
        </Typography>
    );
}
