/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { DeviceSettings } from "../clientSpecific/DeviceSettings";
import { DeviceStateProperties } from "../clientSpecific/DeviceStateProperties";
import Utils from "../utils/utils";

export enum StateOptions {
    Current,
    Next,
}

export default class DeviceState {
    private nextState: DeviceStateProperties;
    private currentState: DeviceStateProperties;
    private stateUpdatedTimestamp: number;

    constructor(current: DeviceStateProperties, next: DeviceStateProperties, timestamp: number) {
        this.nextState = next || null;
        this.currentState = current || null;
        this.stateUpdatedTimestamp = timestamp || null;
    }

    public getStateProperties(stateOption?: StateOptions): DeviceStateProperties {
        if (stateOption === StateOptions.Next) {
            return this.nextState;
        }
        return this.currentState;
    }

    private getPendingState(key: keyof DeviceSettings): boolean {
        if (this.nextState != null && this.currentState != null
            && this.nextState[key] != null
            && this.currentState[key] !== this.nextState[key]) {
            return true;
        }
        return false;
    }

    public getStateUpdatedTimestampMillis(): number {
        return Utils.timestampToMilliseconds(this.stateUpdatedTimestamp);
    }

    public exportSettings(): DeviceSettings {
        const deviceSettings = new DeviceSettings();
        const settingsKeys = Object.keys(deviceSettings) as Array<keyof DeviceSettings>;
        settingsKeys.forEach((key: keyof DeviceSettings) => {
            if (deviceSettings[key] != null) {
                if (this.nextState && this.nextState[key] != null) {
                    deviceSettings[key].value = this.nextState[key];
                } else {
                    deviceSettings[key].value = this.currentState[key] || null;
                }
                deviceSettings[key].pending = this.getPendingState(key);
            }
        });
        return deviceSettings;
    }
}
