/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

export enum EventType {
    Sensor = "SENSOR",
    Generic = "GENERIC",
}

export enum EventSeverity {
    Low = "LOW",
    Medium = "MEDIUM",
    High = "HIGH",
}

export enum EventState {
    Active = "ACTIVE",
    Inactive = "INACTIVE",
}

export interface IEvent {
    deviceId: string;
    timestamp: string;
    eventId: string;
    updatedTimestamp: string;
    type: EventType;
    eventState: EventState;
    severity: EventSeverity;
    sensorName: string;
    sensorValue: number;
}

export interface IEventMetadata {
    eventId: string;
    description: string;
}

export default IEvent;
