/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import moment from "moment";
import { Component } from "react";
import React from "react";
import BackendFactory from "../../data/backend/BackendFactory";
import IEvent, { EventSeverity, EventState } from "../../data/clientSpecific/IEvent";
import { Device } from "../../data/device/Device";
import DeviceSelector from "../../data/deviceSelector/DeviceSelector";
import DeviceSelectorObserver from "../../data/deviceSelector/DeviceSelectorObserver";
import { EventRepositoryListener } from "../../data/events/EventRepositoryListener";
import EventsRepository from "../../data/events/EventsRepository";
import IGroup from "../../data/group/IGroup";
import { DeviceStateObserver } from "../../data/observer/DeviceStateObserver";
import IObservable from "../../data/observer/IObservable";
import { Tree } from "../../data/tree/tree";
import { IoTTreeItem } from "../../data/tree/tree-item";
import Utils, { DateTimeFormatTarget } from "../../data/utils/utils";
import ScrollDrag from "../controls/scroll-drag";
import ErrorNote from "../global/error-note";
import Loader from "../global/loader";
import TreeFolder from "./tree-folder";
import TreeNode from "./tree-node";
import { IoTDeviceTreeSearch } from "./tree-search";

interface Props {
    tree: Tree;
    searchFilter: string;
    adminMode: boolean;
    addGroup: (parentGroup: IGroup) => void;
    removeGroup: (groupName: IGroup) => void;
}

interface State {
    isLoading: boolean;
    errorMsg: string;
    treeItems: IoTTreeItem[];
    leafs: IoTTreeItem[];
    folderIdList: string[];
    expandedItems: string[];
}

class IoTDeviceTree extends Component<Props, State> implements DeviceSelectorObserver, DeviceStateObserver, EventRepositoryListener {

    private static storedExpandedItems: string[] = [];

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            errorMsg: null,
            treeItems: null,
            leafs: null,
            folderIdList: [],
            expandedItems: IoTDeviceTree.storedExpandedItems,
        };
    }

    public async componentDidMount(): Promise<void> {
        console.log("componentDidMount");
        DeviceSelector.getInstance().addObserver(this);
        EventsRepository.getInstance().addListener(this);
        if (this.props.tree != null) {
            await EventsRepository.getInstance().getAllActiveEvents();
            await this.buildTree();
        }
    }

    public componentWillUnmount(): void {
        DeviceSelector.getInstance().removeObserver(this);
        EventsRepository.getInstance().removeListener(this);
        this.cleanupDeviceStateObservers();
    }

    private addDeviceStateObservers(observables: IObservable[]): void {
        for (const observable of observables) {
            if (observable) {
                observable.addObserver(this);
            }
        }
    }

    private cleanupDeviceStateObservers(): void {
        if (this.state.leafs) {
            for (const iotTreeLeaf of this.state.leafs) {
                if (iotTreeLeaf) {
                    const observable = iotTreeLeaf.item ? iotTreeLeaf.item as IObservable : null;
                    if (observable) {
                        observable.removeObserver(this);
                    }
                }
            }
        }
    }

    public onDeviceStateUpdate(device: Device): void {
        const updatedTimestamp = moment(device.getState().getStateUpdatedTimestampMillis());
        console.log(`onDeviceStateUpdate ${device.getId()}: ${updatedTimestamp.format(Utils.getDateTimeFormat(DateTimeFormatTarget.ShadowUpdate))}`);
        if (DeviceSelector.getInstance().devices) {
            const deviceIndex = DeviceSelector.getInstance().devices.indexOf(device);
            if (deviceIndex >= 0) {
                const devices = DeviceSelector.getInstance().devices.slice();
                devices[deviceIndex] = device;
            }
        }
        this.updateLeafItem(device, this.state.treeItems);
    }

    public async onEvent(event: IEvent): Promise<void> {
        console.log("onEvent");
        const activeEvents = await EventsRepository.getInstance().getAllActiveEvents();
        this.updateTreeWithAlarms(this.state.leafs, activeEvents);
    }

    public async onEventStateChanged(event: IEvent): Promise<void> {
        console.log("onEventStateChanged");
        const activeEvents = await EventsRepository.getInstance().getAllActiveEvents();
        this.updateTreeWithAlarms(this.state.leafs, activeEvents);
    }

    public async componentDidUpdate(prevProps: Props, _prevState: State): Promise<void> {
        if (this.props.tree) {
            console.log("componentDidUpdate");
            if (this.props.tree !== prevProps.tree) {
                await EventsRepository.getInstance().getAllActiveEvents();
                await this.buildTree();
            }
            if (prevProps && this.props.searchFilter !== prevProps.searchFilter) {
                this.runFilter();
            }
        }
    }

    private async buildTree(): Promise<void> {
        console.log("buildTree");
        this.addDeviceStateObservers(this.props.tree.deviceList as IObservable[]);
        const handledItems: string[] = [];
        const searchFilter: RegExp = IoTDeviceTreeSearch.getCaseInsensitiveSearchFilter(this.props.searchFilter);
        this.props.tree.treeItems.forEach((item: IoTTreeItem) => {
            IoTDeviceTreeSearch.executeSearchFilterCheck(searchFilter, item, handledItems);
        });
        const events = await EventsRepository.getInstance().getAllActiveEvents();
        this.updateTreeWithAlarms(this.props.tree.leafs, events);
        this.setState({ treeItems: this.props.tree.treeItems, leafs: this.props.tree.leafs, isLoading: false });
    }

    public onSelectedDeviceChanged(_device: Device): void {
        console.log("onSelectedDeviceChanged");
    }

    public onDeviceSetChanged(_devices: Device[]): void {
        console.log("onDeviceSetChanged");
    }

    // Update item when shadow changes
    private updateLeafItem(device: Device, iotTreeItems: IoTTreeItem[]): void {
        if (iotTreeItems) {
            iotTreeItems.forEach((treeItem: IoTTreeItem) => {
                if (treeItem.isGroup) {
                    this.updateLeafItem(device, treeItem.childNodesAndFolders);
                } else {
                    if (device.getId() === treeItem.id) {
                        const currentItems = this.state.treeItems;
                        treeItem.item = device;
                        this.setState({treeItems: currentItems});
                        return;
                    }
                }
            });
        }
    }

    private deviceSelection(deviceId: string): void {
        DeviceSelector.getInstance().setCurrentDevice(deviceId);
    }

    private updateTreeWithAlarms(leafs: IoTTreeItem[], events: IEvent[]): void {
        console.log("updateTreeWithAlarms");
        this.clearFolderAlarmStates(leafs);
        leafs.forEach((leaf: IoTTreeItem) => {
            const alarmsForThisDevice = events.filter((event: IEvent) => {
                return (leaf.id === event.deviceId &&
                        event.severity === EventSeverity.High &&
                        event.eventState === EventState.Active);
            });

            leaf.hasAlarms = alarmsForThisDevice.length > 0;
            if (leaf.hasAlarms) {
                console.log(`${leaf.id} has ${alarmsForThisDevice.length} alarms`);
                if (leaf.parent) {
                    this.setCascadingAlarmState(leaf, true);
                }
            }
        });
        this.setState({ leafs });
    }

    private clearFolderAlarmStates(leafs: IoTTreeItem[]): void {
        const handledFolders: string[] = [];
        leafs.forEach((leaf: IoTTreeItem) => {
            if (leaf.parent && !handledFolders.includes(leaf.parent.id)) {
                this.setCascadingAlarmState(leaf, false);
                handledFolders.push(leaf.parent.id);
            }
        });
    }

    private setCascadingAlarmState(item: IoTTreeItem, hasAlarms: boolean): void {
        item.hasAlarms = hasAlarms;
        if (item.parent) {
            this.setCascadingAlarmState(item.parent, hasAlarms);
        }
    }

    private findTreeItem(name: string, rootItem: IoTTreeItem): IoTTreeItem {
        let foundItem: IoTTreeItem = null;
        if (this.matchNameAndItem(name, rootItem)) {
            foundItem = rootItem;
        } else {
            if (rootItem.childNodesAndFolders) {
                rootItem.childNodesAndFolders.forEach((childItem: IoTTreeItem) => {
                    if (!foundItem) {
                        if (this.matchNameAndItem(name, childItem)) {
                            foundItem = childItem;
                        } else {
                            foundItem = this.findTreeItem(name, childItem);
                        }
                    }
                });
            }
        }
        return foundItem;
    }

    private matchNameAndItem(name: string, treeItem: IoTTreeItem): boolean {
        const itemName = (treeItem.isGroup ? (treeItem.item as IGroup).groupId : (treeItem.item as Device).getId());
        return (name === itemName);
    }

    private getTreeItem(name: string): IoTTreeItem {
        let foundItem: IoTTreeItem = null;
        this.props.tree.treeItems.forEach((item: IoTTreeItem) => {
            if (!foundItem) {
                foundItem = this.findTreeItem(name, item);
            }
        });
        return foundItem;
    }

    private handleDeviceMove = async (deviceName: string, newParent: string): Promise<void> => {
        const movedItem: IoTTreeItem = this.getTreeItem(deviceName);
        const oldParentItem: IoTTreeItem = movedItem.parent;
        const newParentItem: IoTTreeItem = this.getTreeItem(newParent);

        if (oldParentItem === newParentItem) {
            console.log("Skip move, same group");
            return;
        }

        if (!oldParentItem.isGroup || !newParentItem.isGroup) {
            throw new Error("Move failed - parents not groups");
        }

        try {
            await BackendFactory.getBackend().removeDeviceFromGroup(deviceName, oldParentItem.item as IGroup);
        } catch (error) {
            console.error("Removing device from old group failed: " + JSON.stringify(error));
            this.setState({errorMsg: "Failed to move device"});
        }

        try {
            await BackendFactory.getBackend().addDeviceToGroup(deviceName, newParentItem.item as IGroup);
            newParentItem.childNodesAndFolders.push(movedItem);
            movedItem.parent = newParentItem;
            oldParentItem.childNodesAndFolders.forEach((item: IoTTreeItem, index: number) => {
                if (item === movedItem) {
                    oldParentItem.childNodesAndFolders.splice(index, 1);
                }
            });
            this.forceUpdate();
        } catch (error) {
            console.error("Adding device to new group failed: " + JSON.stringify(error));
            this.setState({errorMsg: "Failed to add device to new group"});
            try {
                await BackendFactory.getBackend().addDeviceToGroup(deviceName, oldParentItem.item as IGroup);
            } catch (err) {
                console.error("Restoring device to original group failed: " + err);
                this.setState({errorMsg: "Failed to restore device to original group"});
            }
        }
    }

    private onNodeToggle(_event: object, expandedNodeIds: string[]): void {
        IoTDeviceTree.storedExpandedItems = expandedNodeIds;
        this.setState({expandedItems: expandedNodeIds});
    }

    private isTreeItemExpanded(treeItemId: string): boolean {
        return this.state.expandedItems.includes(treeItemId);
    }

    private async runFilter(): Promise<void> {
        const handledItems: string[] = [];
        const searchFilter: RegExp = IoTDeviceTreeSearch.getCaseInsensitiveSearchFilter(this.props.searchFilter);
        this.state.treeItems.forEach((item: IoTTreeItem) => {
            IoTDeviceTreeSearch.executeSearchFilterCheck(searchFilter, item, handledItems);
            this.forceUpdate();
        });

        let expanded: string[] = [];
        this.retrieveSearchMatchedFolders(this.state.treeItems, expanded);
        expanded = expanded.concat(this.state.expandedItems);
        IoTDeviceTree.storedExpandedItems = expanded;
        this.setState({ expandedItems: expanded });
    }

    private retrieveSearchMatchedFolders(treeItems: IoTTreeItem[], expandedFolderIds: string[]): void {
        treeItems.forEach((item: IoTTreeItem) => {
            if (item.isGroup && this.props.searchFilter.length > 0) {
                if (item.isFilterMatch && !this.state.expandedItems.includes(item.id)) {
                    expandedFolderIds.push(item.id);
                }
                if (item.childNodesAndFolders.length > 0) {
                    this.retrieveSearchMatchedFolders(item.childNodesAndFolders, expandedFolderIds);
                }
            }
        });
    }

    private async onFolderClick(_treeItem: IoTTreeItem): Promise<void> {
        this.updateTreeWithAlarms(this.state.leafs, await EventsRepository.getInstance().getAllActiveEvents());
    }

    private handleAddGroup(parentGroup: IGroup): void {
        this.props.addGroup(parentGroup);
    }

    private handleCloseErrorNote = (): void => {
        this.setState({ errorMsg: null });
    }

    private renderTree(treeItem: IoTTreeItem): JSX.Element {
        const nodeStyle: object = treeItem.isFilterMatch ? { backgroundColor: "transparent" } : { display: "none" };
        if (treeItem.isGroup) {
            const entry: JSX.Element = (
                <TreeFolder
                    key={treeItem.id}
                    treeItem={treeItem}
                    group={treeItem.item as IGroup}
                    isExpanded={(): boolean => this.isTreeItemExpanded(treeItem.id)}
                    adminMode={this.props.adminMode}
                    addGroup={(parentGroup: IGroup): void => this.handleAddGroup(parentGroup)}
                    removeGroup={(groupName: IGroup): void => this.props.removeGroup(groupName)}
                    onDeviceMove={(deviceName: string, newGroup: string): Promise<void> => this.handleDeviceMove(deviceName, newGroup)}
                />
            );
            return (
                <TreeItem
                    key={treeItem.id}
                    nodeId={treeItem.id}
                    label={entry}
                    style={nodeStyle}
                    classes={{ }}
                    onClick={(): Promise<void> => this.onFolderClick(treeItem)}
                >
                {treeItem.childNodesAndFolders.map((node: IoTTreeItem) => this.renderTree(node))}
                </TreeItem>
            );
        } else {
            const entry: JSX.Element = (
                <TreeNode
                    key={treeItem.id}
                    device={treeItem.item as Device}
                    deviceSelected={(deviceId: string): void => this.deviceSelection(deviceId)}
                    adminMode={this.props.adminMode}
                />
            );

            return (
                <TreeItem
                    key={treeItem.id}
                    nodeId={treeItem.id}
                    label={entry}
                    style={nodeStyle}
                    classes={{ }}
                />
            );
        }
    }

    private renderTreeView(): JSX.Element {
        return (
            <TreeView
                expanded={this.state.expandedItems}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeToggle={(event: object, nodeIds: string[]): void => this.onNodeToggle(event, nodeIds)}
            >
                {
                    this.state.treeItems.map((item: IoTTreeItem) => this.renderTree(item))
                }
            </TreeView>
        );
    }

    private renderErrorNote(): JSX.Element {
        if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleCloseErrorNote}
                />
            );
        }
    }

    private renderContents(): JSX.Element {
        if (this.props.adminMode) {
            return (
                <ScrollDrag rootClass={"tree-list"}>
                    {this.renderTreeView()}
                </ScrollDrag>
            );
        } else {
            return this.renderTreeView();
        }
    }

    public render(): JSX.Element {
        if (this.state.isLoading) {
            return <Loader/>;
        } else {
            return (
                <React.Fragment>
                    {this.renderErrorNote()}
                    {this.renderContents()}
                </React.Fragment>
            );
        }
    }
}

export default IoTDeviceTree;
