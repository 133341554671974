/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { HubCapsule } from "@aws-amplify/core/lib/Hub";
import { Hub } from "aws-amplify";

export default class AuthListener {

    // REFACTOR: Should we avoid type 'any' if possible? Strong typing helps readability.
    constructor(callback: any) {
        Hub.listen("auth", (data: HubCapsule) => this.listener(data, callback));
    }

    public removeListener(callback: any): void {
        Hub.remove("auth", (data: HubCapsule) => this.listener(data, callback));
    }

    private listener(data: HubCapsule, callback: any): void {
        switch (data.payload.event) {
            case "signIn":
                callback("SignedIn");
                break;
            case "signUp":
                callback("SignedUp");
                break;
            case "signOut":
                callback("SignedOut");
                break;
            case "signIn_failure":
                callback("SignInFailed");
                break;
        }
    }
}
