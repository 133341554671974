/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import BaseObservable from "../observer/BaseObservable";
import User from "./User";

export abstract class Organization extends BaseObservable {
    public abstract getDisplayName(): string;
    public abstract getUsers(): User[];
    public abstract async submitNameChange(newName: string): Promise<void>;
    public abstract createUser(username: string, isAdmin: boolean): Promise<void>;
    public abstract deleteUser(user: User): Promise<void>;
}
