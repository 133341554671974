/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import { eventUpdates } from "../graphql/subscriptions";
import { OrganizationUtils } from "../organization/OrganizationUtils";
import EventSet from "./EventSet";

export default class EventsSubscriptionManager {

    private subscribedData: EventSet[] = [];
    private subscriptions: ZenObservable.Subscription[] = [];
    private static instance: EventsSubscriptionManager = null;

    public static getInstance(): EventsSubscriptionManager {
        if (EventsSubscriptionManager.instance == null) {
            EventsSubscriptionManager.instance = new EventsSubscriptionManager();
        }
        return this.instance;
    }

    public subscribeEvents(eventSet: EventSet): void {
        if (this.subscribedData && this.subscribedData.indexOf(eventSet) === -1) {
            this.subscribedData.push(eventSet);
            if (this.subscriptions.length === 0) {
                this.subscribe().catch((reason: any) => console.error("Failed to subscribe: " + JSON.stringify(reason)));
            }
        }
    }

    public removeSubscription(dataSet: EventSet): void {
        const observerIndex = this.subscribedData.indexOf(dataSet);
        if (observerIndex !== -1) {
            if (this.subscribedData.length === 1) {
                do {
                    this.subscriptions.pop().unsubscribe();
                } while (this.subscriptions.length > 0);
            }
            this.subscribedData.splice(observerIndex, 1);
        }
    }

    private findEventSet(deviceId: string): EventSet {
        let match: EventSet = null;
        this.subscribedData.forEach((eventSet: EventSet) => {
            if (deviceId === eventSet.getId()) {
                match = eventSet;
            }
        });
        return match;
    }

    private async subscribe(): Promise<void> {
        const canSees: string[] = await OrganizationUtils.getCanSeeValuesForCurrentUser();
        canSees.forEach((canSee: string) => {
            this.subscriptions.push(this.subscribeWithIdentity(canSee));
        });
    }

    private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
        console.log("Events subs for " + identity);
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.EVENTS, clientType.TYPE_COGNITO);
        return appSyncClient.subscribe({
            query: gql(eventUpdates),
            variables: {
                receiver: identity,
            },
        }).subscribe({
            error: (error: any): void => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscribe();
                }
                console.error(error);
            },
            next: (newData: any): void => {
                const eventSet = this.findEventSet(newData.data.onEvent.deviceId);
                if (eventSet) {
                    eventSet.setEvent(newData.data.onEvent);
                }
                console.log(newData);
            },
        });
    }
}
