/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { Component } from "react";
import { OrganizationObserver } from "../../data/observer/OrganizationObserver";
import { Organization } from "../../data/organization/Organization";
import User from "../../data/organization/User";
import ConfirmationDialog from "../global/confirmation-dialog";
import ErrorNote from "../global/error-note";
import Loader from "../global/loader";
import NewUserForm from "./new-user-form";
import UserListItem from "./user-list-item";

interface Props {
    organization: Organization;
    currentUser: string;
}

interface State {
    loading: boolean;
    errorMsg: string;
    users: User[];
    deleteUser: User;
}

export default class UsersList extends Component<Props, State> implements OrganizationObserver {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            errorMsg: null,
            users: [],
            deleteUser: null,
        };
    }

    public componentDidMount(): void {
        this.props.organization.addObserver(this);
        const userList: User[] = this.props.organization.getUsers();
        this.setState({
            loading: userList.length === 0,
            users: userList,
        });
    }

    public componentWillUnmount(): void {
        this.props.organization.removeObserver(this);
    }

    public onOrganizationNameChange(name: string): void {
        // Do nothing
    }

    public onUsersChange(usersList: User[]): void {
        this.setState({
            users: usersList,
            loading: false,
        });
    }

    private submitNewUser = async (username: string, isAdmin: boolean): Promise<void> => {
        this.setState({ loading: true });
        try {
            await this.props.organization.createUser(username, isAdmin);
        } catch (error) {
            let reason: string = "User creation failed";
            if (error.message && (error.message as string).search("account already exists") >= 0) {
                reason = "User already exists";
            }
            this.setState({
                loading: false,
                errorMsg: reason,
            });
        }
    }

    private handleDeleteUser = (user: User): void => {
        this.setState({deleteUser: user});
    }

    private submitDeleteUser = async (): Promise<void> => {
        this.setState({ loading: true });
        try {
            await this.props.organization.deleteUser(this.state.deleteUser);
        } catch (error) {
            this.setState({
                loading: false,
                errorMsg: "User deletion failed",
            });
        }
    }

    private handleCloseErrorNote = (): void => {
        this.setState({ errorMsg: null });
    }

    private handleCancelDelete = (): void => {
        this.setState({deleteUser: null});
    }

    private getUsersTableItems(): JSX.Element | JSX.Element[] {
        const users: User[] = this.props.organization.getUsers();
        if (users && users.length > 0) {
            return users.map((user: User) => {
                return (
                    <UserListItem
                        key={user.getUserName()}
                        user={user}
                        isCurrentUser={user.getUserName() === this.props.currentUser}
                        deleteUser={this.handleDeleteUser}
                    />
                );
            });
        } else {
            return (
                <tr>
                    <td className="users-list-empty-text">No users</td>
                </tr>
            );
        }
    }

    private renderUsersList = (): JSX.Element | JSX.Element[] => {
        if (this.state.loading) {
            return <Loader/>;
        } else if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleCloseErrorNote}
                />
            );
        } else {
            return (
                <Table className="org-users-table" style={{ maxHeight: "100% !important", overflowY: "auto" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell align="center">Admin</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.getUsersTableItems()}
                    </TableBody>
                </Table>
            );
        }
    }

    private renderNewUserForm(): JSX.Element {
        return (
            <NewUserForm
                createUser={this.submitNewUser}
            />
        );
    }

    private renderRemoveGroupPopup(): JSX.Element {
        if (this.state.deleteUser) {
            return (
                <ConfirmationDialog
                    title="Delete"
                    message={this.state.deleteUser.getUserName()}
                    handleConfirm={this.submitDeleteUser}
                    handleCancel={this.handleCancelDelete}
                />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <div className="org-users">
                {this.renderNewUserForm()}
                {this.renderUsersList()}
                {this.renderRemoveGroupPopup()}
            </div>
        );
    }
}
