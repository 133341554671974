/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Interaction from "ol/interaction/Interaction";
import Select from "ol/interaction/Select";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import View from "ol/View";
import { events } from "openlayers";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { IData } from "../../data/clientSpecific/IData";
import { Device } from "../../data/device/Device";
import { DrawerState } from "../../data/utils/utils";
import CloseIcon from "./../../assets/baseline-close-black-24px.svg";
import Marker from "./../../assets/baseline-place-24px.svg";
import StatusPopup from "./status-popup";
const proj = require("ol/proj");

const DEFAULT_CENTER: [number, number] = [25.72088, 62.24147]; // Jyväskylä
const ZOOM_MIN: number = 2;
const ZOOM_DEFAULT: number = 6;
const ZOOM_VALUE: number = 11;
const MARKER_ANCHOR: [number, number] = [0.5, 1];
const OVERLAY_OFFSET: [number, number] = [0, 10];

interface Props extends RouteComponentProps {
    mapData: IData[];
    selectedDevice: Device;
    drawerState: DrawerState;
}

interface State {
    zoomNeeded: boolean;
}

class StatusMap extends Component<Props, State> {
    // REFACTOR: Think of extracting map functionality behind interface so that
    // we can easily swap the map provider.
    private map: Map | null = null;
    private popup: Overlay | null = null;
    private statusMapPointsLayer: VectorLayer = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            zoomNeeded: false,
        };
    }

    public componentDidMount(): void {
        this.renderMap();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.mapData !== this.props.mapData) {
            console.log("Mapdata changed");
            if (this.statusMapPointsLayer) {
                this.map.removeLayer(this.statusMapPointsLayer);
                this.statusMapPointsLayer = null;
            }
            this.renderMarkers();
        }
        if (prevProps.selectedDevice !== this.props.selectedDevice) {
            console.log("Device changed");
            this.setState({ zoomNeeded: true });
        } else if (prevProps.selectedDevice === this.props.selectedDevice &&
            this.state.zoomNeeded && this.props.mapData) {
            this.setState({ zoomNeeded: false });
        }

        if (this.props.drawerState !== prevProps.drawerState && this.props.drawerState !== DrawerState.Closed) {
            console.log("mapSizeChanged");
            this.map.updateSize();
        }
    }

    private renderMarkers(): void {
        const iconStyle = new Style({
            image: new Icon({
                anchor: MARKER_ANCHOR,
                src: Marker,
            }),
        });
        const markers: Feature[] = [];
        this.props.mapData.forEach((mapData: IData) => {
            if (mapData && mapData.deviceId && mapData.longitude !== null && mapData.latitude !== null) {
                const iconFeature = new Feature({
                    geometry: new Point(proj.fromLonLat(
                        [mapData.longitude, mapData.latitude])),
                    properties: {
                        deviceId: mapData.deviceId,
                        timestamp: mapData.timestamp,
                    },
                    type: "marker",
                });
                iconFeature.setStyle(iconStyle);
                markers.push(iconFeature);
            }
        });
        this.renderLayer(markers);
    }

    private renderLayer(features: Feature[]): void {
        if (this.map !== null) {
            const vectorSource = new VectorSource({
                features,
            });
            const vectorLayer = new VectorLayer({
                source: vectorSource,
            });
            this.statusMapPointsLayer = vectorLayer;
            this.map.addLayer(vectorLayer);
        }
    }

    private renderMap(): void {
        console.log("Initializing map");
        const tileLayer = new TileLayer({
            source: new OSM(),
        });
        this.map = new Map({
            layers: [tileLayer],
            target: "status-map",
            view: new View({
                center: proj.fromLonLat(DEFAULT_CENTER),
                extent: proj.transformExtent([180, -90, -180, 90], "EPSG:4326", "EPSG:3857"),
                minZoom: ZOOM_MIN,
                zoom: ZOOM_DEFAULT,
            }),
            loadTilesWhileAnimating: true,
            loadTilesWhileInteracting: true,
        });
        const selectSingleClick: Select = new Select();
        const select: Interaction = selectSingleClick;
        this.map.addInteraction(select);
        select.on("select", (event: events.Event) => {
            if (event !== null) {
                const feature = event.target.getFeatures().array_[0];

                if (feature) {
                    this.openPopup(feature);
                }
            }
        });
        this.initializeOverlay();
    }

    private initializeOverlay = (): void => {
        console.log("Initializing overlay");
        if (!this.popup) {
            const overlayElement = document.getElementById("status-popup");
            this.popup = new Overlay({
                id: "popup",
                element: overlayElement || undefined,
                offset: OVERLAY_OFFSET,
                positioning: "top-center",
                stopEvent: false,
            });
        }
        this.map.addOverlay(this.popup);
    }

    private openPopup = (feature: any): void => {
        this.props.history.push("/status/" + feature.values_.properties.deviceId);
    }

    private closePopup = (): void => {
        this.props.history.push("/status");
        this.popup.setPosition(undefined);
    }

    private renderOverlayContent(): JSX.Element {
        console.log("Render overlay");
        if (this.props.selectedDevice && this.props.mapData && this.popup) {
            let statusMapData: IData = null;
            this.props.mapData.forEach((mapData: IData) => {
                if (mapData && mapData.deviceId === this.props.selectedDevice.getId()) {
                    statusMapData = mapData;
                }
            });
            if (statusMapData && statusMapData.longitude != null && statusMapData.latitude != null) {
                const coordinates = proj.fromLonLat([statusMapData.longitude, statusMapData.latitude]);
                this.popup.setPosition(coordinates);
                if (this.state.zoomNeeded) {
                    const currentZoomValue = this.map.getView().getZoom();
                    const targetZoomValue = currentZoomValue > ZOOM_VALUE ? currentZoomValue : ZOOM_VALUE;
                    this.map.getView().animate({ center: coordinates }, { zoom: targetZoomValue });
                }
                this.popup.getElement().className = "";
                return (
                    <Fragment>
                        <img className="close-icon" alt="close" src={CloseIcon} onClick={this.closePopup} />
                        <StatusPopup
                            selectedDevice={this.props.selectedDevice}
                            popupData={statusMapData}
                        />
                    </Fragment>
                );
            } else {
                this.popup.setPosition(undefined);
                this.popup.getElement().className = "hidden";
            }
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                <div id="status-map">
                    <div id="status-popup" className="hidden">
                        {this.renderOverlayContent()}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(StatusMap);
