/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import ClientProperties from "../clientSpecific/ClientProperties";
import IEvent, { EventState, IEventMetadata } from "../clientSpecific/IEvent";
import { getEventMetadata, getEvents } from "../graphql/queries";
import {eventUpdates} from "../graphql/subscriptions";
import { OrganizationUtils } from "../organization/OrganizationUtils";
import { EventRepositoryListener } from "./EventRepositoryListener";

interface AppSyncGetEventsResponse {
    getEvents: {
        items: IEvent[];
        nextToken?: string;
    };
}

interface AppSyncGetEventMetadataResponse {
    getEventMetadata: {
        items: IEventMetadata[];
        nextToken?: string;
    };
}

export default class EventsRepository {

    private static instance: EventsRepository;

    private events: IEvent[] = [];
    private eventMetadata: Map<string, IEventMetadata>;
    private subscriptions: ZenObservable.Subscription[] = [];
    private listeners: EventRepositoryListener[] = [];

    public static getInstance(): EventsRepository {
        if (this.instance == null) {
            this.instance = new EventsRepository();
        }
        return this.instance;
    }

    public async init(): Promise<void> {
        console.log("init");
        this.eventMetadata = new Map();
        await this.fetchEventMetadata();
        this.subscribe();
    }

    public uninit(): void {
        console.log("uninit");
        if (this.subscriptions) {
            this.subscriptions.forEach((s: ZenObservable.Subscription) => {
                s.unsubscribe();
            });
            this.subscriptions = [];
        }
        if (this.eventMetadata) {
            this.eventMetadata.clear();
        }
    }

    public async getAllActiveEvents(): Promise<IEvent[]> {
        if (!this.events || this.events.length === 0) {
            const range = ClientProperties.getDefaultEventTimestampRange(ClientProperties.EVENT_AGE_DAYS);
            await this.fetchAllEvents(`${range.start}`, `${range.end}`);
        }
        return this.events.filter((e: IEvent) => {
            return e.eventState === EventState.Active;
        });
    }

    public getEventDescription(eventId: string): string {
        if (this.eventMetadata && this.eventMetadata.has(eventId)) {
            return this.eventMetadata.get(eventId).description;
        } else {
            return eventId;
        }
    }

    public async fetchAllEvents(startTimestamp: string, endTimestamp?: string): Promise<IEvent[]> {
        let nextToken: string = null;
        let events: IEvent[] = [];
        try {
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.EVENTS, clientType.TYPE_COGNITO);
                const eventsResponse: ApolloQueryResult<AppSyncGetEventsResponse> = await appSyncClient.query({
                    query: gql(getEvents),
                    variables: {
                        startTimestamp,
                        endTimestamp: endTimestamp != null ? endTimestamp : `${Date.now()}`,
                        nextToken,
                    },
                });
                nextToken = eventsResponse.data.getEvents.nextToken || null;
                events = events.concat(eventsResponse.data.getEvents.items);
            } while (nextToken);
            this.events = events;
            return events;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            return [];
        }
    }

    private async fetchEventMetadata(): Promise<void> {
        let nextToken: string = null;
        try {
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.EVENTS, clientType.TYPE_COGNITO);
                const resp: ApolloQueryResult<AppSyncGetEventMetadataResponse> = await appSyncClient.query({
                    query: gql(getEventMetadata),
                    variables: {
                        nextToken,
                    },
                });
                nextToken = resp.data.getEventMetadata.nextToken || null;
                resp.data.getEventMetadata.items.forEach((em: IEventMetadata) => {
                    this.eventMetadata.set(em.eventId, em);
                });
            } while (nextToken);
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
        }
    }

    public addListener(listener: EventRepositoryListener): void {
        const listenerIndex = this.listeners.indexOf(listener);
        if (listenerIndex !== -1) {
            this.removeListenerFromIndex(listenerIndex);
        }
        this.listeners.push(listener);
        console.log("Listener added");
    }

    public removeListener(listener: EventRepositoryListener): void {
        const listenerIndex = this.listeners.indexOf(listener);
        if (listenerIndex !== -1) {
            this.removeListenerFromIndex(listenerIndex);
            console.log("Listener removed");
        }
    }

    private removeListenerFromIndex(index: number): void {
        this.listeners.splice(index, 1);
    }

    private async subscribe(): Promise<void> {
        const canSees: string[] = await OrganizationUtils.getCanSeeValuesForCurrentUser();
        canSees.forEach((canSee: string) => {
            this.subscriptions.push(this.subscribeWithIdentity(canSee));
        });
    }

    private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
        console.log("subscribeWithIdentity " + identity);
        const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.EVENTS, clientType.TYPE_COGNITO);
        return appSyncClient.subscribe({
            query: gql(eventUpdates),
            variables: {
                receiver: identity,
            },
        }).subscribe({
            error: (error: any): void => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscriptions.push(this.subscribeWithIdentity(identity));
                }
                console.error(error);
            },
            next: (newData: any): void => {
                const event: IEvent = newData.data.onEvent;
                const isNew = this.handleNewEvent(event);
                console.log(`Triggering ${isNew ? "onEvent" : "onEventStateChanged"}`);
                this.listeners.forEach((listener: EventRepositoryListener) => {
                    if (isNew) {
                        listener.onEvent(event);
                    } else {
                        listener.onEventStateChanged(event);
                    }
                });
            },
        });
    }

    private handleNewEvent(event: IEvent): boolean {
        const index: number = this.findEventIndex(event);
        if (index >= 0) {
            console.log(JSON.stringify(event));
            this.events[index] = event;
        } else {
            this.events.push(event);
            return true;
        }
        return false;
    }

    private findEventIndex(event: IEvent): number {
        return this.events.findIndex((e: IEvent) => {
            return (event.deviceId === e.deviceId &&
                    event.eventId === e.eventId &&
                    event.timestamp === e.timestamp);
        });
    }
}
