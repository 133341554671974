/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { RouteComponentPropsParams } from "../../@types/routerprops";
import IEvent, { EventSeverity, EventState } from "../../data/clientSpecific/IEvent";
import { Device } from "../../data/device/Device";
import EventSet from "../../data/events/EventSet";
import { EventObserver } from "../../data/observer/EventObserver";
import Utils, { DateTimeFormatTarget } from "../../data/utils/utils";
import Battery20 from "./../../assets/baseline-battery_20-24px.svg";
import Battery30 from "./../../assets/baseline-battery_30-24px.svg";
import Battery50 from "./../../assets/baseline-battery_50-24px.svg";
import Battery60 from "./../../assets/baseline-battery_60-24px.svg";
import Battery80 from "./../../assets/baseline-battery_80-24px.svg";
import Battery90 from "./../../assets/baseline-battery_90-24px.svg";
import BatteryAlert from "./../../assets/baseline-battery_alert-24px.svg";
import BatteryCharging20 from "./../../assets/baseline-battery_charging_20-24px.svg";
import BatteryCharging30 from "./../../assets/baseline-battery_charging_30-24px.svg";
import BatteryCharging50 from "./../../assets/baseline-battery_charging_50-24px.svg";
import BatteryCharging60 from "./../../assets/baseline-battery_charging_60-24px.svg";
import BatteryCharging80 from "./../../assets/baseline-battery_charging_80-24px.svg";
import BatteryCharging90 from "./../../assets/baseline-battery_charging_90-24px.svg";
import BatteryChargingFull from "./../../assets/baseline-battery_charging_full-24px.svg";
import BatteryFull from "./../../assets/baseline-battery_full-24px.svg";
import Cellular1 from "./../../assets/baseline-signal_cellular_1_bar-24px.svg";
import Cellular2 from "./../../assets/baseline-signal_cellular_2_bar-24px.svg";
import Cellular3 from "./../../assets/baseline-signal_cellular_3_bar-24px.svg";
import Cellular4 from "./../../assets/baseline-signal_cellular_4_bar-24px.svg";
import Wifi1 from "./../../assets/baseline-signal_wifi_1_bar-24px.svg";
import Wifi2 from "./../../assets/baseline-signal_wifi_2_bar-24px.svg";
import Wifi3 from "./../../assets/baseline-signal_wifi_3_bar-24px.svg";
import Wifi4 from "./../../assets/baseline-signal_wifi_4_bar-24px.svg";
import IotItem from "./../../assets/iot-item-24px.svg";

export interface Props extends RouteComponentProps<RouteComponentPropsParams> {
    device: Device | null;
    deviceSelected: (deviceId: string) => void;
    adminMode: boolean;
}

export interface State {
    alarmTypes: string[];
    eventSet: EventSet;
}

export const TOOLTIP_DELAY_MS = 400;

enum Modem {
    Cellular = "cellular",
    Wifi = "wifi",
    Ethernet = "ethernet",
}

// Remove comments when/if powerSupply shadow item is supported
// enum PowerSupply {
//     Battery = "battery",
//     Mains = "mains",
// }

export abstract class BrowserItemBase extends Component<Props, State> implements EventObserver {

    constructor(props: Props) {
        super(props);

        this.state = {
            alarmTypes: [],
            eventSet: null,
        };
    }

    public componentDidMount(): void {
        this.checkEventAlarms();
    }

    public componentWillUnmount(): void {
        if (this.state.eventSet) {
            this.state.eventSet.removeObserver(this);
        }
    }

    public onEventSet(eventSet: EventSet): void {
        if (eventSet.getId() === this.props.device.getId()) {
            this.checkEventAlarms();
        }
    }

    protected async checkEventAlarms(): Promise<void> {
        if (this.props.device) {
            const eventSet: EventSet = await this.props.device.getEvents();
            if (eventSet != null) {
                const alarms: string[] = [];
                eventSet.addObserver(this);
                eventSet.getEvents().filter((event: IEvent) => {
                    const activeAlarm = event.eventState === EventState.Active &&
                                        event.severity === EventSeverity.High;
                    if (activeAlarm) {
                        if (!alarms.includes(event.sensorName)) {
                            alarms.push(event.sensorName);
                        }
                    }
                    return activeAlarm;
                });
                if ((JSON.stringify(alarms) !== JSON.stringify(this.state.alarmTypes))) {
                    this.setState({alarmTypes: alarms, eventSet});
                } else {
                    this.setState({eventSet});
                }
            } else {
                this.setState({eventSet: null});
            }
        }
    }

    protected getPowerSupplyIcon(): string {
        if (this.props.device && this.props.device.getState()) {
            const stateProperties = this.props.device.getState().getStateProperties();
            if (stateProperties.chargerConnected === true) {
                if (stateProperties.batterySoc < 30) {
                    return BatteryCharging20;
                } else if (stateProperties.batterySoc < 50) {
                    return BatteryCharging30;
                } else if (stateProperties.batterySoc < 60) {
                    return BatteryCharging50;
                } else if (stateProperties.batterySoc < 80) {
                    return BatteryCharging60;
                } else if (stateProperties.batterySoc < 90) {
                    return BatteryCharging80;
                } else if (stateProperties.batterySoc < 98) {
                    return BatteryCharging90;
                }
                return BatteryChargingFull;
            } else if (stateProperties.chargerConnected === false) {
                if (stateProperties.batterySoc < 10) {
                    return BatteryAlert;
                } else if (stateProperties.batterySoc < 30) {
                    return Battery20;
                } else if (stateProperties.batterySoc < 50) {
                    return Battery30;
                } else if (stateProperties.batterySoc < 60) {
                    return Battery50;
                } else if (stateProperties.batterySoc < 80) {
                    return Battery60;
                } else if (stateProperties.batterySoc < 90) {
                    return Battery80;
                } else if (stateProperties.batterySoc < 98) {
                    return Battery90;
                }
                return BatteryFull;
            }
        }
    }

    protected getSignalIcon(): string {
        if (this.props.device && this.props.device.getState()) {
            const stateProperties = this.props.device.getState().getStateProperties();
            if (stateProperties.modem === Modem.Wifi) {
                if (stateProperties.signalStrength > -25) {
                    return Wifi4;
                } else if (stateProperties.signalStrength > -50) {
                    return Wifi3;
                } else if (stateProperties.signalStrength > -75) {
                    return Wifi2;
                }
                return Wifi1;
            }
            if (stateProperties.modem === Modem.Cellular) {
                if (stateProperties.signalStrength > -25) {
                    return Cellular4;
                } else if (stateProperties.signalStrength > -50) {
                    return Cellular3;
                } else if (stateProperties.signalStrength > -75) {
                    return Cellular2;
                }
                return Cellular1;
            }
        }
    }

    protected getSignalStatusElement(): JSX.Element {
        const icon = this.getSignalIcon();
        if (icon) {
            return (
                <img
                    className="status-icon"
                    data-tip={this.getTooltip()}
                    src={icon}
                    alt="Signal"
                />
            );
        }
    }

    protected getPowerSupplyStatusElement(): JSX.Element {
        const icon = this.getPowerSupplyIcon();
        if (icon) {
            return (
                <img
                    className="status-icon"
                    data-tip={this.getTooltip()}
                    src={icon}
                    alt="Battery"
                />
            );
        }
    }

    protected getDeviceIcon(): JSX.Element {
        if (this.state.alarmTypes.length > 0) {
            return (
                <WarningIcon
                    height="100%"
                    style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}
                    htmlColor="#ff0000"
                />
            );
        } else {
            return <img src={IotItem} alt="device logo" />;
        }
    }

    protected getLinkRoute = (): string => {
        let baseUrl = this.props.location.pathname;
        if (this.props.match.params.id) {
            baseUrl = this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf("/"));
        }
        if (baseUrl.lastIndexOf("/") === baseUrl.indexOf("/")) {
            return baseUrl + "/" + this.props.device.getId();
        }
        return baseUrl + this.props.device.getId();
    }

    protected getTooltip = (): string => {
        let timestampAsString = "N/A";
        if (this.props.device && this.props.device.getState()) {
            const state = this.props.device.getState();
            if (state && state.getStateUpdatedTimestampMillis()) {
                const timestamp = state.getStateUpdatedTimestampMillis();
                timestampAsString = moment(timestamp).format(Utils.getDateTimeFormat(DateTimeFormatTarget.ShadowUpdate));
            }
        }
        return "Last update: " + timestampAsString;
    }

    protected handleDragStart = (event: React.DragEvent): void => {
        ReactTooltip.hide();
        event.dataTransfer.setData("device", this.props.device.getId());
    }

    public abstract render(): JSX.Element;
}
