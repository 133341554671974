/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, TextField } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { Organization } from "../../data/organization/Organization";
import ErrorNote from "../global/error-note";
import Loader from "../global/loader";

interface Props {
    organization: Organization;
}

interface State {
    loader: boolean;
    displayName: string;
    errorMsg: string;
}

export default class OrganizationDetails extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loader: false,
            displayName: this.props.organization.getDisplayName(),
            errorMsg: null,
        };
    }

    private displayNameChanged = (newInput: string): void => {
        this.setState({ displayName: newInput });
    }

    private handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        if (event.key === "Enter") {
            await this.submitNameChange();
        }
    }

    private handleSubmit = async (): Promise<void> => {
        await this.submitNameChange();
    }

    private async submitNameChange(): Promise<void> {
        this.setState({ loader: true });
        try {
            await this.props.organization.submitNameChange(this.state.displayName);
            this.setState({
                loader: false,
                errorMsg: null,
                displayName: this.props.organization.getDisplayName(),
            });
        } catch (error) {
            console.error("Failed to submit name change: " + JSON.stringify(error));
            this.setState({ loader: false });
        }
    }

    private cancelNameChange = (): void => {
        this.setState({ displayName: this.props.organization.getDisplayName() });
    }

    private handleCloseErrorNote = (): void => {
        this.setState({ errorMsg: null });
    }

    private renderLoader(): JSX.Element {
        if (this.state.loader) {
            return (
                <Loader />
            );
        }
    }

    private renderErrorNote(): JSX.Element {
        if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleCloseErrorNote}
                />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                <div className="organization-details" style={{ margin: "1rem 5rem", width: "Calc(100% - 10rem)" }}>
                    <TextField
                        fullWidth={true}
                        variant="outlined"
                        placeholder="Organization name"
                        className="organization-name"
                        value={this.state.displayName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void =>
                            this.displayNameChanged(event.currentTarget.value)}
                        onKeyPress={this.handleKeyPress}
                        inputProps={{
                            style: { fontSize: 30, padding: 10 },
                        }}
                    />
                    <div className="buttons-container" style={{ margin: "1rem 0 0 0" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="button left"
                            onClick={this.handleSubmit}
                            style={{ marginRight: "2rem" }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="button"
                            onClick={this.cancelNameChange}
                        >
                            Cancel
                        </Button>
                    </div>
                    {this.renderErrorNote()}
                    {this.renderLoader()}
                </div>
            </Fragment>
        );
    }
}
