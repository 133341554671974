/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import {ApolloQueryResult} from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import IData from "../clientSpecific/IData";
import {getLatestData} from "../graphql/queries";
import {DataObserver} from "../observer/DataObserver";
import {LatestDataObserver} from "../observer/LatestDataObserver";
import LatestData from "./LatestData";
import LatestDataSubscriptionManager from "./LatestDataSubscriptionManager";

interface AppSyncDynamoDBLatestDataResponse {
    getLatestData: {
        items: IData[];
        nextToken?: string;
    };
}

export default class AWSLatestData extends LatestData {
    private readonly deviceId: string;
    private data: IData;

    constructor(deviceId: string) {
        super();
        this.deviceId = deviceId;
    }

    public getId(): string {
        return this.deviceId;
    }

    public getLatestData(): IData {
        return this.data;
    }

    public async fetch(): Promise<void> {
        try {
            if (this.deviceId != null) {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DATA, clientType.TYPE_COGNITO);
                const latestDataResponse: ApolloQueryResult<AppSyncDynamoDBLatestDataResponse> = await appSyncClient.query({
                    query: gql(getLatestData),
                    variables: {
                        deviceId: this.deviceId,
                    },
                });
                if (latestDataResponse.data.getLatestData.items != null &&
                    latestDataResponse.data.getLatestData.items.length !== 0) {
                    this.data = latestDataResponse.data.getLatestData.items[0];
                }
            }
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
        }
    }

    public setData(data: IData): void {
        // Drop 'null' and 'undefined' values from the data.
        const cleanedData: IData = Object.fromEntries(Object.entries(data).filter(([_, v]: [string, string | number]) => v != null)) as IData;
        // Merge previous data with the new one as device sends two types of data (CAN1 and CAN2).
        this.data = {
            ...this.data,
            ...cleanedData,
        };
        this.notify();
    }

    public async addObserver(observer: DataObserver): Promise<void> {
        if (!this.data) {
            await this.fetch();
        }
        super.addObserver(observer);
        if (this.observers.length === 1) {
            LatestDataSubscriptionManager.getInstance().subscribeData(this);
        }
    }

    public removeObserver(observer: DataObserver): void {
        super.removeObserver(observer);
        if (this.observers.length === 0) {
            LatestDataSubscriptionManager.getInstance().removeSubscription(this);
        }
    }

    public notify(): void {
        const observers = this.observers as LatestDataObserver[];
        if (observers && observers.length !== 0) {
            observers.forEach((observer: LatestDataObserver) => {
                observer.onDataUpdate(this);
            });
        }
    }
}
