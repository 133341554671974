/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import DeviceSettings from "../clientSpecific/DeviceSettings";
import DeviceStateProperties from "../clientSpecific/DeviceStateProperties";
import { DataSet } from "../data/DataSet";
import { LatestData } from "../data/LatestData";
import { SessionSet } from "../data/SessionSet";
import EventSet from "../events/EventSet";
import BaseObservable from "./../observer/BaseObservable";
import DeviceState from "./DeviceState";

export abstract class Device extends BaseObservable {

    public static isValid(device: Device): boolean {
        return (device != null && device.getId() != null && device.getId().length > 0);
    }

    public abstract store(deviceSettings: DeviceSettings): Promise<void>;
    public abstract init(): Promise<void>;
    public abstract getId(): string;
    public abstract getState(): DeviceState;
    public abstract getSessions(startTimestamp: number, endTimestamp: number): Promise<SessionSet>;
    public abstract getData(startTimestamp: number, endTimestamp: number): Promise<DataSet>;
    public abstract getLatestData(): Promise<LatestData>;
    public abstract setState(current: DeviceStateProperties, next: DeviceStateProperties, stateUpdated: number): void;
    public abstract getEvents(): Promise<EventSet>;
}

export default Device;
