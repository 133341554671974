/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Device from "../../data/device/Device";
import DeviceSelector from "../../data/deviceSelector/DeviceSelector";
import { IGroup } from "../../data/group/IGroup";
import GroupIcon from "./../../assets/group_work-24px.svg";

interface Props {
    group: IGroup;
}

interface State {
}

export default class IoTListGroup extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    private getGroupIcon(): JSX.Element {
        return <img src={GroupIcon} alt="Group" />;
    }

    private getLinkRoute(): string {
        return this.props.group.groupId;
    }

    public render(): JSX.Element {
        return (
            <li className="iot-item">
                <NavLink
                    to={"/status/" + this.getLinkRoute()}
                    className="iot-item-link col-sm-8 col-xsm-8"
                    activeClassName="active"
                    isActive={(): boolean => this.props.group.groupId === DeviceSelector.getInstance().getCurrentGroupId()}
                    onClick={async (): Promise<Device[]> => {
                            DeviceSelector.getInstance().changeGroup(this.props.group.groupId);
                            return DeviceSelector.getInstance().devices = await this.props.group.getDevices();
                        }
                    }
                >
                    <div className="iot-icon col-sm-2 col-xsm-2">
                        {this.getGroupIcon()}
                    </div>
                    <div className="iot-name col-sm-10 col-xsm-10">
                        {this.props.group.groupId}
                    </div>
                </NavLink>
                <div className="iot-status-container col-sm-4 col-xsm-4" />
            </li>
        );
    }
}
