/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Checkbox, TableCell, TableRow } from "@material-ui/core";
import React, { ChangeEvent, Component } from "react";
import User from "../../data/organization/User";
import ErrorNote from "../global/error-note";

interface Props {
    user: User;
    isCurrentUser: boolean;
    deleteUser: (user: User) => void;
}

interface State {
    isAdmin: boolean;
    errorMsg: string;
}

export default class UserListItem extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isAdmin: this.props.user.isAdmin(),
            errorMsg: null,
        };
        this.changeAdminStatus = this.changeAdminStatus.bind(this);
    }

    private readonly changeAdminStatus = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        try {
            await this.props.user.setIsAdmin(event.currentTarget.checked);
            this.setState({ isAdmin: this.props.user.isAdmin() });
        } catch (error) {
            console.error("Admin change error: " + JSON.stringify(error));
            this.setState({errorMsg: "Failed to change admin status"});
        }
    }

    private handleCloseErrorNote = (): void => {
        this.setState({ errorMsg: null });
    }

    private deleteUser = (): void => {
        this.props.deleteUser(this.props.user);
    }

    private renderDeleteButton(): JSX.Element {
        if (!this.props.isCurrentUser) {
            return (
                <Button
                    onClick={this.deleteUser}
                    variant="contained"
                    color="primary"
                >
                    Delete
                </Button>
            );
        }
    }

    private renderErrorNote(): JSX.Element {
        if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleCloseErrorNote}
                />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <TableRow key={this.props.user.getUserName()}>
                <TableCell component="th" scope="row">
                    {this.props.user.getUserName()}
                </TableCell>
                <TableCell align="center">
                    {
                        <Checkbox
                            color="primary"
                            checked={this.state.isAdmin}
                            disabled={this.props.isCurrentUser}
                            onChange={(event: ChangeEvent<HTMLInputElement>): Promise<void> =>
                                this.changeAdminStatus(event)}
                        />
                    }
                </TableCell>
                <TableCell align="right">{this.renderDeleteButton()}</TableCell>
                {this.renderErrorNote()}
            </TableRow>
        );
    }
}
