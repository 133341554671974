/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminWrapper from "../components/admin/adminWrapper";
import EventsWrapper from "../components/events/events-wrapper";
import StatsWrapper from "../components/statistics/stats-wrapper";
import AuthWrapper from "../data/auth/authWrapper";
import { Device } from "../data/device/Device";
import DeviceSelectorObserver from "../data/deviceSelector/DeviceSelectorObserver";
import EventsRepository from "../data/events/EventsRepository";
import { IGroup } from "../data/group/IGroup";
import { DeviceStateObserver } from "../data/observer/DeviceStateObserver";
import OtaManager from "../data/ota/otaManager";
import { Tree } from "../data/tree/tree";
import { TreeBuilder } from "../data/tree/tree-builder";
import { IoTTreeItem } from "../data/tree/tree-item";
import { TreeJsonParser } from "../data/tree/tree-json-parser";
import Utils, { DeviceBrowserMode } from "../data/utils/utils";
import IoTHistoryWrapper from "./../components/history/history-wrapper";
import IoTList from "./../components/list/list";
import StatusWrapper from "./../components/status/status-wrapper";
import BackendFactory from "./../data/backend/BackendFactory";
import DeviceSelector from "./../data/deviceSelector/DeviceSelector";

interface Props {
}

interface State {
    groups: IGroup[];
    devices: Device[];
    legacyBrowserMode: boolean;
    tree: Tree;
}

export default class Body extends Component<Props, State> implements DeviceStateObserver, DeviceSelectorObserver {

    private deviceSelector: DeviceSelector = DeviceSelector.getInstance();

    constructor(props: Props) {
        super(props);
        this.state = {
            groups: null,
            devices: null,
            legacyBrowserMode: (Utils.getDeviceBrowserMode() === DeviceBrowserMode.Legacy),
            tree: null,
        };
    }

    public async componentDidMount(): Promise<void> {
        if (await AuthWrapper.isCurrentUserAuthenticated()) {
            DeviceSelector.getInstance().addObserver(this);
            await this.getGroups();
            await this.getDevices();
            await EventsRepository.getInstance().init();
            await OtaManager.getInstance().init();
        }
    }

    public componentWillUnmount(): void {
        this.cleanupDeviceStateObserver();
        DeviceSelector.getInstance().removeObserver(this);
        EventsRepository.getInstance().uninit();
        OtaManager.getInstance().uninit();
    }

    public onDeviceSetChanged(devices: Device[]): void {
        console.log("onDeviceSetChanged " + devices.length);
        this.cleanupDeviceStateObserver();
        if (devices && devices.length > 0) {
            devices.forEach((device: Device) => {
                DeviceSelector.getInstance().addDevice(device);
                device.addObserver(this);
            });
        }
        this.setState({ devices });
    }

    public onSelectedDeviceChanged(device: Device): void {
        console.log("onSelectedDeviceChanged");
    }

    private cleanupDeviceStateObserver(): void {
        if (this.state.devices && this.state.devices.length > 0) {
            this.state.devices.forEach((device: Device) => {
                device.removeObserver(this);
            });
        }
    }

    private async getGroups(): Promise<void> {
        if (Utils.getDeviceBrowserMode() !== DeviceBrowserMode.TreeFromJson) {
            const groups: IGroup[] = await BackendFactory.getBackend().getGroups(this.state.legacyBrowserMode);
            this.setState({ groups });
        }
    }

    private async getDevices(): Promise<void> {
        if (this.state.legacyBrowserMode) {
            if (this.state.groups && this.state.groups.length > 0) {
                const groupIndex = this.state.groups.findIndex((g: IGroup) => {
                    return g.groupId === process.env.REACT_APP_AWS_IOT_THING_GROUP;
                });
                if (groupIndex >= 0) {
                    DeviceSelector.getInstance().devices = [];
                    DeviceSelector.getInstance().changeGroup(process.env.REACT_APP_AWS_IOT_THING_GROUP);
                    const devices: Device[] = await this.state.groups[groupIndex].getDevices();
                    devices.forEach((device: Device) => {
                        DeviceSelector.getInstance().addDevice(device);
                        device.addObserver(this);
                    });
                    this.deviceSelector.devices = devices;
                    this.setState({ devices });
                }
            } else {
                this.setState({ devices: [] });
            }
        } else {
            if (Utils.getDeviceBrowserMode() === DeviceBrowserMode.TreeFromJson) {
                const tree = await TreeJsonParser.buildTreeFromJson();
                this.deviceSelector.devices = tree.deviceList;
                const groups = tree.treeItems.map((e: IoTTreeItem) => {
                    if (e.isGroup) {
                        return e.item as IGroup;
                    } else {
                        return null;
                    }
                }).filter((e: IGroup) => e !== null);
                this.setState({ devices: tree.deviceList, tree, groups });
            } else {
                const tree = await TreeBuilder.createTree(this.state.groups);
                this.deviceSelector.devices = tree.deviceList;
                this.setState({ devices: tree.deviceList, tree });
            }
        }
    }

    public onDeviceStateUpdate(device: Device): void {
        console.log(`onDeviceStateUpdate ${device.getId()}`);
        const deviceIndex = this.state.devices.indexOf(device);
        const devices = this.state.devices.slice();
        devices[deviceIndex] = device;
        this.deviceSelector.devices = devices;
        this.setState({ devices });
    }

    private triggerGroupsUpdate = (): void => {
        this.getGroups().then(() => {
            this.getDevices().then(() => this.forceUpdate());
        });
    }

    private renderHistoryView = (): JSX.Element => {
        return (
            <Fragment>
                <IoTList
                    devices={this.state.devices}
                    groups={this.state.groups}
                    tree={this.state.tree}
                />
                <IoTHistoryWrapper />
            </Fragment>
        );
    }

    private renderStatusView = (): JSX.Element => {
        return (
            <Fragment>
                <IoTList
                    devices={this.state.devices}
                    groups={this.state.groups}
                    tree={this.state.tree}
                />
                <StatusWrapper
                    devices={this.state.devices}
                />
            </Fragment>
        );
    }

    private renderStatisticsView = (): JSX.Element => {
        return (
            <Fragment>
                <IoTList
                    devices={this.state.devices}
                    groups={this.state.groups}
                    tree={this.state.tree}
                />
                <StatsWrapper />
            </Fragment>
        );
    }

    private renderEventsView = (): JSX.Element => {
        return (
            <Fragment>
                <IoTList
                    devices={this.state.devices}
                    groups={this.state.groups}
                    tree={this.state.tree}
                />
                <EventsWrapper
                    devices={this.state.devices}
                />
            </Fragment>
        );
    }

    private renderAdminView = (): JSX.Element => {
        return (
            <AdminWrapper
                devices={this.state.devices}
                groups={this.state.groups}
                legacyBrowserMode={false}
                tree={this.state.tree}
                triggerGroupsUpdate={(): void => this.triggerGroupsUpdate()}
            />
        );
    }

    private redirectToRoot = (): JSX.Element => {
        return <Redirect to="/status" />;
    }

    public render(): JSX.Element {
        return (
            <section className="body-container col-sm-12 col-xsm-12">
                <Switch>
                    <Route
                        exact={true}
                        path="/history/:id?"
                        render={this.renderHistoryView}
                    />
                    <Route
                        exact={true}
                        path="/status/:id?"
                        render={this.renderStatusView}
                    />
                    <Route
                        exact={true}
                        path="/statistics/:id?"
                        render={this.renderStatisticsView}
                    />
                    <Route
                        exact={true}
                        path="/events/:id?"
                        render={this.renderEventsView}
                    />
                    <Route
                        exact={true}
                        path="/admin"
                        render={this.renderAdminView}
                    />
                    <Route
                        exact={false}
                        path="/"
                        render={this.redirectToRoot}
                    />
                </Switch>
            </section >
        );
    }
}
