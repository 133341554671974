/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

const graphql = require("./../../clients/" + process.env.REACT_APP_CLIENT_FOLDER + "/graphql.yml");

export const clientChangeShadow = graphql.mutations.clientChangeShadow;
export const setUserAdminStatus = graphql.mutations.setUserAdminStatus;
export const updateOrganizationDetails = graphql.mutations.updateOrganizationDetails;
export const createOrganizationUser = graphql.mutations.createOrganizationUser;
export const deleteUser = graphql.mutations.deleteUser;
export const createDeviceGroup = graphql.mutations.createDeviceGroup;
export const deleteDeviceGroup = graphql.mutations.deleteDeviceGroup;
export const addDeviceToOrganization = graphql.mutations.addDeviceToOrganization;
export const addDeviceToGroup = graphql.mutations.addDeviceToGroup;
export const removeDeviceFromGroup = graphql.mutations.removeDeviceFromGroup;
export const deactivateEvent = graphql.mutations.deactivateEvent;

// OTA
export const triggerDeviceOtaUpdate = graphql.mutations.triggerDeviceOtaUpdate;
export const triggerGroupOtaUpdate = graphql.mutations.triggerGroupOtaUpdate;
export const cancelDeviceOtaUpdate = graphql.mutations.cancelDeviceOtaUpdate;
export const cancelGroupOtaUpdate = graphql.mutations.cancelGroupOtaUpdate;
