/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, TextField, withStyles } from "@material-ui/core";
import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../global/loader";

export interface Props {
    loader: boolean;
    username: string;
    password: string;
    userInput: (inputId: string, value: string) => void;
    logIn: () => void;
    passwordError: boolean;
    usernameError: boolean;
}

// tslint:disable-next-line
const ValidationTextField = withStyles({
    root: {
        "& input:valid + fieldset": {
            borderColor: "green",
            borderWidth: 2,
        },
        "& input:invalid + fieldset": {
            borderColor: "red",
            borderWidth: 2,
        },
        "& input:valid:focus + fieldset": {
            borderLeftWidth: 6,
            padding: "4px !important", // override inline-style
        },
    },
})(TextField);

export default class LoginInputs extends Component<Props> {

    private renderLoader = (): JSX.Element => {
        if (this.props.loader) {
            return <Loader />;
        }
    }

    private renderInputs(): JSX.Element {
        const isEnabled = this.props.username.length > 0 && this.props.password.length > 0;
        return (
            <Fragment>
                <div className="login-fields">
                    <ValidationTextField
                        className="text-field"
                        label="Email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        autoFocus={true}
                        required={true}
                        error={this.props.usernameError}
                        defaultValue={this.props.username}
                        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
                            void => this.props.userInput("username", event.currentTarget.value)}
                        onKeyPress={this.handleKeyPress}
                        fullWidth={true}
                    />
                    <ValidationTextField
                        className="text-field"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                        required={true}
                        error={this.props.passwordError}
                        defaultValue={this.props.password}
                        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
                            void => this.props.userInput("password", event.currentTarget.value)}
                        onKeyPress={this.handleKeyPress}
                        fullWidth={true}
                    />
                </div>
                <div className="login-buttons">
                    <Button
                        disabled={!isEnabled}
                        variant="contained"
                        color="primary"
                        onClick={(): void => this.props.logIn()}
                        className="button"
                    >
                        Log In
                    </Button>
                </div>
            </Fragment>
        );
    }

    private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Enter") {
            this.props.logIn();
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                <div className="login-header" />
                {this.renderLoader()}
                {this.renderInputs()}
            </Fragment>
        );
    }
}
