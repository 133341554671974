/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import IObservable from "./IObservable";
import { IObserver } from "./IObserver";

export default class BaseObservable implements IObservable {
    protected observers: IObserver[] = [];

    public addObserver(observer: IObserver): void {
        // TODO: Should not add duplicate observers
        console.log(`Observer added (${this.constructor.name})`);
        this.observers.push(observer);
    }

    public removeObserver(observer: IObserver): void {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex !== -1) {
            console.log(`Observer removed (${this.constructor.name})`);
            this.observers.splice(observerIndex, 1);
        }
    }

    public notify(): void {
        // Base class does not implement anything related to notify
    }
}
