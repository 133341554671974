/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import AWSBackend, {IoTThingGroupAttribute} from "../backend/AWSBackend";
import AWSThing from "../device/AWSThing";
import Device from "../device/Device";
import AWSThingGroup from "../group/AWSThingGroup";
import IGroup from "../group/IGroup";
import Utils from "../utils/utils";
import { Tree } from "./tree";
import { IoTTreeItem } from "./tree-item";

interface Attribute {
    key: string;
    value: string;
}

interface DeviceInfo {
    arn: string;
    name: string;
    type: string;
    attr?: Attribute[];
}

interface GroupInfo {
    name: string;
    arn: string;
    attr?: Attribute[];
}

// Backend uses shortened key names in order to limit the size of the transferred JSON document
// i as in info (Device- or group-)
// c as in children
// g as in group (1 if the TreeItem is a group, 0 if it is a device)
interface TreeJsonItem {
    i: DeviceInfo | GroupInfo;
    c?: TreeJsonItem[];
    g?: number;
}

export class TreeJsonParser {

    public static async buildTreeFromJson(): Promise<Tree> {
        console.log("Creating tree");
        const startTime = Date.now();
        const treeJsonString = await AWSBackend.getInstance().getDeviceTree();
        console.log(`Tree JSON retrieved in ${Date.now() - startTime} ms`);
        const tree = await TreeJsonParser.buildRootLevelTree(JSON.parse(treeJsonString));
        console.log(`Tree done in ${Date.now() - startTime} ms`);
        return tree;
    }

    private static isGroup(item: TreeJsonItem): boolean {
        return item.g === 1;
    }

    private static async buildRootLevelTree(rootLevelJsonItems: TreeJsonItem[]): Promise<Tree> {
        const iotTreeItems: IoTTreeItem[] = [];
        const leafItems: IoTTreeItem[] = [];
        const devices: Device[] = [];
        if (rootLevelJsonItems && rootLevelJsonItems.length > 0) {
            let drawNodeLevel: boolean = true;
            if (rootLevelJsonItems.length === 1) {
                drawNodeLevel = false;
            }

            for (const item of rootLevelJsonItems) {
                if (TreeJsonParser.isGroup(item)) {
                    const groupItem = item.i as GroupInfo;
                    const attributes: IoTThingGroupAttribute[] = this.convertServerAttributeToGroupAttribute(groupItem.attr);
                    const groupFolder: IoTTreeItem = {
                        id: groupItem.name,
                        isGroup: true,
                        item: new AWSThingGroup(groupItem.name, attributes),
                        parent: null,
                        childNodesAndFolders: [],
                        drawLevel: drawNodeLevel,
                        isFilterMatch: false,
                        hasAlarms: false,
                    };

                    await this.buildTreeFolder(item.c, groupFolder, leafItems);
                    iotTreeItems.push(groupFolder);
                }
            }
        }

        leafItems.forEach((leaf: IoTTreeItem) => {
            devices.push(leaf.item as Device);
        });

        return {
            treeItems: iotTreeItems,
            leafs: leafItems,
            deviceList: devices,
        };
    }

    private static convertServerAttributeToGroupAttribute(serverAttributes: Attribute[]): IoTThingGroupAttribute[] {
        if (!serverAttributes) {
            return [];
        }
        const attributes: IoTThingGroupAttribute[] = [];
        serverAttributes.forEach((attr: Attribute) => {
            attributes.push({
                key: attr.key,
                value: attr.value,
            });
        });
        return attributes;
    }

    private static async buildTreeFolder(treeJsonItems: TreeJsonItem[], groupFolder: IoTTreeItem, leafItems: IoTTreeItem[]): Promise<void> {
        let childGroups: IGroup[] = [];
        if (treeJsonItems) {
            childGroups = treeJsonItems.map((item: TreeJsonItem) => {
                if (TreeJsonParser.isGroup(item)) {
                    const groupItem = item.i as GroupInfo;
                    const attributes = this.convertServerAttributeToGroupAttribute(groupItem.attr);
                    return new AWSThingGroup(groupItem.name, attributes);
                } else {
                    return null;
                }
            }).filter((e: IGroup) => e !== null);

            childGroups = childGroups.sort((a: IGroup, b: IGroup) => {
                return Utils.compareGroupsForSorting(a, b);
            });
        }

        const awsGroup = groupFolder.item as AWSThingGroup;
        awsGroup.setGroups(childGroups);
        for (const childGroup of childGroups) {
            const childNode: IoTTreeItem = {
                id: childGroup.groupId,
                isGroup: true,
                item: childGroup,
                parent: groupFolder,
                childNodesAndFolders: [],
                drawLevel: true,
                isFilterMatch: false,
                hasAlarms: false,
            };

            groupFolder.childNodesAndFolders.push(childNode);

            const match = treeJsonItems.find((item: TreeJsonItem) => {
                if (TreeJsonParser.isGroup(item)) {
                    const groupItem = item.i as GroupInfo;
                    if (groupItem.name === childGroup.groupId) {
                        return item;
                    }
                }
                return null;
            });

            await this.buildTreeFolder(match ? match.c : [], childNode, leafItems);
        }

        let devices: Device[] = [];
        if (treeJsonItems) {
            devices = treeJsonItems.map((item: TreeJsonItem) => {
                if (!TreeJsonParser.isGroup(item)) {
                    const deviceItem = item.i as DeviceInfo;
                    return new AWSThing(deviceItem.name);
                } else {
                    return null;
                }
            }).filter((e: Device) => e !== null);
        }

        await Promise.all(devices.map(async (device: Device) => {
            await device.init();
        }));

        devices = devices.sort((a: Device, b: Device) => {
            return Utils.compareDevicesForSorting(a, b);
        });

        awsGroup.setDevices(devices);
        devices.forEach((iotDevice: Device) => {
            const leafItem: IoTTreeItem = {
                id: iotDevice.getId(),
                isGroup: false,
                item: iotDevice,
                parent: groupFolder,
                childNodesAndFolders: null,
                drawLevel: true,
                isFilterMatch: false,
                hasAlarms: false,
            };
            groupFolder.childNodesAndFolders.push(leafItem);
            leafItems.push(leafItem);
        });
    }
}
