/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { ChangeEvent, Component } from "react";

type Selection = string | number | boolean;

interface Props {
    label?: string;
    disabled?: boolean;
    selectionList: Selection[];
    defaultSelection?: string;
    onSelection: (selection: number) => void;
}

interface State {
    selected: string;
}

export default class DropdownSelection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selected: null,
        };
    }

    private getLabel(): JSX.Element {
        if (this.props.label) {
            return (
                <InputLabel shrink={true}>
                    {this.props.label}
                </InputLabel>
            );
        }
    }

    private getValue(): string {
        if (this.state.selected) {
            return this.state.selected;
        }
        return "";
    }

    private getSelectionList(): JSX.Element[] {
        if (this.props.selectionList && this.props.selectionList.length > 0) {
            const selectionList = this.props.selectionList.map((selection: Selection) => {
                return (
                    <MenuItem key={selection.toString()} value={selection.toString()}>{selection}</MenuItem>
                );
            });
            return selectionList;
        }
    }

    private getEmptySelection(): JSX.Element {
        if (this.props.defaultSelection) {
            return (
                <MenuItem value="">
                    {this.props.defaultSelection}
                </MenuItem>
            );
        }
    }

    private onSelection = (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
        let index: number = null;
        const value = event.target.value.toString() || null;
        if (value !== null) {
            index = this.props.selectionList.indexOf(value);
        }
        this.setState({ selected: value });
        this.props.onSelection(index);
    }

    public render(): JSX.Element {
        return (
            <div className="dropdown-selection">
                <FormControl className="dropdown-selection-input">
                    {this.getLabel()}
                    <Select
                        value={this.getValue()}
                        onChange={this.onSelection}
                        disabled={this.props.disabled}
                        displayEmpty={true}
                    >
                        {this.getEmptySelection()}
                        {this.getSelectionList()}
                    </Select>
                </FormControl>
            </div>
        );
    }
}
