/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React from "react";

interface Props {
    rotationSpeedInSeconds?: number;
    size?: string;
    circleHeight?: string;
    spinnerColor?: string;
    circleColor?: string;
    topBottomPadding?: string;
    leftRightPadding?: string;
}

export default function Loader(props: Props): JSX.Element {
    const { rotationSpeedInSeconds,
        size,
        spinnerColor,
        circleColor,
        circleHeight,
        topBottomPadding,
        leftRightPadding,
    } = props;
    const rotationSpeed = rotationSpeedInSeconds || "1";
    const spinnerSize = size || "50px";
    const colorS = spinnerColor || "#3498db";
    const colorC = circleColor || "#f3f3f3";
    const heightC = circleHeight || "0.5rem";
    const paddingTopBottom = topBottomPadding || "2rem";
    const paddingLeftRight = leftRightPadding || "0";
    const containerStyles = {
        padding: paddingTopBottom + " " + paddingLeftRight,
    };
    const loaderStyles = {
        animation: "spin " + rotationSpeed + "s linear infinite",
        border: heightC + " solid " + colorC,
        borderRadius: "50%",
        borderTop: heightC + " solid " + colorS,
        height: spinnerSize,
        margin: "0 auto",
        width: spinnerSize,
    };
    return <div style={containerStyles}><div style={loaderStyles} /></div>;
}
