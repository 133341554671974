/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import {ApolloQueryResult} from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import IData from "../clientSpecific/IData";
import {getDeviceData} from "../graphql/queries";
import {DataObserver} from "../observer/DataObserver";
import DataSet from "./DataSet";
import DataSubscriptionManager from "./DataSubscriptionManager";

interface AppSyncDynamoDBDataResponse {
    getDeviceData: {
        items: IData[];
        nextToken?: string;
    };
}

export default class AWSDataSet extends DataSet {
    private readonly deviceId: string;
    private readonly startTimestamp: number;
    private readonly endTimestamp: number;
    private data: IData[];

    constructor(deviceId: string, startTimestamp: number, endTimestamp: number) {
        super();
        this.deviceId = deviceId;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
    }

    public async fetch(): Promise<void> {
        let nextToken: string = null;
        let data: IData[] = [];
        try {
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DATA, clientType.TYPE_COGNITO);
                const dataResponse: ApolloQueryResult<AppSyncDynamoDBDataResponse> = await appSyncClient.query({
                    query: gql(getDeviceData),
                    variables: {
                        deviceId: this.deviceId,
                        startTimestamp: this.startTimestamp,
                        endTimestamp: this.endTimestamp,
                        nextToken,
                    },
                });
                nextToken = dataResponse.data.getDeviceData.nextToken || null;
                data = data.concat(dataResponse.data.getDeviceData.items);
            } while (nextToken);
            this.data = data;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
        }
    }

    public getId(): string {
        return this.deviceId;
    }

    public getData(): IData[] {
        return this.data;
    }

    public getStartTimestamp(): number {
        return this.startTimestamp;
    }

    public getEndTimestamp(): number {
        return this.endTimestamp;
    }

    public setData(data: IData): void {
        this.data.push(data);
        this.notify();
    }

    public async addObserver(observer: DataObserver): Promise<void> {
        if (!this.data) {
            await this.fetch();
        }
        super.addObserver(observer);
        if (this.observers.length === 1) {
            DataSubscriptionManager.getInstance().subscribeData(this);
        }
    }

    public removeObserver(observer: DataObserver): void {
        super.removeObserver(observer);
        if (this.observers.length === 0) {
            DataSubscriptionManager.getInstance().removeSubscription(this);
        }
    }

    public notify(): void {
        const observers = this.observers as DataObserver[];
        if (observers && observers.length !== 0) {
            observers.forEach((observer: DataObserver) => {
                observer.onDataUpdate(this);
            });
        }
    }
}
