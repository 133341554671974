/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ICredentials } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import {AuthOptions} from "aws-appsync/lib/link/auth-link";
import awsconfig from "../../aws-config";

export enum Service {
    USERS = 0,
    DATA = 1,
    DEVICE = 2,
    EVENTS = 3,
    STATS = 4,
}

export enum clientType {
    TYPE_COGNITO = 0,
    TYPE_IAM = 1,
}

export default class AppSyncClientProvider implements AppSyncClientProvider {

    public getAppSyncClient(service: Service, type: clientType): AWSAppSyncClient<any> {
        let authOptions: AuthOptions = null;
        if (type === clientType.TYPE_IAM) {
            authOptions = {
                credentials: (): Promise<ICredentials> => Auth.currentCredentials(),
                type: AUTH_TYPE.AWS_IAM,
                };
        } else if (type === clientType.TYPE_COGNITO) {
            authOptions = {
                jwtToken: async (): Promise<string> => (await Auth.currentSession()).getAccessToken().getJwtToken(),
                type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            };
        } else {
            throw new Error("Unknown AppSync authentication requested!");
        }
        return new AWSAppSyncClient({
            auth: authOptions,
            region: awsconfig.aws_appsync_region,
            url: this.getEndpoint(service),
            disableOffline: true,
        });
    }

    private getEndpoint(service: Service): string {
        switch (service) {
            case Service.USERS:
                return awsconfig.aws_appsync_graphqlEndpoint_users;
            case Service.DATA:
                return awsconfig.aws_appsync_graphqlEndpoint_data;
            case Service.DEVICE:
                return awsconfig.aws_appsync_graphqlEndpoint_device;
            case Service.EVENTS:
                return awsconfig.aws_appsync_graphqlEndpoint_events;
            case Service.STATS:
                return awsconfig.aws_appsync_graphqlEndpoint_stats;
            default:
                console.error("Unknown AppSync service requested");
        }
    }
}
