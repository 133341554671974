/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { IconButton, Tab, Table, TableCell, TableHead, TableRow, Tabs } from "@material-ui/core";
import List from "@material-ui/core/List";
import AddIcon from "@material-ui/icons/Add";
import React, { ChangeEvent, Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { RouteComponentPropsParams } from "../../@types/routerprops";
import BackendFactory from "../../data/backend/BackendFactory";
import { Device } from "../../data/device/Device";
import { IGroup } from "../../data/group/IGroup";
import { Tree } from "../../data/tree/tree";
import SearchBar from "../controls/search-bar";
import ConfirmationDialog from "../global/confirmation-dialog";
import ErrorNote from "../global/error-note";
import SearchIcon from "./../../assets/baseline-search-24px.svg";
import ClientProperties from "./../../data/clientSpecific/ClientProperties";
import DeviceSelector from "./../../data/deviceSelector/DeviceSelector";
import DeviceSelectorObserver from "./../../data/deviceSelector/DeviceSelectorObserver";
import Utils, { DeviceBrowserMode } from "./../../data/utils/utils";
import Loader from "./../global/loader";
import IoTListGroup from "./../list/list-group";
import IoTListItem from "./../list/list-item";
import ListTab from "./../list/list-tab";
import IoTDeviceTree from "./../list/tree";
import AddDevicePopup from "./add-device-popup";
import AddGroupPopup from "./add-group-popup";

interface Props extends RouteComponentProps<RouteComponentPropsParams> {
    devices: Device[];
    groups: IGroup[];
    tree: Tree;
    triggerGroupsUpdate: () => void;
}

interface State {
    searchFilter: string;
    drawerOpen: boolean;
    selectedDevice: Device;
    tabValue: number;
    showAddGroupPopup: boolean;
    parentGroup: IGroup;
    showRemoveGroupPopup: boolean;
    removeGroup: IGroup;
    showAddDevicePopup: boolean;
    loading: boolean;
    errorMsg: string;
}

class DeviceList extends Component<Props, State> implements DeviceSelectorObserver {

    private deviceSelector: DeviceSelector = DeviceSelector.getInstance();

    constructor(props: Props) {
        super(props);
        this.state = {
            searchFilter: "",
            drawerOpen: false,
            selectedDevice: null,
            tabValue: 0,
            showAddGroupPopup: false,
            parentGroup: null,
            showRemoveGroupPopup: false,
            removeGroup: null,
            showAddDevicePopup: false,
            loading: false,
            errorMsg: null,
        };
    }

    public onSelectedDeviceChanged(device: Device): void {
        this.setState({ selectedDevice: device });
    }

    public onDeviceSetChanged(devices: Device[]): void {
        console.log("onDeviceSetChanged");
    }

    public componentDidMount(): void {
        this.deviceSelector.addObserver(this);
        if (!this.props.match.params.id) {
            this.toggleDrawer();
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.match.params.id !== this.props.match.params.id
            || Utils.isEmpty(this.props.match.params)) {
            this.deviceSelector.setCurrentDevice(this.props.match.params.id);
        }

        if (this.props.match.params.id && !this.state.selectedDevice) {
            this.deviceSelector.setCurrentDevice(this.props.match.params.id);
        }
    }

    public componentWillUnmount(): void {
        this.deviceSelector.removeObserver(this);
    }

    private getDisplayName = (): string => {
        if (this.state.selectedDevice) {
            return Utils.getDisplayName(this.state.selectedDevice);
        }
    }

    private getIoTListItems(): JSX.Element | JSX.Element[] {
        if (this.props.devices && this.props.devices.length >= 0) {
            let filteredDevices: Device[] = this.props.devices.slice();
            if (this.state.searchFilter !== "") {
                filteredDevices = this.props.devices.filter((device: Device) => {
                    return this.isFilterMatch(Utils.getDisplayName(device));
                });
            }

            filteredDevices = filteredDevices.sort((a: Device, b: Device) => {
                return Utils.compareDevicesForSorting(a, b);
            });

            const iotItemsList = filteredDevices.map((device: Device) => {
                if (Device.isValid(device)) {
                    return (
                        <IoTListItem
                            key={device.getId()}
                            device={device}
                            deviceSelected={(deviceId: string): void => { return; }}
                            adminMode={true}
                        />
                    );
                }
                return undefined;
            });
            if (iotItemsList.length === 0) {
                return <li className="iot-item error">No things found!</li>;
            }
            return iotItemsList;
        }
    }

    private getGroupsList(): JSX.Element | JSX.Element[] {
        if (this.props.groups && this.props.groups.length >= 0) {
            let groups: IGroup[] = this.props.groups.slice();
            if (this.state.searchFilter !== "") {
                groups = this.props.groups.filter((group: IGroup) => {
                    return this.isFilterMatch(group.groupId);
                });
            }

            groups = groups.sort((a: IGroup, b: IGroup) => {
                return Utils.compareGroupsForSorting(a, b);
            });

            const iotGroupsList = groups.map((group: IGroup) => {
                return (
                    <IoTListGroup
                        key={group.groupId}
                        group={group}
                    />
                );
            });
            if (iotGroupsList.length === 0) {
                return <li className="iot-item error">No groups found!</li>;
            }
            return iotGroupsList;
        }
    }

    private isFilterMatch(value: string): boolean {
        return (value.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1);
    }

    private changeFilter = (event: React.FormEvent<HTMLInputElement>): void => {
        if (event !== null) {
            this.setState({ searchFilter: event.currentTarget.value });
        }
    }

    private handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
        this.setState({ tabValue: newValue });
    }

    private onSearchTriggered(searchString: string): void {
        console.log("onSearchTriggered: " + searchString);
        this.setState({ searchFilter: searchString });
    }

    private handleAddChildGroup = (parent: IGroup): void => {
        this.setState({
            parentGroup: parent,
            showAddGroupPopup: true,
        });
    }

    private removeGroup = (group: IGroup): void => {
        this.setState({
            removeGroup: group,
            showRemoveGroupPopup: true,
        });
    }

    private handleClosePopup = (): void => {
        // TODO: If popups needed in sequence, then split this
        this.setState({
            showAddGroupPopup: false,
            showRemoveGroupPopup: false,
            showAddDevicePopup: false,
            parentGroup: null,
            removeGroup: null,
            errorMsg: null,
        });
    }

    private handleRemoveGroup = async (): Promise<void> => {
        this.handleClosePopup();
        this.setState({ loading: true });

        try {
            await BackendFactory.getBackend().removeGroup(this.state.removeGroup);
            this.props.triggerGroupsUpdate();
            this.setState({loading: false});
        } catch (error) {
            console.error("Failed to remove device group: " + JSON.stringify(error));
            this.setState({errorMsg: "Group removal failed"});
        }
    }

    private handleAddDeviceIconClick = (): void => {
        this.setState({
            parentGroup: this.props.tree.treeItems[0].item as IGroup,
            showAddDevicePopup: true,
        });
    }

    private toggleDrawer = (): void => {
        this.setState((prevState: State) => ({
            drawerOpen: !prevState.drawerOpen,
        }));
    }

    private renderLegacyBrowserMode(): JSX.Element {
        return (
            <Fragment>
                <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleChange}
                    variant="fullWidth"
                    className="list-tabs"
                >
                    <Tab label={ClientProperties.DEVICES_TAB_TITLE} />
                    <Tab label={ClientProperties.GROUPS_TAB_TITLE} />
                </Tabs>
                <List>
                    <ListTab value={this.state.tabValue} index={0}>
                        {this.renderDeviceList()}
                    </ListTab>
                    <ListTab value={this.state.tabValue} index={1}>
                        {this.renderGroupList()}
                    </ListTab>
                </List>
                <div className="iot-drawer-list-footer col-sm-12 col-xsm-12">
                    <div className="search-bar col-sm-10 col-xsm-10">
                        <div className="search-icon-container">
                            <img className="search-icon" alt="" src={SearchIcon} />
                        </div>
                        <input
                            className="search-input"
                            type="text"
                            placeholder="Search"
                            value={this.state.searchFilter}
                            onChange={this.changeFilter}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }

    private renderGroupList = (): JSX.Element | JSX.Element[] => {
        if (!this.props.groups) {
            return <Loader />;
        }
        return this.getGroupsList();
    }

    private renderDeviceList = (): JSX.Element | JSX.Element[] => {
        if (!this.props.devices) {
            return <Loader />;
        }
        return this.getIoTListItems();
    }

    private renderAddDevicePopup(): JSX.Element {
        return (
            <AddDevicePopup
                title="Add new device"
                label="Device ID"
                parentGroup={this.state.parentGroup}
                closePopup={(): void => this.handleClosePopup()}
                triggerGroupsUpdate={(): void => this.props.triggerGroupsUpdate()}
            />
        );
    }

    private renderAddGroupPopup(): JSX.Element {
        return (
            <AddGroupPopup
                title="Add new group"
                label="Group name"
                parentGroup={this.state.parentGroup}
                closePopup={(): void => this.handleClosePopup()}
                triggerGroupsUpdate={(): void => this.props.triggerGroupsUpdate()}
            />
        );
    }

    private renderRemoveGroupPopup(): JSX.Element {
        if (this.state.removeGroup) {
            return (
                <ConfirmationDialog
                    title="Delete"
                    message={this.state.removeGroup.getLabel()}
                    handleConfirm={this.handleRemoveGroup}
                    handleCancel={this.handleClosePopup}
                />
            );
        }
    }

    private renderPopups(): JSX.Element {
        if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleClosePopup}
                />
            );
        } else if (this.state.showRemoveGroupPopup) {
            return this.renderRemoveGroupPopup();
        } else if (this.state.showAddGroupPopup) {
            return this.renderAddGroupPopup();
        } else if (this.state.showAddDevicePopup) {
            return this.renderAddDevicePopup();
        }
    }

    private renderTreeBrowserMode(): JSX.Element {
        return (
            <Fragment>
                <SearchBar
                    searchString={this.state.searchFilter}
                    onSearchTriggered={(searchString: string): void => this.onSearchTriggered(searchString)}
                    className="admin-search-bar-container"
                />
                <IoTDeviceTree
                    tree={this.props.tree}
                    searchFilter={this.state.searchFilter}
                    adminMode={true}
                    addGroup={(parent: IGroup): void => this.handleAddChildGroup(parent)}
                    removeGroup={this.removeGroup}
                />
            </Fragment>
        );
    }

    private renderDeviceBrowserMode(deviceBrowserMode: DeviceBrowserMode): JSX.Element {
        if (this.state.loading) {
            return <Loader />;
        } else if (deviceBrowserMode !== DeviceBrowserMode.Legacy) {
            return this.renderTreeBrowserMode();
        } else {
            return this.renderLegacyBrowserMode();
        }
    }

    private renderAddDeviceButton(): JSX.Element {
        if (this.props.tree && !this.state.loading) {
            return (
                <IconButton
                    title="Add device"
                    onClick={this.handleAddDeviceIconClick}
                    style={{ backgroundColor: "#e6e6e6", marginLeft: "3rem", marginTop: "1rem" }}
                    size="small"
                >
                    <AddIcon />
                </IconButton>
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                {this.renderPopups()}
                <div className="admin-device-list-container">
                    <span>{this.getDisplayName()}</span>
                    <div className="admin-device-list">
                        <Table className="org-groups-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Groups / {ClientProperties.DEVICE_DRAWER_TITLE}</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        {this.renderDeviceBrowserMode(Utils.getDeviceBrowserMode())}
                        {this.renderAddDeviceButton()}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(DeviceList);
