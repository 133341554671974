/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import IEvent from "../clientSpecific/IEvent";
import { deactivateEvent } from "../graphql/mutations";
import { getDeviceEvents } from "../graphql/queries";
import { EventObserver } from "../observer/EventObserver";
import EventSet from "./EventSet";
import EventsSubscriptionManager from "./EventsSubscriptionManager";

interface AppSyncDynamoDBDataResponse {
    getDeviceEvents: {
        items: IEvent[];
        nextToken?: string;
    };
}

export default class AWSEventSet extends EventSet {

    private readonly deviceId: string;
    private events: IEvent[];

    constructor(deviceId: string) {
        super();
        this.deviceId = deviceId;
        this.events = [];
    }

    public async fetch(): Promise<void> {
        let nextToken: string = null;
        let events: IEvent[] = [];
        try {
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.EVENTS, clientType.TYPE_COGNITO);
                const deviceEventsResponse: ApolloQueryResult<AppSyncDynamoDBDataResponse> = await appSyncClient.query({
                    query: gql(getDeviceEvents),
                    variables: {
                        deviceId: this.deviceId,
                        nextToken,
                    },
                });
                nextToken = deviceEventsResponse.data.getDeviceEvents.nextToken || null;
                events = events.concat(deviceEventsResponse.data.getDeviceEvents.items);
            } while (nextToken);
            this.events = events;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
            this.events = [];
        }
    }

    public getId(): string {
        return this.deviceId;
    }

    public getEvents(): IEvent[] {
        return this.events;
    }

    public setEvent(event: IEvent): void {
        const matchIndex = this.events.findIndex((e: IEvent) => {
            console.log(`event: ${event}, e: ${e}`);
            if (event) {
                return (e.deviceId === event.deviceId &&
                    e.eventId === event.eventId &&
                    e.timestamp === event.timestamp);
            }
            return false;
        });

        if (matchIndex >= 0) {
            this.events[matchIndex] = event;
        } else {
            this.events.push(event);
        }
        this.notify();
    }

    public async deactivateEvent(event: IEvent): Promise<void> {
        const eventPayload = {
            deviceId: event.deviceId,
            timestamp: event.timestamp,
        };
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.EVENTS, clientType.TYPE_COGNITO);
            await appSyncClient.mutate({
                mutation: gql(deactivateEvent),
                variables: {
                    payload: eventPayload,
                },
            });
        } catch (error) {
            console.log(error);
        }
    }

    public async addObserver(observer: EventObserver): Promise<void> {
        if (!this.events) {
            await this.fetch();
        }
        super.addObserver(observer);
        if (this.observers.length === 1) {
            EventsSubscriptionManager.getInstance().subscribeEvents(this);
        }
    }

    public removeObserver(observer: EventObserver): void {
        super.removeObserver(observer);
        if (this.observers.length === 0) {
            EventsSubscriptionManager.getInstance().removeSubscription(this);
        }
    }

    public notify(): void {
        const observers = this.observers as EventObserver[];
        if (observers && observers.length !== 0) {
            observers.forEach((observer: EventObserver) => {
                observer.onEventSet(this);
            });
        }
    }
}
