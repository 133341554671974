/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Checkbox } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import AuthWrapper from "../../data/auth/authWrapper";
import AWSBackend from "../../data/backend/AWSBackend";
import { SessionSet } from "../../data/data/SessionSet";
import { Device } from "../../data/device/Device";
import ErrorNote from "../global/error-note";
import Loader from "../global/loader";
import DeviceSelector from "./../../data/deviceSelector/DeviceSelector";
import DeviceSelectorObserver from "./../../data/deviceSelector/DeviceSelectorObserver";
import TimeWrapper from "./../../data/time/TimeWrapper";
import TimeRangePicker from "./../controls/time-range-picker";
import DropdownSelection from "./../global/dropdown-selection";

// tslint:disable-next-line:variable-name
const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

interface QsParams {
    url: string;
    container: HTMLElement;
    width: string;
    height: string;
    parameters: {
        selectedDevice?: string;
        startTime?: number;
        endTime?: number,
    };
}

type QuickSightDashboard = any;

interface Props {
}

interface State {
    startTimestamp: number;
    endTimestamp: number;
    selection: number;
    selectedDevice: Device;
    sessionSet: SessionSet;
    isLoading: boolean;
    onlySelectedDevice: boolean;
    errorMsg: string;
}

const ALL_DEVICES_KEY = "[ALL]";

export default class StatsWrapper extends Component<Props, State> implements DeviceSelectorObserver {

    private deviceSelector: DeviceSelector = DeviceSelector.getInstance();

    private dashboard: QuickSightDashboard = null;

    constructor(props: Props) {
        super(props);
        const endTimestamp = Date.now();
        const startTimestamp = TimeWrapper.getTimestampXUnitsAgo(endTimestamp, 1, "month");
        this.state = {
            startTimestamp,
            endTimestamp,
            selection: null,
            selectedDevice: null,
            sessionSet: null,
            isLoading: true,
            onlySelectedDevice: true,
            errorMsg: null,
        };
    }

    public async componentDidMount(): Promise<void> {
        this.deviceSelector.addObserver(this);
        await this.createDashboard();
    }

    private async createDashboard(): Promise<void> {
        const openIdToken = await AuthWrapper.getOpenIdToken();
        const dashboardId = process.env.REACT_APP_DEFAULT_QUICKSIGHT_DASHBOARD_ID;
        const embedUrl = await AWSBackend.getInstance().getQsEmbedUrl(openIdToken, dashboardId);
        const containerDiv = document.getElementById("dashboardContainer");
        const params: QsParams = {
                url: embedUrl,
                container: containerDiv,
                width: "100%",
                height: "98%",
                parameters: {
                    selectedDevice: this.state.selectedDevice ? this.state.selectedDevice.getId() : ALL_DEVICES_KEY,
                    startTime: this.state.startTimestamp,
                    endTime: this.state.endTimestamp,
                },
            };
        try {
            this.dashboard = QuickSightEmbedding.embedDashboard(params);
            this.dashboard.on("error", this.onDashboardError);
            this.dashboard.on("load", this.onDashboardLoad);
            this.setState({isLoading: false});
        } catch (e) {
            console.error(e);
            this.setState({isLoading: false, errorMsg: e.toString()});
        }
    }

    public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
        const deviceChanged = this.state.selectedDevice != null &&
            (prevState.selectedDevice == null || prevState.selectedDevice.getId() !==
                this.state.selectedDevice.getId());
        if (deviceChanged && this.state.onlySelectedDevice) {
            this.updateDashboardDevice(this.state.selectedDevice.getId());
        }
    }

    public componentWillUnmount(): void {
        this.deviceSelector.removeObserver(this);
    }

    public onSelectedDeviceChanged(device: Device): void {
        let newDevice = null;
        if (device) {
            newDevice = Object.create(device);
        }
        this.setState({ selectedDevice: newDevice });
    }

    public onDeviceSetChanged(devices: Device[]): void {
        console.log("onDeviceSetChanged");
    }

    private onDashboardLoad = (payload: any): void => {
        console.log("onDashboardLoad", payload);
    }

    private onDashboardError = (payload: any): void => {
        console.error("onDashboardError", payload);
        this.setState({errorMsg: "Dashboard loading failed"});
    }

    private onOnlySelectedDeviceChange = (event: React.FormEvent<HTMLInputElement>): void => {
        this.setState({ onlySelectedDevice: event.currentTarget.checked });
        let id = ALL_DEVICES_KEY;
        if (event.currentTarget.checked && this.state.selectedDevice) {
            id = this.state.selectedDevice.getId();
        }
        this.updateDashboardDevice(id);
    }

    private updateDashboardTimestamps(start: number, end: number): void {
        if (this.dashboard) {
            this.dashboard.setParameters({
                startTime: start,
                endTime: end,
            });
        }
    }

    private updateDashboardDevice(deviceId: string): void {
        if (this.dashboard) {
            this.dashboard.setParameters({
                selectedDevice: deviceId,
            });
        }
    }

    private setTimeRange = async (startTimestamp: number, endTimestamp: number): Promise<void> => {
        this.setState({
            startTimestamp,
            endTimestamp,
        });
        this.updateDashboardTimestamps(startTimestamp, endTimestamp);
    }

    private getTimestampsBySelection(selection: number): {
        startTimestamp: number,
        endTimestamp: number,
    } {
        switch (selection) {
            case 0:
                return {
                    startTimestamp: TimeWrapper.getStartOfXDaysAgo(0),
                    endTimestamp: Date.now(),
                };
            case 1:
                return {
                    startTimestamp: TimeWrapper.getStartOfXDaysAgo(1),
                    endTimestamp: TimeWrapper.getStartOfXDaysAgo(0),
                };
            case 2:
                return {
                    startTimestamp: TimeWrapper.getTimestampXDaysAgo(7),
                    endTimestamp: Date.now(),
                };
            case 3:
                return {
                    startTimestamp: TimeWrapper.getTimestampXDaysAgo(14),
                    endTimestamp: Date.now(),
                };
            case 4:
                return {
                    startTimestamp: TimeWrapper.getTimestampXDaysAgo(30),
                    endTimestamp: Date.now(),
                };
        }
    }

    private setSelection = async (selection: number): Promise<void> => {
        if (selection !== null) {
            const queryArguments = this.getTimestampsBySelection(selection);
            this.setState({
                selection,
                startTimestamp: queryArguments.startTimestamp,
                endTimestamp: queryArguments.endTimestamp,
            });
            this.updateDashboardTimestamps(queryArguments.startTimestamp, queryArguments.endTimestamp);
        } else {
            this.setState({
                selection,
            });
        }
    }

    private isDisabled = (): boolean => {
        if (this.state.selection !== null) {
            return true;
        }
        return false;
    }

    private getToolbar(): JSX.Element {
        return (
            <Fragment>
                <div className="live-control-container">
                    <span id="only-selected-device-label">Only selected device</span>
                    <Checkbox
                        checked={this.state.onlySelectedDevice}
                        onChange={this.onOnlySelectedDeviceChange}
                        color="primary"
                        className="only-selected-device-checkbox"
                    />
                </div>
                <DropdownSelection
                    label="Period"
                    selectionList={["Today", "Yesterday", "Last 7 days", "Last 14 days", "Last 30 days"]}
                    defaultSelection="Custom"
                    onSelection={this.setSelection}
                />
                <TimeRangePicker
                    startTimestamp={this.state.startTimestamp}
                    endTimestamp={this.state.endTimestamp}
                    onTimeRangeSelect={this.setTimeRange}
                    disabled={this.isDisabled()}
                />
            </Fragment>
        );
    }

    private handleCloseErrorNote = (): void => {
        this.setState({ errorMsg: null });
    }

    private renderDashboard(): JSX.Element {
        const suffix = this.state.isLoading ? ".hidden" : "";
        const className = `iot-content-container${suffix} col-sm-12 col-xsm-12`;
        return (
            <React.Fragment>
                <div id="dashboardContainer" className={className}/>
                <Loader size={this.state.isLoading ? "50px" : "0px"}/>
            </React.Fragment>
        );
    }

    private renderErrorNote(): JSX.Element {
        if (this.state.errorMsg) {
            return (
                <ErrorNote
                    errorMsg={this.state.errorMsg}
                    closeErrorNote={this.handleCloseErrorNote}
                />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                <div className="iot-tool-container col-sm-8 col-xsm-7">
                    {this.getToolbar()}
                </div>
                {this.renderErrorNote()}
                {this.renderDashboard()}
            </Fragment>
        );
    }
}
