/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import IEvent from "../clientSpecific/IEvent";
import BaseObservable from "../observer/BaseObservable";

export abstract class EventSet extends BaseObservable {
    public abstract getId(): string;
    public abstract getEvents(): IEvent[];
    public abstract fetch(): Promise<void>;
    public abstract setEvent(data: IEvent): void;
    public abstract deactivateEvent(event: IEvent): Promise<void>;
}

export default EventSet;
