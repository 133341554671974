/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupWork from "@material-ui/icons/GroupWork";
import RemoveIcon from "@material-ui/icons/Remove";
import WarningIcon from "@material-ui/icons/Warning";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ClientProperties from "../../data/clientSpecific/ClientProperties";
import { IGroup } from "../../data/group/IGroup";
import { IoTTreeItem } from "../../data/tree/tree-item";

const IOT_MAX_CHILD_GROUPS: number = 100;

interface Props {
    group: IGroup;
    isExpanded: () => boolean;
    treeItem: IoTTreeItem;
    adminMode: boolean;
    addGroup: (parentGroup: IGroup) => void;
    removeGroup: (groupName: IGroup) => void;
    onDeviceMove: (deviceName: string, newGroup: string) => void;
}

export default class TreeFolder extends Component<Props> {

    private renderAlarmIcon(): JSX.Element {
        if (this.props.treeItem.hasAlarms && !this.props.isExpanded()) {
            return (
                <WarningIcon
                    height="100%"
                    style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
                    htmlColor="#ff0000"
                />
            );
        } else {
            return (
                <GroupWork
                    height="100%"
                    style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
                    htmlColor="#000000"
                />
            );
        }
    }

    private handleAddGroupIconClick = (event: any): void => {
        event.stopPropagation();
        this.props.addGroup(this.props.group);
    }

    private handleRemoveGroupIconClick = (event: any): void => {
        event.stopPropagation();
        this.props.removeGroup(this.props.group);
    }

    private handleDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
    }

    private handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        if (!this.props.adminMode) { return; }
        event.preventDefault();
        event.stopPropagation();
        // TODO: Define proper color
        event.currentTarget.style.backgroundColor = "#AAAAAA";
    }

    private handleDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
        if (!this.props.adminMode) { return; }
        event.preventDefault();
        event.stopPropagation();
        // TODO: Define proper color
        event.currentTarget.style.backgroundColor = "#FFFFFF";
    }

    private handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
        if (!this.props.adminMode) { return; }
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.getData("device")) {
            this.props.onDeviceMove(event.dataTransfer.getData("device"), this.props.group.groupId);
        }
        // TODO: Define proper color
        event.currentTarget.style.backgroundColor = "#FFFFFF";
    }

    private renderAddSubGroupIcon(): JSX.Element {
        const { treeItem, adminMode } = this.props;
        if (adminMode && treeItem.isGroup) {
            let childGroupCount: number = 0;
            this.props.treeItem.childNodesAndFolders.forEach((item: IoTTreeItem) => {
                if (item.isGroup) {
                    childGroupCount++;
                }
            });
            if (childGroupCount < IOT_MAX_CHILD_GROUPS) {
                return (
                    <IconButton
                        title="Add new sub group"
                        onClick={this.handleAddGroupIconClick}
                        style={{ backgroundColor: "#e6e6e6" }}
                        size="small"
                    >
                        <AddIcon />
                    </IconButton>
                );
            }
        }
    }

    private renderRemoveGroupIcon(): JSX.Element {
        if (this.props.adminMode
            && this.props.treeItem.parent
            && this.props.treeItem.isGroup
            && this.props.treeItem.childNodesAndFolders.length === 0) {
            return (
                <IconButton
                    title="Remove group"
                    onClick={this.handleRemoveGroupIconClick}
                    style={{ backgroundColor: "#e6e6e6" }}
                    size="small"
                >
                    <RemoveIcon />
                </IconButton>
            );
        }
    }

    public render(): JSX.Element {
        return (
            <div
                className="tree-folder"
                onDragStart={this.handleDragStart}
                onDragOver={(event: any): void => this.handleDragOver(event)}
                onDragLeave={(event: any): void => this.handleDragLeave(event)}
                onDrop={(event: any): void => this.handleDrop(event)}
            >
                <NavLink to={"#"} className="iot-item-link col-sm-8 col-xsm-8">
                    <div
                        className="iot-icon col-sm-1 col-xsm-1"
                        data-tip={ClientProperties.getAlarmTooltipFromEntities([])}
                    >
                        {this.renderAlarmIcon()}
                    </div>
                    <div className="iot-name col-sm-10 col-xsm-10">
                        {this.props.group.getLabel()}
                    </div>
                </NavLink>
                <div className="iot-status-container col-sm-4 col-xsm-4">
                    {this.renderAddSubGroupIcon()}
                    {this.renderRemoveGroupIcon()}
                </div>
            </div>
        );
    }
}
