/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { CognitoUser } from "@aws-amplify/auth";
import Amplify, { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import awsconfig from "../../aws-config";

Amplify.configure(awsconfig);

interface GetOpenIdParams  {
    IdentityId: string;
    Logins: {[key: string]: string };
}

// REFACTOR: Hard to unit test as dependencies are built in. Could use dependency injection.
// Also usage of static methods can make mocking hard in classes that use AuthWrapper.
export default class AuthWrapper {

    public static async logIn(email: string, password: string): Promise<CognitoUser | any> {
        if (password === "") {
            throw new Error("Empty password");
        }
        try {
            return await Auth.signIn(email, password);
        } catch (error) {
            throw new Error(error.message || error);
        }
    }

    public static async logOut(): Promise<any> {
        try {
            await Auth.signOut();
        } catch (error) {
            throw new Error(error.message || error);
        }
    }

    public static async completeNewPassword(user: CognitoUser | any, password: string): Promise<CognitoUser | any> {
        try {
            return await Auth.completeNewPassword(user, password, {});
        } catch (error) {
            throw new Error(error.message || error);
        }
    }

    public static async isCurrentUserAuthenticated(): Promise<boolean> {
        return await this.getCurrentAuthenticatedUser(false) != null;
    }

    public static async getCurrentAuthenticatedUser(refreshFromServer: boolean): Promise<CognitoUser> {
        try {
            return await Auth.currentAuthenticatedUser({
                bypassCache: refreshFromServer,
            });
        } catch (error) {
            console.log("No current user! " + error);
            return null;
        }
    }

    public static async getOpenIdToken(): Promise<string> {
        const currentSession = await Auth.currentSession();
        const cognitoAuthenticatedLoginsKey = `cognito-idp.${awsconfig.Auth.region}.amazonaws.com/${awsconfig.Auth.userPoolId}`;
        const cognitoAuthenticatedLogins = {[cognitoAuthenticatedLoginsKey]: currentSession.getIdToken().getJwtToken()};

        const getIdParams = {
            IdentityPoolId: awsconfig.Auth.identityPoolId,
            Logins: cognitoAuthenticatedLogins,
        };

        if (!AWS.config.region) {
            AWS.config.update({
                region: awsconfig.Auth.region,
            });
        }

        const cognitoIdentity = new AWS.CognitoIdentity();

        try {
            const data: AWS.CognitoIdentity.GetIdResponse = await cognitoIdentity.getId(getIdParams).promise();
            const getOpenIdParams: GetOpenIdParams = {
                IdentityId: data.IdentityId,
                Logins: cognitoAuthenticatedLogins,
            };
            const response: AWS.CognitoIdentity.GetOpenIdTokenResponse = await cognitoIdentity.getOpenIdToken(getOpenIdParams).promise();
            return response.Token;
        } catch (error) {
            console.error("getOpenIdToken", error);
            return null;
        }
    }
}
