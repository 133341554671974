/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Button from "@material-ui/core/Button";
import { List, ListItem, ListItemText, Switch } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouteComponentPropsParams } from "../../@types/routerprops";
import { ClientProperties, StatusEntry } from "../../data/clientSpecific/ClientProperties";
import Utils from "../../data/utils/utils";
import SettingsWrapper from "../settings/settings-wrapper";
import DeviceIcon from "./../../assets/memory_24px.svg";
import IData from "./../../data/clientSpecific/IData";
import { Device } from "./../../data/device/Device";
import DeviceSettings from "../../data/clientSpecific/DeviceSettings";
import Loader from "../global/loader";

interface Props extends RouteComponentProps<RouteComponentPropsParams> {
    popupData: IData;
    selectedDevice: Device;
}

interface State {
    ledEnabled: boolean;
}

class StatusPopup extends Component<Props, State> {

    private deviceSettings: DeviceSettings = this.getSettings();

    constructor(props: Props) {
        super(props);
        this.state = {
            ledEnabled: this.deviceSettings.ledEnabled.value || false,
        };
    }

    private getSettings(): DeviceSettings {
        if (this.props.selectedDevice && this.props.selectedDevice.getState()) {
            return this.props.selectedDevice.getState().exportSettings();
        }
        return null;
    }

    private handleSettingsClosed = (): void => {
        if (this.props.selectedDevice && this.props.selectedDevice.getState()) {
            this.deviceSettings = this.props.selectedDevice.getState().exportSettings();
            this.setState({ ledEnabled: this.deviceSettings.ledEnabled.value || false });
        }
    }

    private openHistory = (): void => {
        this.props.history.push("/history/" + this.props.selectedDevice.getId());
    }

    private convertToLocalTime(timestamp: string): string {
        return new Date(Number(timestamp)).toLocaleString();
    }

    private ledStateChanged = (): void => {
        if (this.deviceSettings && this.deviceSettings.ledEnabled) {
            this.deviceSettings.ledEnabled.value = !this.deviceSettings.ledEnabled.value;
            this.props.selectedDevice.store(this.deviceSettings);
            this.setState({ ledEnabled: this.deviceSettings.ledEnabled.value });
        }
    }

    private getLoader(key: keyof DeviceSettings): JSX.Element {
        if (this.deviceSettings[key].pending) {
            return (
                <Loader
                    size="1.5rem"
                    circleHeight="0.2rem"
                    topBottomPadding="0"
                    leftRightPadding="0.2rem"
                />
            );
        }
    }

    private getTableBody(): JSX.Element[] {
        const entries: StatusEntry[] = ClientProperties.getOverlayPopupEntries(this.props.popupData);
        return entries.map((e: StatusEntry) => {
            return <tr key={e.title}><td><b>{e.title}</b></td><td>{e.value}</td></tr>;
        });
    }

    private renderLedSwitch(): JSX.Element {
        return (
            <List>
                <div className="setting-item">
                    <ListItem>
                        <ListItemText id="switch-list-label-led" primary="Led on/off" />
                        <Switch
                            edge="end"
                            onChange={this.ledStateChanged}
                            checked={this.state.ledEnabled}
                            color="primary"
                        />
                        {this.getLoader("ledEnabled")}
                    </ListItem>
                </div>
            </List>
        );
    }

    public render(): JSX.Element {
        const { selectedDevice, popupData } = this.props;

        return (
            <Fragment>
                <div className="status-popup-container">
                    <div className="status-popup-header">
                        <img className="device-icon" alt="" src={DeviceIcon} />
                        {Utils.getDisplayName(selectedDevice)}
                    </div>
                    <div className="status-popup-body">
                        <p>{this.convertToLocalTime(popupData.timestamp)}</p>
                        <table>
                            <tbody>
                                {this.getTableBody()}
                            </tbody>
                        </table>
                        {this.renderLedSwitch()}
                        <SettingsWrapper
                            device={selectedDevice}
                            isIcon={false}
                            onSettingsClosed={this.handleSettingsClosed}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            className="button"
                            onClick={this.openHistory}
                        >
                            History
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(StatusPopup);
