/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Dialog, List, ListItemText, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import React, { Component } from "react";

interface Props {
    errorMsg: string;
    closeErrorNote: () => void;
}

export default class ErrorNote extends Component<Props> {

    protected handleOk = (): void => {
        this.props.closeErrorNote();
    }

    public render(): JSX.Element {
        return (
            <Dialog
                onClose={this.props.closeErrorNote}
                open={true}
            >
                <div className="popup-inner">
                    <Typography variant="h6">Error</Typography>
                    <div className="popup-body">
                        <List style={{ minHeight: "2rem" }}>
                            <ErrorIcon htmlColor="#ff0000" />
                            <ListItemText id="list-label-new-popup-name" primary={this.props.errorMsg} />
                            <div className="buttons-container">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="button middle"
                                    onClick={this.handleOk}
                                >
                                    Ok
                                </Button>
                            </div>
                        </List>
                    </div>
                </div>
            </Dialog>
        );
    }
}
