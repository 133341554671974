/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Utils, { DateTimeFormatTarget } from "../utils/utils";
import IData from "./IData";

export interface StatusEntry {
    title: string;
    value: string | number | JSX.Element;
}

export type StatusDataRow = Array<string | number | JSX.Element>;

export interface TableColumnLabels {
    [key: string]: string;
}

export interface TimestampRange {
    start: number;
    end: number;
}

export enum CanType {
    CAN_1,
    CAN_2,
}

export interface CanGateway {
    id: CanType;
    name: string;
    sensors: string[];
}

export class ClientProperties {
    public static readonly EVENT_AGE_DAYS = 365;
    public static readonly DEVICE_DRAWER_TITLE: string = "Devices";
    public static readonly DEVICES_TAB_TITLE: string = "Devices";
    public static readonly GROUPS_TAB_TITLE: string = "Groups";

    public static readonly EVENT_TABLE_TITLE: string = "Events";
    public static readonly EVENT_ACKNOWLEDGE_BUTTON_TITLE: string = "Acknowledge";

    public static readonly EVENT_TABLE_LABELS: TableColumnLabels = {
        timestamp: "Timestamp",
        description: "Description",
        type: "Type",
        severity: "Severity",
        sensorName: "Sensor",
        sensorValue: "Sensor value",
        eventState: "State",
    };

    public static readonly CAN_GATEWAYS: CanGateway[] = [
        {
            id: CanType.CAN_1,
            name: "CAN 1/BMS",
            sensors: [
                "latitude",
                "longitude",
                "speed",
                "current",
                "pack_voltage",
                "SOC",
                "SOH",
            ],
        },
        {
            id: CanType.CAN_2,
            name: "CAN 2/Vehicle",
            sensors: [
                "Battery_Voltage_1",
                "Capasitor_Voltage_1",
                "Battery_Current_1",
                "Heatsink_Temp_1",
                "Temp_PTC_1",
                "Key_Hours",
                "Traction_Hours",
                "Pump_Hours",
                "Battery_Voltage_2",
                "Capasitor_Voltage_2",
                "Battery_Current_2",
                "Heatsink_Temp_2",
                "Temp_PTC_2",
            ],
        },
    ];

    private static readonly HIDE_POPUP_ENTRIES = [
        "__typename",
        "deviceId",
        "StartTime",
        "EndTime",
        "sessionId",
        "receiver",
        "timestamp",
        "Driveablity_Select_2_switch_main",
        "Driveablity_Select_1_switch_main",
        "Forward_switch_main",
        "Seat_switch_main",
        "Handbreak_switch",
        "Driveablity_Select_1_switch",
        "Driveablity_Select_2_switch",
        "Charger_Interlock_switch",
        "Forward_switch_1",
        "Reverse_switch_1",
        "Seat_switch_1",
        "Reverse_switch_main",
    ];

    private static readonly HIDE_STATUS_TABLE_ENTRIES = [
        "__typename",
        "deviceId",
        "StartTime",
        "EndTime",
        "sessionId",
        "receiver",
        "latitude",
        "longitude",
        "Driveablity_Select_2_switch_main",
        "Driveablity_Select_1_switch_main",
        "Forward_switch_main",
        "Seat_switch_main",
        "Handbreak_switch",
        "Driveablity_Select_1_switch",
        "Driveablity_Select_2_switch",
        "Charger_Interlock_switch",
        "Forward_switch_1",
        "Reverse_switch_1",
        "Seat_switch_1",
        "Reverse_switch_main",
    ];

    public static getOverlayPopupEntries(data: IData): StatusEntry[] {
        const json = JSON.parse(JSON.stringify(data));
        const arr: StatusEntry[] = [];
        Object.keys(json).forEach((key: string) => {
            if (!this.HIDE_POPUP_ENTRIES.includes(key)) {
                if (typeof (json[key]) === "number") {
                    arr.push({ title: key, value: Utils.numberToFixed(json[key], 2) });
                } else {
                    arr.push({title: key, value: json[key] == null ? "N/A" : json[key]});
                }
            }
        });

        return arr;
    }

    public static getAlarmTooltipFromEntities(entities: string[]): string {
        let result: string = "";
        entities.forEach((entity: string, index: number) => {
            let sensor: string = "";
            switch (entity) {
                default:
                    sensor = entity;
                    break;
            }
            result = result.concat(sensor, index === entities.length - 1 ? " alarm" : ",");
        });
        if (result.length > 0) {
            return result.charAt(0).toUpperCase() + result.slice(1);
        } else {
            return "";
        }
    }

    public static getDefaultEventTimestampRange(days: number): TimestampRange {
        const delta: number = days * 24 * 60 * 60 * 1000;
        const startTimestamp: number = new Date().getTime() - delta;
        const endTimestamp: number = new Date().getTime();
        return {
            start: startTimestamp,
            end: endTimestamp,
        };
    }

    public static getStatusRowEntries(data: IData): StatusEntry[] {
        const json = JSON.parse(JSON.stringify(data));
        const arr: StatusEntry[] = [];
        Object.keys(json).forEach((key: string) => {
            if (!this.HIDE_STATUS_TABLE_ENTRIES.includes(key)) {
                if (key === "timestamp") {
                    arr.push({title: key, value: json[key] == null ?
                        "N/A" : Utils.convertTimestampToString(json[key], DateTimeFormatTarget.StatusTable)});
                } else if (typeof(json[key]) === "number") {
                    arr.push({ title: key, value: Utils.numberToFixed(json[key], 2) });
                } else {
                    arr.push({title: key, value: json[key] == null ? "N/A" : json[key]});
                }
            }
        });

        return arr;
    }

    public static getMetricsHeaderTitles(): string[] {
        const headerTitles: string[] = ["Measured data", "Min", "Max", "Average"];
        return headerTitles;
    }

    public static getMetricsKeyLabel(labelId: string): string {
        const labels: TableColumnLabels = {
            temperature: "Temperature",
            humidity: "Humidity",
            pressure: "Pressure",
            altitude: "Altitude",
            speed: "Speed",
            batteryLevel: "Battery Level",
        };
        if (labels[labelId]) {
            return labels[labelId];
        }
        return labelId;
    }

    public static getInvalidMetricsKeys(): string[] {
        const invalidKeys: string[] = [
            "deviceId",
            "sessionId",
            "durationMs",
            "eventCount",
        ];
        return invalidKeys;
    }

    public static filterSensorData(canType: CanType, inputData: IData[]): IData[] {
        const canGw = this.CAN_GATEWAYS.find((can: CanGateway) => can.id === canType);
        return inputData.filter((data: IData) => {
            for (const key of Object.keys(data)) {
                if (canGw.sensors.includes(key) && data[key] != null) {
                    return true;
                }
            }

            return false;
        });
    }
}

export default ClientProperties;
