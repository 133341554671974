/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import {clientType, Service} from "../backend/AppSyncClientProvider";
import { ISession } from "../clientSpecific/ISession";
import { getSessions } from "../graphql/queries";
import { SessionObserver } from "../observer/SessionObserver";
import SessionSet from "./SessionSet";
import SessionSubscriptionManager from "./SessionSubscriptionManager";

interface AppSyncDynamoDBSessionResponse {
    getSessions: {
        items: ISession[];
        nextToken?: string;
    };
}

export default class AWSSessionSet extends SessionSet {
    private deviceId: string;
    private startTimestamp: number;
    private endTimestamp: number;
    private data: ISession[];

    // TODO: Remove comments when subscriptions are device specific
    // private subscription: ZenObservable.Subscription = null;

    constructor(deviceId: string, startTimestamp: number, endTimestamp: number) {
        super();
        this.deviceId = deviceId;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
    }

    public getId(): string {
        return this.deviceId;
    }

    public getSessions(): ISession[] {
        return this.data;
    }

    public async fetch(): Promise<void> {
        try {
            let nextToken: string = null;
            let sessions: ISession[] = [];
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(Service.DATA, clientType.TYPE_COGNITO);
                const sessionResponse: ApolloQueryResult<AppSyncDynamoDBSessionResponse> = await appSyncClient.query({
                    query: gql(getSessions),
                    variables: {
                        deviceId: this.deviceId,
                        startTimestamp: this.startTimestamp,
                        endTimestamp: this.endTimestamp,
                        nextToken,
                    },
                });
                nextToken = sessionResponse.data.getSessions.nextToken || null;
                sessions = sessions.concat(sessionResponse.data.getSessions.items);
            } while (nextToken);
            this.data = sessions;
        } catch (error) {
            console.error("Error: " + JSON.stringify(error));
        }
    }

    public setData(data: ISession): void {
        this.data.push(data);
        this.notify();
    }

    public async addObserver(observer: SessionObserver): Promise<void> {
        if (!this.data) {
            await this.fetch();
        }
        super.addObserver(observer);
        if (this.observers.length === 1) {
            SessionSubscriptionManager.getInstance().subscribeData(this);
        }
        // TODO: Remove comments when subscriptions are device specific
        // if (!this.subscription) {
        //     this.subscribe();
        // }
    }

    public removeObserver(observer: SessionObserver): void {
        super.removeObserver(observer);
        if (this.observers.length === 0) {
            SessionSubscriptionManager.getInstance().removeSubscription(this);
        }
    }

    public notify(): void {
        const observers = this.observers as SessionObserver[];
        if (observers && observers.length !== 0) {
            observers.forEach((observer: SessionObserver) => {
                observer.onSessionUpdate(this);
            });
        }
    }

    // TODO: Remove comments when subscriptions are device specific
    // private subscribe(): void {
    //     const appSyncClient = AppSyncClientFactory.createProvider().getAppSyncClient(clientType.TYPE_COGNITO);
    //     this.subscription = appSyncClient.subscribe({
    //         query: gql(dataUpdates),
    //     }).subscribe({
    //         error: (error: any): void => {
    //             if (error.errorMessage === "AMQJS0008I Socket closed.") {
    //                 console.log("Reconnecting socket");
    //                 this.subscribe();
    //             }
    //             console.error(error);
    //         },
    //         next: (newData: any): void => {
    //             if (newData.data.onDataUpdates.deviceId === this.deviceId) {
    //                 this.data.push(newData.data.onDataUpdates);
    //                 this.notify();
    //             }
    //         },
    //     });
    // }
}
