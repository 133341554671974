/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import moment from "moment";
import React, { Component, Fragment } from "react";
import { Device } from "../../data/device/Device";
import LocationOff from "./../../assets/baseline-location_off-24px.svg";
import LocationOn from "./../../assets/baseline-location_on-24px.svg";

interface Props {
    device: Device;
}

export default class LocationIcon extends Component<Props> {

    private getLocationIcon(): string {
        if (this.props.device && this.props.device.getState()) {
            const stateProperties = this.props.device.getState().getStateProperties();
            if (stateProperties.gpsFix === true) {
                return LocationOn;
            } else if (stateProperties.gpsFix === false) {
                return LocationOff;
            }
        }
    }

    private getTooltip = (): string => {
        let timestampAsString = "N/A";
        if (this.props.device && this.props.device.getState()) {
            const state = this.props.device.getState();
            // REFACTOR: Time and date formatting should be harmonized throughout the app
            // and encapsulated to a single class.
            if (state && state.getStateUpdatedTimestampMillis()) {
                const timestamp = state.getStateUpdatedTimestampMillis();
                timestampAsString = moment(timestamp).format("D/M/Y HH:mm:ss");
            }
        }
        return "Last update: " + timestampAsString;
    }

    private getLocationStatusElement(): JSX.Element {
        const icon = this.getLocationIcon();
        if (icon) {
            return (
                    <img
                        className="status-icon"
                        data-tip={this.getTooltip()}
                        src={icon}
                        alt="Location"
                    />
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Fragment>
                {this.getLocationStatusElement()}
            </Fragment>
        );
    }
}
