/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Device from "../../data/device/Device";
import IGroup from "../../data/group/IGroup";
import { IoTTreeItem } from "../../data/tree/tree-item";
import Utils from "../../data/utils/utils";

export class IoTDeviceTreeSearch {

    public static getCaseInsensitiveSearchFilter(searchString: string): RegExp {
        return new RegExp(searchString, "i");
    }

    public static executeSearchFilterCheck(searchFilter: RegExp, item: IoTTreeItem, handledItems: string[]): void {
        let label: string = "";
        const checkFilter: boolean = !handledItems.includes(item.id);
        if (item.isGroup) {
            label = (item.item as IGroup).getLabel();
        } else {
            label = Utils.getDisplayName((item.item as Device));
        }

        if (checkFilter) {
            item.isFilterMatch = IoTDeviceTreeSearch.checkSearchFilterMatch(searchFilter, label);
        }

        handledItems.push(item.id);

        if (item.isFilterMatch) {
            if (item.isGroup) {
                IoTDeviceTreeSearch.includeParentChain(item, handledItems);
                IoTDeviceTreeSearch.includeChildChain(item, handledItems);
            } else {
                IoTDeviceTreeSearch.includeParentChain(item, handledItems);
            }
        }

        if (item.childNodesAndFolders) {
            item.childNodesAndFolders.forEach((child: IoTTreeItem) => {
                IoTDeviceTreeSearch.executeSearchFilterCheck(searchFilter, child, handledItems);
            });
        }
    }

    private static checkSearchFilterMatch(searchFilter: RegExp, itemLabel: string): boolean {
        if (searchFilter) {
            return itemLabel.search(searchFilter) >= 0;
        }
        return true;
    }

    private static includeChildChain(item: IoTTreeItem, handledItems: string[]): void {
        if (item.childNodesAndFolders) {
            item.childNodesAndFolders.forEach((child: IoTTreeItem) => {
                if (!handledItems.includes(child.id)) {
                    child.isFilterMatch = true;
                    handledItems.push(child.id);
                    IoTDeviceTreeSearch.includeChildChain(child, handledItems);
                }
            });
        }
    }

    private static includeParentChain(item: IoTTreeItem, handledItems: string[]): void {
        if (item.parent) {
            item.parent.isFilterMatch = true;
            handledItems.push(item.id);
            IoTDeviceTreeSearch.includeParentChain(item.parent, handledItems);
        }
    }
}
